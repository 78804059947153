<datex-grid #$gridComponent *ngIf="initialized " [selection]="true" (selectionChange)="$selectionChanged($event)" [headers]="headers" [rows]="rows" [(pageSize)]="pageSize" [(pageSkip)]="pageSkip" [totalCount]="totalCount" [loadingStatus]="loadingStatus" (pageChange)="$dataLoad()" [containerStyles]="containerStyles"



>
    <ng-container topToolbar>
      <app-toolbar [toolbar]="topToolbar">
          <ng-template 
            toolbarToolDef="new_reading" let-tool>
            <ng-container  *ngIf="tool.hasButtonsToShow">
            <button mat-button
                    data-cy="button"
                    class="datex-button"
                    [disabled]="tool.control.readOnly"
                    [ngStyle]="tool.control.styles.style"
                    [ngClass]="tool.control.styles.classes"
                    [matTooltip]="tool.control.tooltip"
                    matTooltipClass="datex-control-tooltip"
                    (click)="on_new_clicked($event)"
                    >
              <div class="button-label">
                <ng-container *ngIf="tool.control.icon === 'datex-default-spinner'; else showIcon">
                  <div class="button-icon">
                    <i data-cy="button-icon" class="datex-default-spinner"></i>
                  </div>
                </ng-container>
                <ng-template #showIcon>
                  <div class="button-icon">
                    <i data-cy="button-icon" class="icon {{tool.control.icon}}"></i>
                  </div>
                </ng-template>
                <div data-cy="button-label" class="button-text">{{tool.control.label}}</div>
              </div>
            
            </button>
            </ng-container>
          </ng-template>
          <ng-template 
            toolbarToolDef="delete_reading" let-tool>
            <ng-container  *ngIf="tool.hasButtonsToShow">
            <button mat-button
                    data-cy="button"
                    class="datex-button"
                    [disabled]="tool.control.readOnly"
                    [ngStyle]="tool.control.styles.style"
                    [ngClass]="tool.control.styles.classes"
                    [matTooltip]="tool.control.tooltip"
                    matTooltipClass="datex-control-tooltip"
                    (click)="on_delete_clicked($event)"
                    >
              <div class="button-label">
                <ng-container *ngIf="tool.control.icon === 'datex-default-spinner'; else showIcon">
                  <div class="button-icon">
                    <i data-cy="button-icon" class="datex-default-spinner"></i>
                  </div>
                </ng-container>
                <ng-template #showIcon>
                  <div class="button-icon">
                    <i data-cy="button-icon" class="icon {{tool.control.icon}}"></i>
                  </div>
                </ng-template>
                <div data-cy="button-label" class="button-text">{{tool.control.label}}</div>
              </div>
            
            </button>
            </ng-container>
          </ng-template>
      </app-toolbar>
  </ng-container>


  <ng-container gridColumnDef="DateCaptured">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.DateCaptured.displayControl.styles.style"
          [ngClass]="row.cells.DateCaptured.displayControl.styles.classes"
          [matTooltip]="row.cells.DateCaptured.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.DateCaptured.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="Employee">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.Employee.displayControl.styles.style"
          [ngClass]="row.cells.Employee.displayControl.styles.classes"
          [matTooltip]="row.cells.Employee.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.Employee.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="Notes">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.Notes.displayControl.styles.style"
          [ngClass]="row.cells.Notes.displayControl.styles.classes"
          [matTooltip]="row.cells.Notes.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.Notes.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="Temperature1">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.Temperature1.displayControl.styles.style"
          [ngClass]="row.cells.Temperature1.displayControl.styles.classes"
          [matTooltip]="row.cells.Temperature1.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.Temperature1.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="Temperature1MeasurementUnit_Short_name">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.Temperature1MeasurementUnit_Short_name.displayControl.styles.style"
          [ngClass]="row.cells.Temperature1MeasurementUnit_Short_name.displayControl.styles.classes"
          [matTooltip]="row.cells.Temperature1MeasurementUnit_Short_name.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.Temperature1MeasurementUnit_Short_name.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="Temperature2">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.Temperature2.displayControl.styles.style"
          [ngClass]="row.cells.Temperature2.displayControl.styles.classes"
          [matTooltip]="row.cells.Temperature2.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.Temperature2.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="Temperature2MeasurementUnit_Short_name">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.Temperature2MeasurementUnit_Short_name.displayControl.styles.style"
          [ngClass]="row.cells.Temperature2MeasurementUnit_Short_name.displayControl.styles.classes"
          [matTooltip]="row.cells.Temperature2MeasurementUnit_Short_name.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.Temperature2MeasurementUnit_Short_name.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="Temperature3">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.Temperature3.displayControl.styles.style"
          [ngClass]="row.cells.Temperature3.displayControl.styles.classes"
          [matTooltip]="row.cells.Temperature3.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.Temperature3.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="Temperature3MeasurementUnit_Short_name">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.Temperature3MeasurementUnit_Short_name.displayControl.styles.style"
          [ngClass]="row.cells.Temperature3MeasurementUnit_Short_name.displayControl.styles.classes"
          [matTooltip]="row.cells.Temperature3MeasurementUnit_Short_name.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.Temperature3MeasurementUnit_Short_name.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="ReadingPoint_Name">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.ReadingPoint_Name.displayControl.styles.style"
          [ngClass]="row.cells.ReadingPoint_Name.displayControl.styles.classes"
          [matTooltip]="row.cells.ReadingPoint_Name.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.ReadingPoint_Name.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="ReadingType_Name">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.ReadingType_Name.displayControl.styles.style"
          [ngClass]="row.cells.ReadingType_Name.displayControl.styles.classes"
          [matTooltip]="row.cells.ReadingType_Name.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.ReadingType_Name.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="LicensePlate_LookupCode">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.LicensePlate_LookupCode.displayControl.styles.style"
          [ngClass]="row.cells.LicensePlate_LookupCode.displayControl.styles.classes"
          [matTooltip]="row.cells.LicensePlate_LookupCode.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.LicensePlate_LookupCode.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="LoadContainer_LookupCode">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.LoadContainer_LookupCode.displayControl.styles.style"
          [ngClass]="row.cells.LoadContainer_LookupCode.displayControl.styles.classes"
          [matTooltip]="row.cells.LoadContainer_LookupCode.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.LoadContainer_LookupCode.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="Shipment_LookupCode">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.Shipment_LookupCode.displayControl.styles.style"
          [ngClass]="row.cells.Shipment_LookupCode.displayControl.styles.classes"
          [matTooltip]="row.cells.Shipment_LookupCode.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.Shipment_LookupCode.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="ShipmentLine_LineNumber">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.ShipmentLine_LineNumber.displayControl.styles.style"
          [ngClass]="row.cells.ShipmentLine_LineNumber.displayControl.styles.classes"
          [matTooltip]="row.cells.ShipmentLine_LineNumber.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.ShipmentLine_LineNumber.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>

<ng-container bottomToolbar>
</ng-container>


</datex-grid>
