import { Inject, Injectable, Injector } from '@angular/core';

import { Utilities_FlowService } from './Utilities.flow.index';

import { EndeavorLabs_endeavor_csv_from_sftp_flowService } from './EndeavorLabs.flow.index';
import { EndeavorLabs_endeavor_csv_to_sftp_flowService } from './EndeavorLabs.flow.index';
import { EndeavorLabs_endeavor_main_flowService } from './EndeavorLabs.flow.index';
import { EndeavorLabs_initial_setupService } from './EndeavorLabs.flow.index';

import { $frontendTypes } from './EndeavorLabs.frontend.types'

@Injectable({ providedIn: 'root' })
export class EndeavorLabs_FlowService {

  constructor(
    private injector: Injector
  ) {
    this.Utilities = this.injector.get(Utilities_FlowService);
  }

    public Utilities: Utilities_FlowService;
  public EndeavorLabs: EndeavorLabs_FlowService = this;

  // injecting lazily in order to avoid circular dependencies
 
  private _endeavor_csv_from_sftp_flow: EndeavorLabs_endeavor_csv_from_sftp_flowService;
  public async endeavor_csv_from_sftp_flow(inParams: {  }): Promise< { error?: string }> {
    if(!this._endeavor_csv_from_sftp_flow) { 
      this._endeavor_csv_from_sftp_flow = this.injector.get(EndeavorLabs_endeavor_csv_from_sftp_flowService);
    }
    return this._endeavor_csv_from_sftp_flow.run(inParams);
  }
   
   

   
 
  private _endeavor_csv_to_sftp_flow: EndeavorLabs_endeavor_csv_to_sftp_flowService;
  public async endeavor_csv_to_sftp_flow(inParams: { enviroment?: string }): Promise< { file?: string, env?: string }> {
    if(!this._endeavor_csv_to_sftp_flow) { 
      this._endeavor_csv_to_sftp_flow = this.injector.get(EndeavorLabs_endeavor_csv_to_sftp_flowService);
    }
    return this._endeavor_csv_to_sftp_flow.run(inParams);
  }
   
   

   
 
  private _endeavor_main_flow: EndeavorLabs_endeavor_main_flowService;
  public async endeavor_main_flow(inParams: {  }): Promise<void> {
    if(!this._endeavor_main_flow) { 
      this._endeavor_main_flow = this.injector.get(EndeavorLabs_endeavor_main_flowService);
    }
    return this._endeavor_main_flow.run(inParams);
  }
   
   

   
 
  private _initial_setup: EndeavorLabs_initial_setupService;
  public async initial_setup(inParams: { sas: string, sas_exp_date: string }): Promise<void> {
    if(!this._initial_setup) { 
      this._initial_setup = this.injector.get(EndeavorLabs_initial_setupService);
    }
    return this._initial_setup.run(inParams);
  }
   
   

   
}
