import { Inject, Injectable } from '@angular/core';
import { isNil } from 'lodash-es';
import { environment } from '../environments/environment';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';

@Injectable({ providedIn: 'root' })
export class app_custom_ds_bol_report_details_shService {

  constructor(
    private utils: UtilsService,
    private settings: SettingsValuesService,
    ) { 
  }

  public async get(inParams: { orderId: number }): 
  Promise<{ result: { Id?: number, ActualAmount?: number, GrossWeight?: number, NetWeight?: number, OrderLine?: { OrderId?: number, Amount?: number, Material?: { Description?: string, LookupCode?: string, Tasks?: { ActualPackagedAmount?: number, ActualSourceLicensePlateId?: number, ActualTargetLicensePlateId?: number, Lot?: { LookupCode?: string, VendorLot?: { LookupCode?: string } } }[] } }, ds_outbound_header_bill_of_lading_report_SalesOrders?: { Id?: number, CreatedSysUser?: string, LookupCode?: string, OwnerReference?: string, RequestedDeliveryDate?: string, VendorReference?: string, ShipmentOrderLookups?: { ShipmentId?: number, Shipment?: { BillOfLading?: string, GrossWeight?: number, ItemCount?: number, PalletCount?: number, SealId?: string, ShippedDate?: string, TrackingIdentifier?: string, TrailerId?: string } }[], PreferredCarrier?: { Name?: string }, PreferredWarehouse?: { Name?: string, WarehousesContactsLookup?: { ContactId?: number, Contact?: { PrimaryTelephone?: string, Address?: { AttentionOf?: string, City?: string, Country?: string, Line1?: string, PostalCode?: string, State?: string } } }[] }, Project?: { Name?: string, ProjectsContactsLookup?: { ContactId?: number, Contact?: { Id?: number, Address?: { City?: string, Line1?: string, Line2?: string, PostalCode?: string, State?: string } } }[] }, ShipToAddress?: { AttentionOf?: string, FirstName?: string, LastName?: string, Line1?: string, Line2?: string, City?: string, State?: string, PostalCode?: string, Country?: string, PrimaryTelephone?: string, OrderId?: number } }[], ActualPackagedAmount?: number, VendorLotLookupCode?: string, LotLookupCode?: string, PalletCount?: number }[] }> 
  {
    const missingRequiredInParams = [];
    if (isNil(inParams.orderId)) {
      missingRequiredInParams.push('\'orderId\'');
    }
    if (missingRequiredInParams.length) {
    }
    let url = `${environment.backendUrl}api/app/datasources/custom_ds_bol_report_details_sh/get`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.utils.http.post(url, body, options);
    
  }
  public async getList(inParams: { orderId: number, $top?: number, $skip?: number }): 
  Promise<{ result: { Id?: number, ActualAmount?: number, GrossWeight?: number, NetWeight?: number, OrderLine?: { OrderId?: number, Amount?: number, Material?: { Description?: string, LookupCode?: string, Tasks?: { ActualPackagedAmount?: number, ActualSourceLicensePlateId?: number, ActualTargetLicensePlateId?: number, Lot?: { LookupCode?: string, VendorLot?: { LookupCode?: string } } }[] } }, ds_outbound_header_bill_of_lading_report_SalesOrders?: { Id?: number, CreatedSysUser?: string, LookupCode?: string, OwnerReference?: string, RequestedDeliveryDate?: string, VendorReference?: string, ShipmentOrderLookups?: { ShipmentId?: number, Shipment?: { BillOfLading?: string, GrossWeight?: number, ItemCount?: number, PalletCount?: number, SealId?: string, ShippedDate?: string, TrackingIdentifier?: string, TrailerId?: string } }[], PreferredCarrier?: { Name?: string }, PreferredWarehouse?: { Name?: string, WarehousesContactsLookup?: { ContactId?: number, Contact?: { PrimaryTelephone?: string, Address?: { AttentionOf?: string, City?: string, Country?: string, Line1?: string, PostalCode?: string, State?: string } } }[] }, Project?: { Name?: string, ProjectsContactsLookup?: { ContactId?: number, Contact?: { Id?: number, Address?: { City?: string, Line1?: string, Line2?: string, PostalCode?: string, State?: string } } }[] }, ShipToAddress?: { AttentionOf?: string, FirstName?: string, LastName?: string, Line1?: string, Line2?: string, City?: string, State?: string, PostalCode?: string, Country?: string, PrimaryTelephone?: string, OrderId?: number } }[], ActualPackagedAmount?: number, VendorLotLookupCode?: string, LotLookupCode?: string, PalletCount?: number }[], totalCount: number }> 
  {
    const missingRequiredInParams = [];
    if (isNil(inParams.orderId)) {
      missingRequiredInParams.push('\'orderId\'');
    }
    if (missingRequiredInParams.length) {
    }
    let url = `${environment.backendUrl}api/app/datasources/custom_ds_bol_report_details_sh/getList`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.utils.http.post(url, body, options);
    
  }
  public async getByKeys(inParams: { orderId: number, $keys: number[] }): 
  Promise<{ result: { Id?: number, ActualAmount?: number, GrossWeight?: number, NetWeight?: number, OrderLine?: { OrderId?: number, Amount?: number, Material?: { Description?: string, LookupCode?: string, Tasks?: { ActualPackagedAmount?: number, ActualSourceLicensePlateId?: number, ActualTargetLicensePlateId?: number, Lot?: { LookupCode?: string, VendorLot?: { LookupCode?: string } } }[] } }, ds_outbound_header_bill_of_lading_report_SalesOrders?: { Id?: number, CreatedSysUser?: string, LookupCode?: string, OwnerReference?: string, RequestedDeliveryDate?: string, VendorReference?: string, ShipmentOrderLookups?: { ShipmentId?: number, Shipment?: { BillOfLading?: string, GrossWeight?: number, ItemCount?: number, PalletCount?: number, SealId?: string, ShippedDate?: string, TrackingIdentifier?: string, TrailerId?: string } }[], PreferredCarrier?: { Name?: string }, PreferredWarehouse?: { Name?: string, WarehousesContactsLookup?: { ContactId?: number, Contact?: { PrimaryTelephone?: string, Address?: { AttentionOf?: string, City?: string, Country?: string, Line1?: string, PostalCode?: string, State?: string } } }[] }, Project?: { Name?: string, ProjectsContactsLookup?: { ContactId?: number, Contact?: { Id?: number, Address?: { City?: string, Line1?: string, Line2?: string, PostalCode?: string, State?: string } } }[] }, ShipToAddress?: { AttentionOf?: string, FirstName?: string, LastName?: string, Line1?: string, Line2?: string, City?: string, State?: string, PostalCode?: string, Country?: string, PrimaryTelephone?: string, OrderId?: number } }[], ActualPackagedAmount?: number, VendorLotLookupCode?: string, LotLookupCode?: string, PalletCount?: number }[] }> 
  {
    const missingRequiredInParams = [];
    if (isNil(inParams.orderId)) {
      missingRequiredInParams.push('\'orderId\'');
    }
    if (isNil(inParams.$keys)) {
      missingRequiredInParams.push('\'$keys\'');
    }
    if (missingRequiredInParams.length) {
    }
    let url = `${environment.backendUrl}api/app/datasources/custom_ds_bol_report_details_sh/getByKeys`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.utils.http.post(url, body, options);
    
  }


    
    hasMissingRequiredInParams(inParams: { 
      orderId: number    }) {
        if(isNil(inParams.orderId)) {
          return true; 
        }
      return false;
    }
}
