<div class="blade-wrapper">
  <div class="blade-header">
    <div *ngIf="initialized && $hasDataLoaded && !$hasMissingRequiredInParams" class="blade-tools">
        <app-toolbar [toolbar]="toolbar">
            <ng-template 
              toolbarToolDef="confirm" let-tool>
              <ng-container  *ngIf="tool.hasButtonsToShow">
              <button mat-button
                      data-cy="button"
                      class="datex-button"
                      [disabled]="tool.control.readOnly"
                      [ngStyle]="tool.control.styles.style"
                      [ngClass]="tool.control.styles.classes"
                      [matTooltip]="tool.control.tooltip"
                      matTooltipClass="datex-control-tooltip"
                      (click)="on_confirm_clicked($event)"
                      >
                <div class="button-label">
                  <ng-container *ngIf="tool.control.icon === 'datex-default-spinner'; else showIcon">
                    <div class="button-icon">
                      <i data-cy="button-icon" class="datex-default-spinner"></i>
                    </div>
                  </ng-container>
                  <ng-template #showIcon>
                    <div class="button-icon">
                      <i data-cy="button-icon" class="icon {{tool.control.icon}}"></i>
                    </div>
                  </ng-template>
                  <div data-cy="button-label" class="button-text">{{tool.control.label}}</div>
                </div>
              
              </button>
              </ng-container>
            </ng-template>
            <ng-template 
              toolbarToolDef="cancel" let-tool>
              <ng-container  *ngIf="tool.hasButtonsToShow">
              <button mat-button
                      data-cy="button"
                      class="datex-button"
                      [disabled]="tool.control.readOnly"
                      [ngStyle]="tool.control.styles.style"
                      [ngClass]="tool.control.styles.classes"
                      [matTooltip]="tool.control.tooltip"
                      matTooltipClass="datex-control-tooltip"
                      (click)="on_cancel_clicked($event)"
                      >
                <div class="button-label">
                  <ng-container *ngIf="tool.control.icon === 'datex-default-spinner'; else showIcon">
                    <div class="button-icon">
                      <i data-cy="button-icon" class="datex-default-spinner"></i>
                    </div>
                  </ng-container>
                  <ng-template #showIcon>
                    <div class="button-icon">
                      <i data-cy="button-icon" class="icon {{tool.control.icon}}"></i>
                    </div>
                  </ng-template>
                  <div data-cy="button-label" class="button-text">{{tool.control.label}}</div>
                </div>
              
              </button>
              </ng-container>
            </ng-template>
        </app-toolbar>
    </div>
    <ng-content></ng-content>
  </div>
  <div class="blade-content">
    <div class="datex-editor">
      <ng-container *ngIf="initialized && $hasDataLoaded && !$hasMissingRequiredInParams">
        <div [formGroup]="formGroup"
             class="formdata">
                <div data-cy="fieldset-id-newGroup1"
                     *ngIf="!fieldsets.newGroup1.hidden && !fieldsets.newGroup1.removed"
                     class="fieldset-container">
                  <div class="fieldsetsContainer"
                       [ngClass]="{'collapsible': fieldsets.newGroup1.collapsible }">
                    <div *ngIf="!fieldsets.newGroup1.hideTitle"
                         data-cy="fieldset-title"
                         class="fieldsetsTitle"
                         (click)="fieldsets.newGroup1.toggle()">
                      <span class="fieldsetsTitle-text">
                      {{fieldsets.newGroup1.label}}
                      </span>
                      <i data-cy="fieldset-toggle"
                         *ngIf="fieldsets.newGroup1.collapsible"
                         class="icon"
                         [ngClass]="{'icon-ic_fluent_chevron_down_20_filled': !fieldsets.newGroup1.expanded, 'icon-ic_fluent_chevron_up_20_filled': fieldsets.newGroup1.expanded }"></i>
                    </div>
                    <div data-cy="fieldset-group"
                         *ngIf="fieldsets.newGroup1.expanded"
                         class="fieldsetsGroup">
                      <div data-cy="field-id-lookupcode" *ngIf="!fields.lookupcode.hidden && !fields.lookupcode.removed" 
                            class="field-container double {{fields.lookupcode.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.lookupcode.styles.style"
                            [ngClass]="fields.lookupcode.styles.classes">
                        <div class="label-container"
                             [matTooltip]="fields.lookupcode.label + (fields.lookupcode.required ? ' (required)' : '')"
                             matTooltipClass="datex-control-tooltip">
                          <label data-cy="field-label" class="datex-label">{{fields.lookupcode.label}}<span *ngIf="fields.lookupcode.required" class="required-asterisk">*</span>
                          </label>
                        </div>
                        <input data-cy="textBox" formControlName="lookupcode"
                                matInput
                                autocomplete="off"
                                spellcheck="false"
                                class="datex-textbox {{fields.lookupcode.control.readOnly ? 'readonly ' : ''}}"
                                placeholder="{{fields.lookupcode.control.placeholder}}"
                                [ngStyle]="fields.lookupcode.control.styles.style"
                                [ngClass]="fields.lookupcode.control.styles.classes"
                                [matTooltip]="fields.lookupcode.control.tooltip"
                                matTooltipClass="datex-control-tooltip"> 
                        <ng-container *ngIf="fields.lookupcode.invalid">
                          <ng-container *ngFor="let error of fields.lookupcode.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-status" *ngIf="!fields.status.hidden && !fields.status.removed" 
                            class="field-container double {{fields.status.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.status.styles.style"
                            [ngClass]="fields.status.styles.classes">
                        <div class="label-container"
                             [matTooltip]="fields.status.label + (fields.status.required ? ' (required)' : '')"
                             matTooltipClass="datex-control-tooltip">
                          <label data-cy="field-label" class="datex-label">{{fields.status.label}}<span *ngIf="fields.status.required" class="required-asterisk">*</span>
                          </label>
                        </div>
                        <Materials-material_statuses_dd_single 
                            data-cy="selector"
                            [type]="fields.status.control.type"
                            formControlName="status"
                            (displayTextChange)="fields.status.control.displayText=$event"
                            [placeholder]="fields.status.control.placeholder"
                            [styles]="fields.status.control.styles"
                            [tooltip]="fields.status.control.tooltip"
                        >
                        </Materials-material_statuses_dd_single>
                        <ng-container *ngIf="fields.status.invalid">
                          <ng-container *ngFor="let error of fields.status.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-name" *ngIf="!fields.name.hidden && !fields.name.removed" 
                            class="field-container double {{fields.name.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.name.styles.style"
                            [ngClass]="fields.name.styles.classes">
                        <div class="label-container"
                             [matTooltip]="fields.name.label + (fields.name.required ? ' (required)' : '')"
                             matTooltipClass="datex-control-tooltip">
                          <label data-cy="field-label" class="datex-label">{{fields.name.label}}<span *ngIf="fields.name.required" class="required-asterisk">*</span>
                          </label>
                        </div>
                        <input data-cy="textBox" formControlName="name"
                                matInput
                                autocomplete="off"
                                spellcheck="false"
                                class="datex-textbox {{fields.name.control.readOnly ? 'readonly ' : ''}}"
                                placeholder="{{fields.name.control.placeholder}}"
                                [ngStyle]="fields.name.control.styles.style"
                                [ngClass]="fields.name.control.styles.classes"
                                [matTooltip]="fields.name.control.tooltip"
                                matTooltipClass="datex-control-tooltip"> 
                        <ng-container *ngIf="fields.name.invalid">
                          <ng-container *ngFor="let error of fields.name.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-description" *ngIf="!fields.description.hidden && !fields.description.removed" 
                            class="field-container double {{fields.description.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.description.styles.style"
                            [ngClass]="fields.description.styles.classes">
                        <div class="label-container"
                             [matTooltip]="fields.description.label + (fields.description.required ? ' (required)' : '')"
                             matTooltipClass="datex-control-tooltip">
                          <label data-cy="field-label" class="datex-label">{{fields.description.label}}<span *ngIf="fields.description.required" class="required-asterisk">*</span>
                          </label>
                        </div>
                        <input data-cy="textBox" formControlName="description"
                                matInput
                                autocomplete="off"
                                spellcheck="false"
                                class="datex-textbox {{fields.description.control.readOnly ? 'readonly ' : ''}}"
                                placeholder="{{fields.description.control.placeholder}}"
                                [ngStyle]="fields.description.control.styles.style"
                                [ngClass]="fields.description.control.styles.classes"
                                [matTooltip]="fields.description.control.tooltip"
                                matTooltipClass="datex-control-tooltip"> 
                        <ng-container *ngIf="fields.description.invalid">
                          <ng-container *ngFor="let error of fields.description.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-material_group" *ngIf="!fields.material_group.hidden && !fields.material_group.removed" 
                            class="field-container full {{fields.material_group.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.material_group.styles.style"
                            [ngClass]="fields.material_group.styles.classes">
                        <div class="label-container"
                             [matTooltip]="fields.material_group.label + (fields.material_group.required ? ' (required)' : '')"
                             matTooltipClass="datex-control-tooltip">
                          <label data-cy="field-label" class="datex-label">{{fields.material_group.label}}<span *ngIf="fields.material_group.required" class="required-asterisk">*</span>
                          </label>
                        </div>
                        <Materials-material_groups_dd_single 
                            data-cy="selector"
                            [type]="fields.material_group.control.type"
                            formControlName="material_group"
                            (displayTextChange)="fields.material_group.control.displayText=$event"
                            [placeholder]="fields.material_group.control.placeholder"
                            [styles]="fields.material_group.control.styles"
                            [tooltip]="fields.material_group.control.tooltip"
                        >
                        </Materials-material_groups_dd_single>
                        <ng-container *ngIf="fields.material_group.invalid">
                          <ng-container *ngFor="let error of fields.material_group.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>            </div>
                  </div>
                </div>
                <div data-cy="fieldset-id-newGroup2"
                     *ngIf="!fieldsets.newGroup2.hidden && !fieldsets.newGroup2.removed"
                     class="fieldset-container">
                  <div class="fieldsetsContainer"
                       [ngClass]="{'collapsible': fieldsets.newGroup2.collapsible }">
                    <div *ngIf="!fieldsets.newGroup2.hideTitle"
                         data-cy="fieldset-title"
                         class="fieldsetsTitle"
                         (click)="fieldsets.newGroup2.toggle()">
                      <span class="fieldsetsTitle-text">
                      {{fieldsets.newGroup2.label}}
                      </span>
                      <i data-cy="fieldset-toggle"
                         *ngIf="fieldsets.newGroup2.collapsible"
                         class="icon"
                         [ngClass]="{'icon-ic_fluent_chevron_down_20_filled': !fieldsets.newGroup2.expanded, 'icon-ic_fluent_chevron_up_20_filled': fieldsets.newGroup2.expanded }"></i>
                    </div>
                    <div data-cy="fieldset-group"
                         *ngIf="fieldsets.newGroup2.expanded"
                         class="fieldsetsGroup">
                      <div data-cy="field-id-lot_controlled" *ngIf="!fields.lot_controlled.hidden && !fields.lot_controlled.removed" 
                            class="field-container standard {{fields.lot_controlled.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.lot_controlled.styles.style"
                            [ngClass]="fields.lot_controlled.styles.classes">
                        <div class="label-container"
                             [matTooltip]="fields.lot_controlled.label + (fields.lot_controlled.required ? ' (required)' : '')"
                             matTooltipClass="datex-control-tooltip">
                          <label data-cy="field-label" class="datex-label">{{fields.lot_controlled.label}}<span *ngIf="fields.lot_controlled.required" class="required-asterisk">*</span>
                          </label>
                        </div>
                        <mat-checkbox data-cy="checkBox" formControlName="lot_controlled"
                                      class="datex-checkbox"
                                      color="primary"
                                      [ngStyle]="fields.lot_controlled.control.styles.style"
                                      [ngClass]="fields.lot_controlled.control.styles.classes"
                                      [matTooltip]="fields.lot_controlled.control.tooltip"
                                      matTooltipClass="datex-control-tooltip">{{fields.lot_controlled.control.label}}</mat-checkbox>
                        <ng-container *ngIf="fields.lot_controlled.invalid">
                          <ng-container *ngFor="let error of fields.lot_controlled.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-serial_controlled" *ngIf="!fields.serial_controlled.hidden && !fields.serial_controlled.removed" 
                            class="field-container standard {{fields.serial_controlled.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.serial_controlled.styles.style"
                            [ngClass]="fields.serial_controlled.styles.classes">
                        <div class="label-container"
                             [matTooltip]="fields.serial_controlled.label + (fields.serial_controlled.required ? ' (required)' : '')"
                             matTooltipClass="datex-control-tooltip">
                          <label data-cy="field-label" class="datex-label">{{fields.serial_controlled.label}}<span *ngIf="fields.serial_controlled.required" class="required-asterisk">*</span>
                          </label>
                        </div>
                        <mat-checkbox data-cy="checkBox" formControlName="serial_controlled"
                                      class="datex-checkbox"
                                      color="primary"
                                      [ngStyle]="fields.serial_controlled.control.styles.style"
                                      [ngClass]="fields.serial_controlled.control.styles.classes"
                                      [matTooltip]="fields.serial_controlled.control.tooltip"
                                      matTooltipClass="datex-control-tooltip">{{fields.serial_controlled.control.label}}</mat-checkbox>
                        <ng-container *ngIf="fields.serial_controlled.invalid">
                          <ng-container *ngFor="let error of fields.serial_controlled.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-fixed_dimension" *ngIf="!fields.fixed_dimension.hidden && !fields.fixed_dimension.removed" 
                            class="field-container standard {{fields.fixed_dimension.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.fixed_dimension.styles.style"
                            [ngClass]="fields.fixed_dimension.styles.classes">
                        <div class="label-container"
                             [matTooltip]="fields.fixed_dimension.label + (fields.fixed_dimension.required ? ' (required)' : '')"
                             matTooltipClass="datex-control-tooltip">
                          <label data-cy="field-label" class="datex-label">{{fields.fixed_dimension.label}}<span *ngIf="fields.fixed_dimension.required" class="required-asterisk">*</span>
                          </label>
                        </div>
                        <mat-checkbox data-cy="checkBox" formControlName="fixed_dimension"
                                      class="datex-checkbox"
                                      color="primary"
                                      [ngStyle]="fields.fixed_dimension.control.styles.style"
                                      [ngClass]="fields.fixed_dimension.control.styles.classes"
                                      [matTooltip]="fields.fixed_dimension.control.tooltip"
                                      matTooltipClass="datex-control-tooltip">{{fields.fixed_dimension.control.label}}</mat-checkbox>
                        <ng-container *ngIf="fields.fixed_dimension.invalid">
                          <ng-container *ngFor="let error of fields.fixed_dimension.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-fixed_weight" *ngIf="!fields.fixed_weight.hidden && !fields.fixed_weight.removed" 
                            class="field-container standard {{fields.fixed_weight.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.fixed_weight.styles.style"
                            [ngClass]="fields.fixed_weight.styles.classes">
                        <div class="label-container"
                             [matTooltip]="fields.fixed_weight.label + (fields.fixed_weight.required ? ' (required)' : '')"
                             matTooltipClass="datex-control-tooltip">
                          <label data-cy="field-label" class="datex-label">{{fields.fixed_weight.label}}<span *ngIf="fields.fixed_weight.required" class="required-asterisk">*</span>
                          </label>
                        </div>
                        <mat-checkbox data-cy="checkBox" formControlName="fixed_weight"
                                      class="datex-checkbox"
                                      color="primary"
                                      [ngStyle]="fields.fixed_weight.control.styles.style"
                                      [ngClass]="fields.fixed_weight.control.styles.classes"
                                      [matTooltip]="fields.fixed_weight.control.tooltip"
                                      matTooltipClass="datex-control-tooltip">{{fields.fixed_weight.control.label}}</mat-checkbox>
                        <ng-container *ngIf="fields.fixed_weight.invalid">
                          <ng-container *ngFor="let error of fields.fixed_weight.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-allocation_strategy_workflow" *ngIf="!fields.allocation_strategy_workflow.hidden && !fields.allocation_strategy_workflow.removed" 
                            class="field-container standard {{fields.allocation_strategy_workflow.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.allocation_strategy_workflow.styles.style"
                            [ngClass]="fields.allocation_strategy_workflow.styles.classes">
                        <div class="label-container"
                             [matTooltip]="fields.allocation_strategy_workflow.label + (fields.allocation_strategy_workflow.required ? ' (required)' : '')"
                             matTooltipClass="datex-control-tooltip">
                          <label data-cy="field-label" class="datex-label">{{fields.allocation_strategy_workflow.label}}<span *ngIf="fields.allocation_strategy_workflow.required" class="required-asterisk">*</span>
                          </label>
                        </div>
                        <Materials-allocation_strategy_workflows_dd_single 
                            data-cy="selector"
                            [type]="fields.allocation_strategy_workflow.control.type"
                            formControlName="allocation_strategy_workflow"
                            (displayTextChange)="fields.allocation_strategy_workflow.control.displayText=$event"
                            [placeholder]="fields.allocation_strategy_workflow.control.placeholder"
                            [styles]="fields.allocation_strategy_workflow.control.styles"
                            [tooltip]="fields.allocation_strategy_workflow.control.tooltip"
                          [is_active]="$fields_allocation_strategy_workflow_selector_inParams_is_active"
                        >
                        </Materials-allocation_strategy_workflows_dd_single>
                        <ng-container *ngIf="fields.allocation_strategy_workflow.invalid">
                          <ng-container *ngFor="let error of fields.allocation_strategy_workflow.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-storage_category_rule" *ngIf="!fields.storage_category_rule.hidden && !fields.storage_category_rule.removed" 
                            class="field-container standard {{fields.storage_category_rule.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.storage_category_rule.styles.style"
                            [ngClass]="fields.storage_category_rule.styles.classes">
                        <div class="label-container"
                             [matTooltip]="fields.storage_category_rule.label + (fields.storage_category_rule.required ? ' (required)' : '')"
                             matTooltipClass="datex-control-tooltip">
                          <label data-cy="field-label" class="datex-label">{{fields.storage_category_rule.label}}<span *ngIf="fields.storage_category_rule.required" class="required-asterisk">*</span>
                          </label>
                        </div>
                        <Materials-storage_category_rules_dd_single 
                            data-cy="selector"
                            [type]="fields.storage_category_rule.control.type"
                            formControlName="storage_category_rule"
                            (displayTextChange)="fields.storage_category_rule.control.displayText=$event"
                            [placeholder]="fields.storage_category_rule.control.placeholder"
                            [styles]="fields.storage_category_rule.control.styles"
                            [tooltip]="fields.storage_category_rule.control.tooltip"
                        >
                        </Materials-storage_category_rules_dd_single>
                        <ng-container *ngIf="fields.storage_category_rule.invalid">
                          <ng-container *ngFor="let error of fields.storage_category_rule.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-shelf_life_span" *ngIf="!fields.shelf_life_span.hidden && !fields.shelf_life_span.removed" 
                            class="field-container standard {{fields.shelf_life_span.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.shelf_life_span.styles.style"
                            [ngClass]="fields.shelf_life_span.styles.classes">
                        <div class="label-container"
                             [matTooltip]="fields.shelf_life_span.label + (fields.shelf_life_span.required ? ' (required)' : '')"
                             matTooltipClass="datex-control-tooltip">
                          <label data-cy="field-label" class="datex-label">{{fields.shelf_life_span.label}}<span *ngIf="fields.shelf_life_span.required" class="required-asterisk">*</span>
                          </label>
                        </div>
                        <input data-cy="numberBox" formControlName="shelf_life_span"
                                matInput
                                numberBox
                                [format]="fields.shelf_life_span.control.format"
                                autocomplete="off"
                                spellcheck="false"
                                class="datex-numberbox {{fields.shelf_life_span.control.readOnly ? 'readonly ' : ''}}"
                                placeholder="{{fields.shelf_life_span.control.placeholder}}"
                                [ngStyle]="fields.shelf_life_span.control.styles.style"
                                [ngClass]="fields.shelf_life_span.control.styles.classes"
                                [matTooltip]="fields.shelf_life_span.control.tooltip"
                                matTooltipClass="datex-control-tooltip">
                        <ng-container *ngIf="fields.shelf_life_span.invalid">
                          <ng-container *ngFor="let error of fields.shelf_life_span.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-minimum_dating_span" *ngIf="!fields.minimum_dating_span.hidden && !fields.minimum_dating_span.removed" 
                            class="field-container standard {{fields.minimum_dating_span.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.minimum_dating_span.styles.style"
                            [ngClass]="fields.minimum_dating_span.styles.classes">
                        <div class="label-container"
                             [matTooltip]="fields.minimum_dating_span.label + (fields.minimum_dating_span.required ? ' (required)' : '')"
                             matTooltipClass="datex-control-tooltip">
                          <label data-cy="field-label" class="datex-label">{{fields.minimum_dating_span.label}}<span *ngIf="fields.minimum_dating_span.required" class="required-asterisk">*</span>
                          </label>
                        </div>
                        <input data-cy="numberBox" formControlName="minimum_dating_span"
                                matInput
                                numberBox
                                [format]="fields.minimum_dating_span.control.format"
                                autocomplete="off"
                                spellcheck="false"
                                class="datex-numberbox {{fields.minimum_dating_span.control.readOnly ? 'readonly ' : ''}}"
                                placeholder="{{fields.minimum_dating_span.control.placeholder}}"
                                [ngStyle]="fields.minimum_dating_span.control.styles.style"
                                [ngClass]="fields.minimum_dating_span.control.styles.classes"
                                [matTooltip]="fields.minimum_dating_span.control.tooltip"
                                matTooltipClass="datex-control-tooltip">
                        <ng-container *ngIf="fields.minimum_dating_span.invalid">
                          <ng-container *ngFor="let error of fields.minimum_dating_span.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-skip_cycle_count" *ngIf="!fields.skip_cycle_count.hidden && !fields.skip_cycle_count.removed" 
                            class="field-container standard {{fields.skip_cycle_count.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.skip_cycle_count.styles.style"
                            [ngClass]="fields.skip_cycle_count.styles.classes">
                        <div class="label-container"
                             [matTooltip]="fields.skip_cycle_count.label + (fields.skip_cycle_count.required ? ' (required)' : '')"
                             matTooltipClass="datex-control-tooltip">
                          <label data-cy="field-label" class="datex-label">{{fields.skip_cycle_count.label}}<span *ngIf="fields.skip_cycle_count.required" class="required-asterisk">*</span>
                          </label>
                        </div>
                        <mat-checkbox data-cy="checkBox" formControlName="skip_cycle_count"
                                      class="datex-checkbox"
                                      color="primary"
                                      [ngStyle]="fields.skip_cycle_count.control.styles.style"
                                      [ngClass]="fields.skip_cycle_count.control.styles.classes"
                                      [matTooltip]="fields.skip_cycle_count.control.tooltip"
                                      matTooltipClass="datex-control-tooltip">{{fields.skip_cycle_count.control.label}}</mat-checkbox>
                        <ng-container *ngIf="fields.skip_cycle_count.invalid">
                          <ng-container *ngFor="let error of fields.skip_cycle_count.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-skip_count_back" *ngIf="!fields.skip_count_back.hidden && !fields.skip_count_back.removed" 
                            class="field-container standard {{fields.skip_count_back.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.skip_count_back.styles.style"
                            [ngClass]="fields.skip_count_back.styles.classes">
                        <div class="label-container"
                             [matTooltip]="fields.skip_count_back.label + (fields.skip_count_back.required ? ' (required)' : '')"
                             matTooltipClass="datex-control-tooltip">
                          <label data-cy="field-label" class="datex-label">{{fields.skip_count_back.label}}<span *ngIf="fields.skip_count_back.required" class="required-asterisk">*</span>
                          </label>
                        </div>
                        <mat-checkbox data-cy="checkBox" formControlName="skip_count_back"
                                      class="datex-checkbox"
                                      color="primary"
                                      [ngStyle]="fields.skip_count_back.control.styles.style"
                                      [ngClass]="fields.skip_count_back.control.styles.classes"
                                      [matTooltip]="fields.skip_count_back.control.tooltip"
                                      matTooltipClass="datex-control-tooltip">{{fields.skip_count_back.control.label}}</mat-checkbox>
                        <ng-container *ngIf="fields.skip_count_back.invalid">
                          <ng-container *ngFor="let error of fields.skip_count_back.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-scan_serial_of_all_package_levels" *ngIf="!fields.scan_serial_of_all_package_levels.hidden && !fields.scan_serial_of_all_package_levels.removed" 
                            class="field-container standard {{fields.scan_serial_of_all_package_levels.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.scan_serial_of_all_package_levels.styles.style"
                            [ngClass]="fields.scan_serial_of_all_package_levels.styles.classes">
                        <div class="label-container"
                             [matTooltip]="fields.scan_serial_of_all_package_levels.label + (fields.scan_serial_of_all_package_levels.required ? ' (required)' : '')"
                             matTooltipClass="datex-control-tooltip">
                          <label data-cy="field-label" class="datex-label">{{fields.scan_serial_of_all_package_levels.label}}<span *ngIf="fields.scan_serial_of_all_package_levels.required" class="required-asterisk">*</span>
                          </label>
                        </div>
                        <mat-checkbox data-cy="checkBox" formControlName="scan_serial_of_all_package_levels"
                                      class="datex-checkbox"
                                      color="primary"
                                      [ngStyle]="fields.scan_serial_of_all_package_levels.control.styles.style"
                                      [ngClass]="fields.scan_serial_of_all_package_levels.control.styles.classes"
                                      [matTooltip]="fields.scan_serial_of_all_package_levels.control.tooltip"
                                      matTooltipClass="datex-control-tooltip">{{fields.scan_serial_of_all_package_levels.control.label}}</mat-checkbox>
                        <ng-container *ngIf="fields.scan_serial_of_all_package_levels.invalid">
                          <ng-container *ngFor="let error of fields.scan_serial_of_all_package_levels.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-allow_receiving" *ngIf="!fields.allow_receiving.hidden && !fields.allow_receiving.removed" 
                            class="field-container standard {{fields.allow_receiving.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.allow_receiving.styles.style"
                            [ngClass]="fields.allow_receiving.styles.classes">
                        <div class="label-container"
                             [matTooltip]="fields.allow_receiving.label + (fields.allow_receiving.required ? ' (required)' : '')"
                             matTooltipClass="datex-control-tooltip">
                          <label data-cy="field-label" class="datex-label">{{fields.allow_receiving.label}}<span *ngIf="fields.allow_receiving.required" class="required-asterisk">*</span>
                          </label>
                        </div>
                        <mat-checkbox data-cy="checkBox" formControlName="allow_receiving"
                                      class="datex-checkbox"
                                      color="primary"
                                      [ngStyle]="fields.allow_receiving.control.styles.style"
                                      [ngClass]="fields.allow_receiving.control.styles.classes"
                                      [matTooltip]="fields.allow_receiving.control.tooltip"
                                      matTooltipClass="datex-control-tooltip">{{fields.allow_receiving.control.label}}</mat-checkbox>
                        <ng-container *ngIf="fields.allow_receiving.invalid">
                          <ng-container *ngFor="let error of fields.allow_receiving.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>            </div>
                  </div>
                </div>
                <div data-cy="fieldset-id-manufacturing"
                     *ngIf="!fieldsets.manufacturing.hidden && !fieldsets.manufacturing.removed"
                     class="fieldset-container">
                  <div class="fieldsetsContainer"
                       [ngClass]="{'collapsible': fieldsets.manufacturing.collapsible }">
                    <div *ngIf="!fieldsets.manufacturing.hideTitle"
                         data-cy="fieldset-title"
                         class="fieldsetsTitle"
                         (click)="fieldsets.manufacturing.toggle()">
                      <span class="fieldsetsTitle-text">
                      {{fieldsets.manufacturing.label}}
                      </span>
                      <i data-cy="fieldset-toggle"
                         *ngIf="fieldsets.manufacturing.collapsible"
                         class="icon"
                         [ngClass]="{'icon-ic_fluent_chevron_down_20_filled': !fieldsets.manufacturing.expanded, 'icon-ic_fluent_chevron_up_20_filled': fieldsets.manufacturing.expanded }"></i>
                    </div>
                    <div data-cy="fieldset-group"
                         *ngIf="fieldsets.manufacturing.expanded"
                         class="fieldsetsGroup">
                      <div data-cy="field-id-material_type" *ngIf="!fields.material_type.hidden && !fields.material_type.removed" 
                            class="field-container double {{fields.material_type.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.material_type.styles.style"
                            [ngClass]="fields.material_type.styles.classes">
                        <div class="label-container"
                             [matTooltip]="fields.material_type.label + (fields.material_type.required ? ' (required)' : '')"
                             matTooltipClass="datex-control-tooltip">
                          <label data-cy="field-label" class="datex-label">{{fields.material_type.label}}<span *ngIf="fields.material_type.required" class="required-asterisk">*</span>
                          </label>
                        </div>
                        <FootPrintManager-material_type_dd_single 
                            data-cy="selector"
                            [type]="fields.material_type.control.type"
                            formControlName="material_type"
                            (displayTextChange)="fields.material_type.control.displayText=$event"
                            [placeholder]="fields.material_type.control.placeholder"
                            [styles]="fields.material_type.control.styles"
                            [tooltip]="fields.material_type.control.tooltip"
                        >
                        </FootPrintManager-material_type_dd_single>
                        <ng-container *ngIf="fields.material_type.invalid">
                          <ng-container *ngFor="let error of fields.material_type.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-allowed_time_outside_storage" *ngIf="!fields.allowed_time_outside_storage.hidden && !fields.allowed_time_outside_storage.removed" 
                            class="field-container double {{fields.allowed_time_outside_storage.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.allowed_time_outside_storage.styles.style"
                            [ngClass]="fields.allowed_time_outside_storage.styles.classes">
                        <div class="label-container"
                             [matTooltip]="fields.allowed_time_outside_storage.label + (fields.allowed_time_outside_storage.required ? ' (required)' : '')"
                             matTooltipClass="datex-control-tooltip">
                          <label data-cy="field-label" class="datex-label">{{fields.allowed_time_outside_storage.label}}<span *ngIf="fields.allowed_time_outside_storage.required" class="required-asterisk">*</span>
                          </label>
                        </div>
                        <input data-cy="numberBox" formControlName="allowed_time_outside_storage"
                                matInput
                                numberBox
                                [format]="fields.allowed_time_outside_storage.control.format"
                                autocomplete="off"
                                spellcheck="false"
                                class="datex-numberbox {{fields.allowed_time_outside_storage.control.readOnly ? 'readonly ' : ''}}"
                                placeholder="{{fields.allowed_time_outside_storage.control.placeholder}}"
                                [ngStyle]="fields.allowed_time_outside_storage.control.styles.style"
                                [ngClass]="fields.allowed_time_outside_storage.control.styles.classes"
                                [matTooltip]="fields.allowed_time_outside_storage.control.tooltip"
                                matTooltipClass="datex-control-tooltip">
                        <ng-container *ngIf="fields.allowed_time_outside_storage.invalid">
                          <ng-container *ngFor="let error of fields.allowed_time_outside_storage.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-production_rate" *ngIf="!fields.production_rate.hidden && !fields.production_rate.removed" 
                            class="field-container double {{fields.production_rate.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.production_rate.styles.style"
                            [ngClass]="fields.production_rate.styles.classes">
                        <div class="label-container"
                             [matTooltip]="fields.production_rate.label + (fields.production_rate.required ? ' (required)' : '')"
                             matTooltipClass="datex-control-tooltip">
                          <label data-cy="field-label" class="datex-label">{{fields.production_rate.label}}<span *ngIf="fields.production_rate.required" class="required-asterisk">*</span>
                          </label>
                        </div>
                        <input data-cy="numberBox" formControlName="production_rate"
                                matInput
                                numberBox
                                [format]="fields.production_rate.control.format"
                                autocomplete="off"
                                spellcheck="false"
                                class="datex-numberbox {{fields.production_rate.control.readOnly ? 'readonly ' : ''}}"
                                placeholder="{{fields.production_rate.control.placeholder}}"
                                [ngStyle]="fields.production_rate.control.styles.style"
                                [ngClass]="fields.production_rate.control.styles.classes"
                                [matTooltip]="fields.production_rate.control.tooltip"
                                matTooltipClass="datex-control-tooltip">
                        <ng-container *ngIf="fields.production_rate.invalid">
                          <ng-container *ngFor="let error of fields.production_rate.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-required_crew_size" *ngIf="!fields.required_crew_size.hidden && !fields.required_crew_size.removed" 
                            class="field-container double {{fields.required_crew_size.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.required_crew_size.styles.style"
                            [ngClass]="fields.required_crew_size.styles.classes">
                        <div class="label-container"
                             [matTooltip]="fields.required_crew_size.label + (fields.required_crew_size.required ? ' (required)' : '')"
                             matTooltipClass="datex-control-tooltip">
                          <label data-cy="field-label" class="datex-label">{{fields.required_crew_size.label}}<span *ngIf="fields.required_crew_size.required" class="required-asterisk">*</span>
                          </label>
                        </div>
                        <input data-cy="numberBox" formControlName="required_crew_size"
                                matInput
                                numberBox
                                [format]="fields.required_crew_size.control.format"
                                autocomplete="off"
                                spellcheck="false"
                                class="datex-numberbox {{fields.required_crew_size.control.readOnly ? 'readonly ' : ''}}"
                                placeholder="{{fields.required_crew_size.control.placeholder}}"
                                [ngStyle]="fields.required_crew_size.control.styles.style"
                                [ngClass]="fields.required_crew_size.control.styles.classes"
                                [matTooltip]="fields.required_crew_size.control.tooltip"
                                matTooltipClass="datex-control-tooltip">
                        <ng-container *ngIf="fields.required_crew_size.invalid">
                          <ng-container *ngFor="let error of fields.required_crew_size.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-use_oldest_expiry_date" *ngIf="!fields.use_oldest_expiry_date.hidden && !fields.use_oldest_expiry_date.removed" 
                            class="field-container double {{fields.use_oldest_expiry_date.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.use_oldest_expiry_date.styles.style"
                            [ngClass]="fields.use_oldest_expiry_date.styles.classes">
                        <div class="label-container"
                             [matTooltip]="fields.use_oldest_expiry_date.label + (fields.use_oldest_expiry_date.required ? ' (required)' : '')"
                             matTooltipClass="datex-control-tooltip">
                          <label data-cy="field-label" class="datex-label">{{fields.use_oldest_expiry_date.label}}<span *ngIf="fields.use_oldest_expiry_date.required" class="required-asterisk">*</span>
                          </label>
                        </div>
                        <mat-checkbox data-cy="checkBox" formControlName="use_oldest_expiry_date"
                                      class="datex-checkbox"
                                      color="primary"
                                      [ngStyle]="fields.use_oldest_expiry_date.control.styles.style"
                                      [ngClass]="fields.use_oldest_expiry_date.control.styles.classes"
                                      [matTooltip]="fields.use_oldest_expiry_date.control.tooltip"
                                      matTooltipClass="datex-control-tooltip">{{fields.use_oldest_expiry_date.control.label}}</mat-checkbox>
                        <ng-container *ngIf="fields.use_oldest_expiry_date.invalid">
                          <ng-container *ngFor="let error of fields.use_oldest_expiry_date.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-skip_autocreation_of_move_tasks" *ngIf="!fields.skip_autocreation_of_move_tasks.hidden && !fields.skip_autocreation_of_move_tasks.removed" 
                            class="field-container double {{fields.skip_autocreation_of_move_tasks.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.skip_autocreation_of_move_tasks.styles.style"
                            [ngClass]="fields.skip_autocreation_of_move_tasks.styles.classes">
                        <div class="label-container"
                             [matTooltip]="fields.skip_autocreation_of_move_tasks.label + (fields.skip_autocreation_of_move_tasks.required ? ' (required)' : '')"
                             matTooltipClass="datex-control-tooltip">
                          <label data-cy="field-label" class="datex-label">{{fields.skip_autocreation_of_move_tasks.label}}<span *ngIf="fields.skip_autocreation_of_move_tasks.required" class="required-asterisk">*</span>
                          </label>
                        </div>
                        <mat-checkbox data-cy="checkBox" formControlName="skip_autocreation_of_move_tasks"
                                      class="datex-checkbox"
                                      color="primary"
                                      [ngStyle]="fields.skip_autocreation_of_move_tasks.control.styles.style"
                                      [ngClass]="fields.skip_autocreation_of_move_tasks.control.styles.classes"
                                      [matTooltip]="fields.skip_autocreation_of_move_tasks.control.tooltip"
                                      matTooltipClass="datex-control-tooltip">{{fields.skip_autocreation_of_move_tasks.control.label}}</mat-checkbox>
                        <ng-container *ngIf="fields.skip_autocreation_of_move_tasks.invalid">
                          <ng-container *ngFor="let error of fields.skip_autocreation_of_move_tasks.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-enable_packaging_review" *ngIf="!fields.enable_packaging_review.hidden && !fields.enable_packaging_review.removed" 
                            class="field-container double {{fields.enable_packaging_review.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.enable_packaging_review.styles.style"
                            [ngClass]="fields.enable_packaging_review.styles.classes">
                        <div class="label-container"
                             [matTooltip]="fields.enable_packaging_review.label + (fields.enable_packaging_review.required ? ' (required)' : '')"
                             matTooltipClass="datex-control-tooltip">
                          <label data-cy="field-label" class="datex-label">{{fields.enable_packaging_review.label}}<span *ngIf="fields.enable_packaging_review.required" class="required-asterisk">*</span>
                          </label>
                        </div>
                        <mat-slide-toggle data-cy="slideToggle" formControlName="enable_packaging_review"
                                      color="primary"
                                      class="datex-toggle"
                                      [ngStyle]="fields.enable_packaging_review.control.styles.style"
                                      [ngClass]="fields.enable_packaging_review.control.styles.classes"
                                      [matTooltip]="fields.enable_packaging_review.control.tooltip"
                                      matTooltipClass="datex-control-tooltip">{{fields.enable_packaging_review.control.label}}</mat-slide-toggle>
                        <ng-container *ngIf="fields.enable_packaging_review.invalid">
                          <ng-container *ngFor="let error of fields.enable_packaging_review.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-set_packaging_review_reminder" *ngIf="!fields.set_packaging_review_reminder.hidden && !fields.set_packaging_review_reminder.removed" 
                            class="field-container double {{fields.set_packaging_review_reminder.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.set_packaging_review_reminder.styles.style"
                            [ngClass]="fields.set_packaging_review_reminder.styles.classes">
                        <div class="label-container"
                             [matTooltip]="fields.set_packaging_review_reminder.label + (fields.set_packaging_review_reminder.required ? ' (required)' : '')"
                             matTooltipClass="datex-control-tooltip">
                          <label data-cy="field-label" class="datex-label">{{fields.set_packaging_review_reminder.label}}<span *ngIf="fields.set_packaging_review_reminder.required" class="required-asterisk">*</span>
                          </label>
                        </div>
                        <app-datebox 
                            data-cy="dateBox"
                            formControlName="set_packaging_review_reminder"
                            [format]="fields.set_packaging_review_reminder.control.format"
                            [mode]="fields.set_packaging_review_reminder.control.mode"
                            [ngStyle]="fields.set_packaging_review_reminder.control.styles.style"
                            [ngClass]="fields.set_packaging_review_reminder.control.styles.classes"
                            [tooltip]="fields.set_packaging_review_reminder.control.tooltip">
                        </app-datebox>
                        <ng-container *ngIf="fields.set_packaging_review_reminder.invalid">
                          <ng-container *ngFor="let error of fields.set_packaging_review_reminder.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-review_new_packaging_type_after" *ngIf="!fields.review_new_packaging_type_after.hidden && !fields.review_new_packaging_type_after.removed" 
                            class="field-container double {{fields.review_new_packaging_type_after.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.review_new_packaging_type_after.styles.style"
                            [ngClass]="fields.review_new_packaging_type_after.styles.classes">
                        <div class="label-container"
                             [matTooltip]="fields.review_new_packaging_type_after.label + (fields.review_new_packaging_type_after.required ? ' (required)' : '')"
                             matTooltipClass="datex-control-tooltip">
                          <label data-cy="field-label" class="datex-label">{{fields.review_new_packaging_type_after.label}}<span *ngIf="fields.review_new_packaging_type_after.required" class="required-asterisk">*</span>
                          </label>
                        </div>
                        <input data-cy="numberBox" formControlName="review_new_packaging_type_after"
                                matInput
                                numberBox
                                [format]="fields.review_new_packaging_type_after.control.format"
                                autocomplete="off"
                                spellcheck="false"
                                class="datex-numberbox {{fields.review_new_packaging_type_after.control.readOnly ? 'readonly ' : ''}}"
                                placeholder="{{fields.review_new_packaging_type_after.control.placeholder}}"
                                [ngStyle]="fields.review_new_packaging_type_after.control.styles.style"
                                [ngClass]="fields.review_new_packaging_type_after.control.styles.classes"
                                [matTooltip]="fields.review_new_packaging_type_after.control.tooltip"
                                matTooltipClass="datex-control-tooltip">
                        <ng-container *ngIf="fields.review_new_packaging_type_after.invalid">
                          <ng-container *ngFor="let error of fields.review_new_packaging_type_after.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>            </div>
                  </div>
                </div>
              </div>


      </ng-container>

      <div *ngIf="$hasMissingRequiredInParams" class="missing-params">
        <h3>Please provide {{$missingRequiredInParams.toString()}}</h3>
      </div>
      <div *ngIf="initialized && !$hasDataLoaded && !$hasMissingRequiredInParams" class="missing-params">
        <h3>No data to display</h3>
      </div>
    </div>
  </div>
</div>