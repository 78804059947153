import { Injectable } from '@angular/core';
import { ParamMap } from '@angular/router';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { isNil, cloneDeep } from 'lodash-es';
import { ToastrService } from 'ngx-toastr';

import { WavelengthShellService } from 'wavelength-ui';
import { EModalSize, EToasterType, EToasterPosition } from 'wavelength-ui';


import { CleanupLoggerService } from './cleanup.logging.service';
import { BarTender_azure_blob_storage_create_directory_formComponent } from './BarTender.azure_blob_storage_create_directory_form.component';
import { BarTender_bartender_homeComponent } from './BarTender.bartender_home.component';
import { BarTender_configurations_formComponent } from './BarTender.configurations_form.component';
import { BarTender_azure_blob_storage_navigation_gridComponent } from './BarTender.azure_blob_storage_navigation_grid.component';
import { BarTender_bartender_hubComponent } from './BarTender.bartender_hub.component';
import { BarTender_view_fileComponent } from './BarTender.view_file.component';
import { BarTender_printers_dd_singleComponent } from './BarTender.printers_dd_single.component';
import { BarTender_reflect_strings_dd_singleComponent } from './BarTender.reflect_strings_dd_single.component';
import { BarTender_printers_dd_multiComponent } from './BarTender.printers_dd_multi.component';
import { BarTender_reflect_strings_dd_multiComponent } from './BarTender.reflect_strings_dd_multi.component';

import { Utilities_ShellService } from './Utilities.shell.service';

@Injectable({ providedIn: 'root' })
export class BarTender_ShellService extends WavelengthShellService  {
  constructor(
    dialog : MatDialog,
    toastr: ToastrService,
    private logger: CleanupLoggerService,
    public Utilities: Utilities_ShellService,
  ) {
    super(dialog, toastr);
  }

  public BarTender: BarTender_ShellService = this;

  public openazure_blob_storage_create_directory_form(inParams:{ path: string }, replaceCurrentView?: boolean) {
    this.logger.log('BarTender', 'azure_blob_storage_create_directory_form');
    WavelengthShellService.openViewRequest$.next(
      {
        title: 'Create directory',
        referenceName: 'BarTender_azure_blob_storage_create_directory_form',
        component: BarTender_azure_blob_storage_create_directory_formComponent,
        replaceCurrentView: replaceCurrentView,
        inParams: inParams
      }
    );
  }

  public openazure_blob_storage_create_directory_formDialog(
    inParams:{ path: string }, 
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<{ is_confirmed?: boolean }> {
    this.logger.log('BarTender', 'azure_blob_storage_create_directory_form');
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      BarTender_azure_blob_storage_create_directory_formComponent,
      'Create directory',
      mode,
      dialogSize
      , cloneDeep(inParams)
    )
  }
  public openbartender_home(replaceCurrentView?: boolean) {
    this.logger.log('BarTender', 'bartender_home');
    WavelengthShellService.openViewRequest$.next(
      {
        title: 'bartender_home',
        referenceName: 'BarTender_bartender_home',
        component: BarTender_bartender_homeComponent,
        replaceCurrentView: replaceCurrentView,
        
      }
    );
  }

  public openbartender_homeDialog(
    
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    this.logger.log('BarTender', 'bartender_home');
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      BarTender_bartender_homeComponent,
      'bartender_home',
      mode,
      dialogSize
    )
  }
  public openconfigurations_form(replaceCurrentView?: boolean) {
    this.logger.log('BarTender', 'configurations_form');
    WavelengthShellService.openViewRequest$.next(
      {
        title: 'BarTender configurations',
        referenceName: 'BarTender_configurations_form',
        component: BarTender_configurations_formComponent,
        replaceCurrentView: replaceCurrentView,
        
      }
    );
  }

  public openconfigurations_formDialog(
    
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<{ is_confirmed?: boolean }> {
    this.logger.log('BarTender', 'configurations_form');
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      BarTender_configurations_formComponent,
      'BarTender configurations',
      mode,
      dialogSize
    )
  }
  public openazure_blob_storage_navigation_grid(replaceCurrentView?: boolean) {
    this.logger.log('BarTender', 'azure_blob_storage_navigation_grid');
    WavelengthShellService.openViewRequest$.next(
      {
        title: 'Azure blob storage',
        referenceName: 'BarTender_azure_blob_storage_navigation_grid',
        component: BarTender_azure_blob_storage_navigation_gridComponent,
        replaceCurrentView: replaceCurrentView,
        
      }
    );
  }

  public openazure_blob_storage_navigation_gridDialog(
    
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    this.logger.log('BarTender', 'azure_blob_storage_navigation_grid');
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      BarTender_azure_blob_storage_navigation_gridComponent,
      'Azure blob storage',
      mode,
      dialogSize
    )
  }
  public openbartender_hub(replaceCurrentView?: boolean) {
    this.logger.log('BarTender', 'bartender_hub');
    WavelengthShellService.openViewRequest$.next(
      {
        title: 'BarTender hub',
        referenceName: 'BarTender_bartender_hub',
        component: BarTender_bartender_hubComponent,
        replaceCurrentView: replaceCurrentView,
        
      }
    );
  }

  public openbartender_hubDialog(
    
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    this.logger.log('BarTender', 'bartender_hub');
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      BarTender_bartender_hubComponent,
      'BarTender hub',
      mode,
      dialogSize
    )
  }
  public openview_file(inParams:{ file_content: string }, replaceCurrentView?: boolean) {
    this.logger.log('BarTender', 'view_file');
    WavelengthShellService.openViewRequest$.next(
      {
        title: 'View file',
        referenceName: 'BarTender_view_file',
        component: BarTender_view_fileComponent,
        replaceCurrentView: replaceCurrentView,
        inParams: inParams
      }
    );
  }

  public openview_fileDialog(
    inParams:{ file_content: string }, 
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    this.logger.log('BarTender', 'view_file');
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      BarTender_view_fileComponent,
      'View file',
      mode,
      dialogSize
      , cloneDeep(inParams)
    )
  }

  public override getComponentInformation(referenceName: string, params: ParamMap): { title: string, component: any, inParams: any } {
    if (referenceName === 'BarTender_azure_blob_storage_create_directory_form') {
      this.logger.log('BarTender', 'azure_blob_storage_create_directory_form');
      const title = 'Create directory';
      const component = BarTender_azure_blob_storage_create_directory_formComponent;
      const inParams:{ path: string } = { path: null };
      if (!isNil(params.get('path'))) {
        const paramValueString = params.get('path');
        // TODO: date
        inParams.path = paramValueString;
              }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'BarTender_bartender_home') {
      this.logger.log('BarTender', 'bartender_home');
      const title = 'bartender_home';
      const component = BarTender_bartender_homeComponent;
      const inParams = null;

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'BarTender_configurations_form') {
      this.logger.log('BarTender', 'configurations_form');
      const title = 'BarTender configurations';
      const component = BarTender_configurations_formComponent;
      const inParams = null;

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'BarTender_azure_blob_storage_navigation_grid') {
      this.logger.log('BarTender', 'azure_blob_storage_navigation_grid');
      const title = 'Azure blob storage';
      const component = BarTender_azure_blob_storage_navigation_gridComponent;
      const inParams = null;

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'BarTender_bartender_hub') {
      this.logger.log('BarTender', 'bartender_hub');
      const title = 'BarTender hub';
      const component = BarTender_bartender_hubComponent;
      const inParams = null;

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'BarTender_view_file') {
      this.logger.log('BarTender', 'view_file');
      const title = 'View file';
      const component = BarTender_view_fileComponent;
      const inParams:{ file_content: string } = { file_content: null };
      if (!isNil(params.get('file_content'))) {
        const paramValueString = params.get('file_content');
        // TODO: date
        inParams.file_content = paramValueString;
              }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'BarTender_printers_dd_single') {
      const title = 'printers_dd';
      const component = BarTender_printers_dd_singleComponent;
      const inParams = null;

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'BarTender_reflect_strings_dd_single') {
      this.logger.log('BarTender', 'reflect_strings_dd_single');
      const title = 'reflect_strings_dd';
      const component = BarTender_reflect_strings_dd_singleComponent;
      const inParams:{ values: string } = { values: null };
      if (!isNil(params.get('values'))) {
        const paramValueString = params.get('values');
        // TODO: date
        inParams.values = paramValueString;
              }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'BarTender_printers_dd_multi') {
      const title = 'printers_dd';
      const component = BarTender_printers_dd_multiComponent;
      const inParams = null;

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'BarTender_reflect_strings_dd_multi') {
      this.logger.log('BarTender', 'reflect_strings_dd_multi');
      const title = 'reflect_strings_dd';
      const component = BarTender_reflect_strings_dd_multiComponent;
      const inParams:{ values: string } = { values: null };
      if (!isNil(params.get('values'))) {
        const paramValueString = params.get('values');
        // TODO: date
        inParams.values = paramValueString;
              }

      return { title: title, component: component, inParams: inParams };
    }


    let result = null;
    result = this.Utilities.getComponentInformation(referenceName, params);
    if (result) {
      return result;
    }
    return result;
  }
}
