import { Inject, Injectable } from '@angular/core';
import { isNil } from 'lodash-es';
import { environment } from '../environments/environment';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';

@Injectable({ providedIn: 'root' })
export class Reports_custom_master_bol_report_headerService {

  constructor(
    private utils: UtilsService,
    private settings: SettingsValuesService,
    ) { 
  }

  public async get(inParams: { load_container_id: number }): 
  Promise<{ result: { Id?: number, LookupCode?: string, TrailerNumber?: string, Shipments?: { Id?: number, LoadContainerSequence?: number, SealId?: string, OrderLookups?: { OrderId?: number, Order?: { Id?: number, LookupCode?: string, OwnerReference?: string, VendorReference?: string } }[], ExpectedWarehouse?: { Name?: string, Address?: { City?: string, Line1?: string, Line2?: string, PostalCode?: string, State?: string }, WarehousesContactsLookup?: { ContactId?: number, Contact?: { FirstName?: string, LastName?: string, Address?: { City?: string, Line1?: string, Line2?: string, PostalCode?: string, State?: string } } }[] }, ActualWarehouse?: { Name?: string, Address?: { City?: string, Line1?: string, Line2?: string, PostalCode?: string, State?: string }, WarehousesContactsLookup?: { ContactId?: number, Contact?: { FirstName?: string, LastName?: string, Address?: { City?: string, Line1?: string, Line2?: string, PostalCode?: string, State?: string } } }[] } }[], Carrier?: { Name?: string }, warehouse_name?: string, warehouse_line1?: string, warehouse_city?: string, load_container_lookup?: string, carrier?: string, trailer_number?: string, warehouse_state?: string, warehouse_postal_code?: string }[] }> 
  {
    const missingRequiredInParams = [];
    if (isNil(inParams.load_container_id)) {
      missingRequiredInParams.push('\'load_container_id\'');
    }
    if (missingRequiredInParams.length) {
    }
    let url = `${environment.backendUrl}api/Reports/datasources/custom_master_bol_report_header/get`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.utils.http.post(url, body, options);
    
  }
  public async getList(inParams: { load_container_id: number, $top?: number, $skip?: number }): 
  Promise<{ result: { Id?: number, LookupCode?: string, TrailerNumber?: string, Shipments?: { Id?: number, LoadContainerSequence?: number, SealId?: string, OrderLookups?: { OrderId?: number, Order?: { Id?: number, LookupCode?: string, OwnerReference?: string, VendorReference?: string } }[], ExpectedWarehouse?: { Name?: string, Address?: { City?: string, Line1?: string, Line2?: string, PostalCode?: string, State?: string }, WarehousesContactsLookup?: { ContactId?: number, Contact?: { FirstName?: string, LastName?: string, Address?: { City?: string, Line1?: string, Line2?: string, PostalCode?: string, State?: string } } }[] }, ActualWarehouse?: { Name?: string, Address?: { City?: string, Line1?: string, Line2?: string, PostalCode?: string, State?: string }, WarehousesContactsLookup?: { ContactId?: number, Contact?: { FirstName?: string, LastName?: string, Address?: { City?: string, Line1?: string, Line2?: string, PostalCode?: string, State?: string } } }[] } }[], Carrier?: { Name?: string }, warehouse_name?: string, warehouse_line1?: string, warehouse_city?: string, load_container_lookup?: string, carrier?: string, trailer_number?: string, warehouse_state?: string, warehouse_postal_code?: string }[], totalCount: number }> 
  {
    const missingRequiredInParams = [];
    if (isNil(inParams.load_container_id)) {
      missingRequiredInParams.push('\'load_container_id\'');
    }
    if (missingRequiredInParams.length) {
    }
    let url = `${environment.backendUrl}api/Reports/datasources/custom_master_bol_report_header/getList`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.utils.http.post(url, body, options);
    
  }
  public async getByKeys(inParams: { load_container_id: number, $keys: number[] }): 
  Promise<{ result: { Id?: number, LookupCode?: string, TrailerNumber?: string, Shipments?: { Id?: number, LoadContainerSequence?: number, SealId?: string, OrderLookups?: { OrderId?: number, Order?: { Id?: number, LookupCode?: string, OwnerReference?: string, VendorReference?: string } }[], ExpectedWarehouse?: { Name?: string, Address?: { City?: string, Line1?: string, Line2?: string, PostalCode?: string, State?: string }, WarehousesContactsLookup?: { ContactId?: number, Contact?: { FirstName?: string, LastName?: string, Address?: { City?: string, Line1?: string, Line2?: string, PostalCode?: string, State?: string } } }[] }, ActualWarehouse?: { Name?: string, Address?: { City?: string, Line1?: string, Line2?: string, PostalCode?: string, State?: string }, WarehousesContactsLookup?: { ContactId?: number, Contact?: { FirstName?: string, LastName?: string, Address?: { City?: string, Line1?: string, Line2?: string, PostalCode?: string, State?: string } } }[] } }[], Carrier?: { Name?: string }, warehouse_name?: string, warehouse_line1?: string, warehouse_city?: string, load_container_lookup?: string, carrier?: string, trailer_number?: string, warehouse_state?: string, warehouse_postal_code?: string }[] }> 
  {
    const missingRequiredInParams = [];
    if (isNil(inParams.load_container_id)) {
      missingRequiredInParams.push('\'load_container_id\'');
    }
    if (isNil(inParams.$keys)) {
      missingRequiredInParams.push('\'$keys\'');
    }
    if (missingRequiredInParams.length) {
    }
    let url = `${environment.backendUrl}api/Reports/datasources/custom_master_bol_report_header/getByKeys`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.utils.http.post(url, body, options);
    
  }


    
    hasMissingRequiredInParams(inParams: { 
      load_container_id: number    }) {
        if(isNil(inParams.load_container_id)) {
          return true; 
        }
      return false;
    }
}
