import { Inject, Injectable, Injector } from '@angular/core';
import { environment } from '../environments/environment';

import { UtilsService } from './utils.service';

import { BarcodeParsing_OperationService } from './BarcodeParsing.operation.service';
import { Utilities_OperationService } from './Utilities.operation.service';
import { Addresses_OperationService } from './Addresses.operation.service';
import { Attachments_OperationService } from './Attachments.operation.service';
import { Carriers_OperationService } from './Carriers.operation.service';
import { EntityImport_OperationService } from './EntityImport.operation.service';
import { Equipment_OperationService } from './Equipment.operation.service';
import { ExcelMaterialImport_OperationService } from './ExcelMaterialImport.operation.service';
import { ExcelProjectImport_OperationService } from './ExcelProjectImport.operation.service';
import { Inspections_OperationService } from './Inspections.operation.service';
import { Instructions_OperationService } from './Instructions.operation.service';
import { InventoryTransfers_OperationService } from './InventoryTransfers.operation.service';
import { LaborManagement_OperationService } from './LaborManagement.operation.service';
import { LoadContainers_OperationService } from './LoadContainers.operation.service';
import { PackVerification_OperationService } from './PackVerification.operation.service';
import { PalletTransactions_OperationService } from './PalletTransactions.operation.service';
import { Replenishments_OperationService } from './Replenishments.operation.service';
import { Returns_OperationService } from './Returns.operation.service';
import { Surveys_OperationService } from './Surveys.operation.service';
import { TemperatureReadings_OperationService } from './TemperatureReadings.operation.service';
import { TransloadOrders_OperationService } from './TransloadOrders.operation.service';
import { WorkOrders_OperationService } from './WorkOrders.operation.service';
import { Lots_OperationService } from './Lots.operation.service';
import { ExcelLocationImport_OperationService } from './ExcelLocationImport.operation.service';
import { SerialNumbers_OperationService } from './SerialNumbers.operation.service';
import { InventoryCounts_OperationService } from './InventoryCounts.operation.service';
import { AsnOrders_OperationService } from './AsnOrders.operation.service';
import { Owners_OperationService } from './Owners.operation.service';
import { Discussions_OperationService } from './Discussions.operation.service';
import { WarehouseTransfers_OperationService } from './WarehouseTransfers.operation.service';
import { ExcelOrderImport_OperationService } from './ExcelOrderImport.operation.service';
import { Document360_OperationService } from './Document360.operation.service';
import { Usersnap_OperationService } from './Usersnap.operation.service';
import { BarTender_OperationService } from './BarTender.operation.service';
import { ShippingContainers_OperationService } from './ShippingContainers.operation.service';
import { Inventory_OperationService } from './Inventory.operation.service';
import { Waves_OperationService } from './Waves.operation.service';
import { Invoices_OperationService } from './Invoices.operation.service';
import { Locations_OperationService } from './Locations.operation.service';
import { Catalogs_OperationService } from './Catalogs.operation.service';
import { ExcelInventoryImport_OperationService } from './ExcelInventoryImport.operation.service';
import { DockAppointments_OperationService } from './DockAppointments.operation.service';
import { PrintNode_OperationService } from './PrintNode.operation.service';
import { Materials_OperationService } from './Materials.operation.service';
import { Manifesting_OperationService } from './Manifesting.operation.service';
import { Cartonization_OperationService } from './Cartonization.operation.service';
import { PurchaseOrders_OperationService } from './PurchaseOrders.operation.service';
import { Notifications_OperationService } from './Notifications.operation.service';
import { SalesOrders_OperationService } from './SalesOrders.operation.service';


@Injectable({ providedIn: 'root' })
export class FootPrintManager_OperationService {

  constructor(
    private injector: Injector,
    private utils: UtilsService,
  ) {
    this.BarcodeParsing = this.injector.get(BarcodeParsing_OperationService);
    this.Utilities = this.injector.get(Utilities_OperationService);
    this.Addresses = this.injector.get(Addresses_OperationService);
    this.Attachments = this.injector.get(Attachments_OperationService);
    this.Carriers = this.injector.get(Carriers_OperationService);
    this.EntityImport = this.injector.get(EntityImport_OperationService);
    this.Equipment = this.injector.get(Equipment_OperationService);
    this.ExcelMaterialImport = this.injector.get(ExcelMaterialImport_OperationService);
    this.ExcelProjectImport = this.injector.get(ExcelProjectImport_OperationService);
    this.Inspections = this.injector.get(Inspections_OperationService);
    this.Instructions = this.injector.get(Instructions_OperationService);
    this.InventoryTransfers = this.injector.get(InventoryTransfers_OperationService);
    this.LaborManagement = this.injector.get(LaborManagement_OperationService);
    this.LoadContainers = this.injector.get(LoadContainers_OperationService);
    this.PackVerification = this.injector.get(PackVerification_OperationService);
    this.PalletTransactions = this.injector.get(PalletTransactions_OperationService);
    this.Replenishments = this.injector.get(Replenishments_OperationService);
    this.Returns = this.injector.get(Returns_OperationService);
    this.Surveys = this.injector.get(Surveys_OperationService);
    this.TemperatureReadings = this.injector.get(TemperatureReadings_OperationService);
    this.TransloadOrders = this.injector.get(TransloadOrders_OperationService);
    this.WorkOrders = this.injector.get(WorkOrders_OperationService);
    this.Lots = this.injector.get(Lots_OperationService);
    this.ExcelLocationImport = this.injector.get(ExcelLocationImport_OperationService);
    this.SerialNumbers = this.injector.get(SerialNumbers_OperationService);
    this.InventoryCounts = this.injector.get(InventoryCounts_OperationService);
    this.AsnOrders = this.injector.get(AsnOrders_OperationService);
    this.Owners = this.injector.get(Owners_OperationService);
    this.Discussions = this.injector.get(Discussions_OperationService);
    this.WarehouseTransfers = this.injector.get(WarehouseTransfers_OperationService);
    this.ExcelOrderImport = this.injector.get(ExcelOrderImport_OperationService);
    this.Document360 = this.injector.get(Document360_OperationService);
    this.Usersnap = this.injector.get(Usersnap_OperationService);
    this.BarTender = this.injector.get(BarTender_OperationService);
    this.ShippingContainers = this.injector.get(ShippingContainers_OperationService);
    this.Inventory = this.injector.get(Inventory_OperationService);
    this.Waves = this.injector.get(Waves_OperationService);
    this.Invoices = this.injector.get(Invoices_OperationService);
    this.Locations = this.injector.get(Locations_OperationService);
    this.Catalogs = this.injector.get(Catalogs_OperationService);
    this.ExcelInventoryImport = this.injector.get(ExcelInventoryImport_OperationService);
    this.DockAppointments = this.injector.get(DockAppointments_OperationService);
    this.PrintNode = this.injector.get(PrintNode_OperationService);
    this.Materials = this.injector.get(Materials_OperationService);
    this.Manifesting = this.injector.get(Manifesting_OperationService);
    this.Cartonization = this.injector.get(Cartonization_OperationService);
    this.PurchaseOrders = this.injector.get(PurchaseOrders_OperationService);
    this.Notifications = this.injector.get(Notifications_OperationService);
    this.SalesOrders = this.injector.get(SalesOrders_OperationService);
  }

    public BarcodeParsing: BarcodeParsing_OperationService;
    public Utilities: Utilities_OperationService;
    public Addresses: Addresses_OperationService;
    public Attachments: Attachments_OperationService;
    public Carriers: Carriers_OperationService;
    public EntityImport: EntityImport_OperationService;
    public Equipment: Equipment_OperationService;
    public ExcelMaterialImport: ExcelMaterialImport_OperationService;
    public ExcelProjectImport: ExcelProjectImport_OperationService;
    public Inspections: Inspections_OperationService;
    public Instructions: Instructions_OperationService;
    public InventoryTransfers: InventoryTransfers_OperationService;
    public LaborManagement: LaborManagement_OperationService;
    public LoadContainers: LoadContainers_OperationService;
    public PackVerification: PackVerification_OperationService;
    public PalletTransactions: PalletTransactions_OperationService;
    public Replenishments: Replenishments_OperationService;
    public Returns: Returns_OperationService;
    public Surveys: Surveys_OperationService;
    public TemperatureReadings: TemperatureReadings_OperationService;
    public TransloadOrders: TransloadOrders_OperationService;
    public WorkOrders: WorkOrders_OperationService;
    public Lots: Lots_OperationService;
    public ExcelLocationImport: ExcelLocationImport_OperationService;
    public SerialNumbers: SerialNumbers_OperationService;
    public InventoryCounts: InventoryCounts_OperationService;
    public AsnOrders: AsnOrders_OperationService;
    public Owners: Owners_OperationService;
    public Discussions: Discussions_OperationService;
    public WarehouseTransfers: WarehouseTransfers_OperationService;
    public ExcelOrderImport: ExcelOrderImport_OperationService;
    public Document360: Document360_OperationService;
    public Usersnap: Usersnap_OperationService;
    public BarTender: BarTender_OperationService;
    public ShippingContainers: ShippingContainers_OperationService;
    public Inventory: Inventory_OperationService;
    public Waves: Waves_OperationService;
    public Invoices: Invoices_OperationService;
    public Locations: Locations_OperationService;
    public Catalogs: Catalogs_OperationService;
    public ExcelInventoryImport: ExcelInventoryImport_OperationService;
    public DockAppointments: DockAppointments_OperationService;
    public PrintNode: PrintNode_OperationService;
    public Materials: Materials_OperationService;
    public Manifesting: Manifesting_OperationService;
    public Cartonization: Cartonization_OperationService;
    public PurchaseOrders: PurchaseOrders_OperationService;
    public Notifications: Notifications_OperationService;
    public SalesOrders: SalesOrders_OperationService;
  public FootPrintManager: FootPrintManager_OperationService = this;

  public Disable_Accessorials_ChargeEntry = {
    isAuthorized: (): Promise<boolean> => {
      return this.isAuthorized('Disable_Accessorials_ChargeEntry');
    },
    isAssigned: (): Promise<boolean> => {
      return this.isAuthorized('Disable_Accessorials_ChargeEntry');
    }
  }
  public Disable_AsnOrders_AutoReceiving = {
    isAuthorized: (): Promise<boolean> => {
      return this.isAuthorized('Disable_AsnOrders_AutoReceiving');
    },
    isAssigned: (): Promise<boolean> => {
      return this.isAuthorized('Disable_AsnOrders_AutoReceiving');
    }
  }
  public Disable_AsnOrders_LicenseplateEditing = {
    isAuthorized: (): Promise<boolean> => {
      return this.isAuthorized('Disable_AsnOrders_LicenseplateEditing');
    },
    isAssigned: (): Promise<boolean> => {
      return this.isAuthorized('Disable_AsnOrders_LicenseplateEditing');
    }
  }
  public Disable_AsnOrders_OrderCreation = {
    isAuthorized: (): Promise<boolean> => {
      return this.isAuthorized('Disable_AsnOrders_OrderCreation');
    },
    isAssigned: (): Promise<boolean> => {
      return this.isAuthorized('Disable_AsnOrders_OrderCreation');
    }
  }
  public Disable_AsnOrders_OrderEdits = {
    isAuthorized: (): Promise<boolean> => {
      return this.isAuthorized('Disable_AsnOrders_OrderEdits');
    },
    isAssigned: (): Promise<boolean> => {
      return this.isAuthorized('Disable_AsnOrders_OrderEdits');
    }
  }
  public Disable_Billing_BillingRecord_Cancellation = {
    isAuthorized: (): Promise<boolean> => {
      return this.isAuthorized('Disable_Billing_BillingRecord_Cancellation');
    },
    isAssigned: (): Promise<boolean> => {
      return this.isAuthorized('Disable_Billing_BillingRecord_Cancellation');
    }
  }
  public Disable_Billing_BillingRecord_Creation = {
    isAuthorized: (): Promise<boolean> => {
      return this.isAuthorized('Disable_Billing_BillingRecord_Creation');
    },
    isAssigned: (): Promise<boolean> => {
      return this.isAuthorized('Disable_Billing_BillingRecord_Creation');
    }
  }
  public Disable_Billing_BillingRecord_Deletion = {
    isAuthorized: (): Promise<boolean> => {
      return this.isAuthorized('Disable_Billing_BillingRecord_Deletion');
    },
    isAssigned: (): Promise<boolean> => {
      return this.isAuthorized('Disable_Billing_BillingRecord_Deletion');
    }
  }
  public Disable_Billing_BillingRecordEditing = {
    isAuthorized: (): Promise<boolean> => {
      return this.isAuthorized('Disable_Billing_BillingRecordEditing');
    },
    isAssigned: (): Promise<boolean> => {
      return this.isAuthorized('Disable_Billing_BillingRecordEditing');
    }
  }
  public Disable_Billing_Contract_Activation = {
    isAuthorized: (): Promise<boolean> => {
      return this.isAuthorized('Disable_Billing_Contract_Activation');
    },
    isAssigned: (): Promise<boolean> => {
      return this.isAuthorized('Disable_Billing_Contract_Activation');
    }
  }
  public Disable_Billing_Contract_Deactivation = {
    isAuthorized: (): Promise<boolean> => {
      return this.isAuthorized('Disable_Billing_Contract_Deactivation');
    },
    isAssigned: (): Promise<boolean> => {
      return this.isAuthorized('Disable_Billing_Contract_Deactivation');
    }
  }
  public Disable_Billing_Contract_Deletion = {
    isAuthorized: (): Promise<boolean> => {
      return this.isAuthorized('Disable_Billing_Contract_Deletion');
    },
    isAssigned: (): Promise<boolean> => {
      return this.isAuthorized('Disable_Billing_Contract_Deletion');
    }
  }
  public Disable_Billing_ContractCopying = {
    isAuthorized: (): Promise<boolean> => {
      return this.isAuthorized('Disable_Billing_ContractCopying');
    },
    isAssigned: (): Promise<boolean> => {
      return this.isAuthorized('Disable_Billing_ContractCopying');
    }
  }
  public Disable_Billing_ContractCreation = {
    isAuthorized: (): Promise<boolean> => {
      return this.isAuthorized('Disable_Billing_ContractCreation');
    },
    isAssigned: (): Promise<boolean> => {
      return this.isAuthorized('Disable_Billing_ContractCreation');
    }
  }
  public Disable_Billing_ContractEditing = {
    isAuthorized: (): Promise<boolean> => {
      return this.isAuthorized('Disable_Billing_ContractEditing');
    },
    isAssigned: (): Promise<boolean> => {
      return this.isAuthorized('Disable_Billing_ContractEditing');
    }
  }
  public Disable_Billing_InvoiceCreation = {
    isAuthorized: (): Promise<boolean> => {
      return this.isAuthorized('Disable_Billing_InvoiceCreation');
    },
    isAssigned: (): Promise<boolean> => {
      return this.isAuthorized('Disable_Billing_InvoiceCreation');
    }
  }
  public Disable_Billing_InvoiceEditing = {
    isAuthorized: (): Promise<boolean> => {
      return this.isAuthorized('Disable_Billing_InvoiceEditing');
    },
    isAssigned: (): Promise<boolean> => {
      return this.isAuthorized('Disable_Billing_InvoiceEditing');
    }
  }
  public Disable_Carriers_Management_View = {
    isAuthorized: (): Promise<boolean> => {
      return this.isAuthorized('Disable_Carriers_Management_View');
    },
    isAssigned: (): Promise<boolean> => {
      return this.isAuthorized('Disable_Carriers_Management_View');
    }
  }
  public Disable_Inbound_Order_Editing = {
    isAuthorized: (): Promise<boolean> => {
      return this.isAuthorized('Disable_Inbound_Order_Editing');
    },
    isAssigned: (): Promise<boolean> => {
      return this.isAuthorized('Disable_Inbound_Order_Editing');
    }
  }
  public Disable_InboundOrders_Cancellation = {
    isAuthorized: (): Promise<boolean> => {
      return this.isAuthorized('Disable_InboundOrders_Cancellation');
    },
    isAssigned: (): Promise<boolean> => {
      return this.isAuthorized('Disable_InboundOrders_Cancellation');
    }
  }
  public Disable_InboundOrders_Completion = {
    isAuthorized: (): Promise<boolean> => {
      return this.isAuthorized('Disable_InboundOrders_Completion');
    },
    isAssigned: (): Promise<boolean> => {
      return this.isAuthorized('Disable_InboundOrders_Completion');
    }
  }
  public Disable_InboundOrders_Deletion = {
    isAuthorized: (): Promise<boolean> => {
      return this.isAuthorized('Disable_InboundOrders_Deletion');
    },
    isAssigned: (): Promise<boolean> => {
      return this.isAuthorized('Disable_InboundOrders_Deletion');
    }
  }
  public Disable_InboundOrders_Processing = {
    isAuthorized: (): Promise<boolean> => {
      return this.isAuthorized('Disable_InboundOrders_Processing');
    },
    isAssigned: (): Promise<boolean> => {
      return this.isAuthorized('Disable_InboundOrders_Processing');
    }
  }
  public Disable_InboundOrders_Reverting = {
    isAuthorized: (): Promise<boolean> => {
      return this.isAuthorized('Disable_InboundOrders_Reverting');
    },
    isAssigned: (): Promise<boolean> => {
      return this.isAuthorized('Disable_InboundOrders_Reverting');
    }
  }
  public Disable_Inventory_Adjustment = {
    isAuthorized: (): Promise<boolean> => {
      return this.isAuthorized('Disable_Inventory_Adjustment');
    },
    isAssigned: (): Promise<boolean> => {
      return this.isAuthorized('Disable_Inventory_Adjustment');
    }
  }
  public Disable_Inventory_Creation = {
    isAuthorized: (): Promise<boolean> => {
      return this.isAuthorized('Disable_Inventory_Creation');
    },
    isAssigned: (): Promise<boolean> => {
      return this.isAuthorized('Disable_Inventory_Creation');
    }
  }
  public Disable_Inventory_Importing = {
    isAuthorized: (): Promise<boolean> => {
      return this.isAuthorized('Disable_Inventory_Importing');
    },
    isAssigned: (): Promise<boolean> => {
      return this.isAuthorized('Disable_Inventory_Importing');
    }
  }
  public Disable_Inventory_Movement = {
    isAuthorized: (): Promise<boolean> => {
      return this.isAuthorized('Disable_Inventory_Movement');
    },
    isAssigned: (): Promise<boolean> => {
      return this.isAuthorized('Disable_Inventory_Movement');
    }
  }
  public Disable_Inventory_MovementConfirmation = {
    isAuthorized: (): Promise<boolean> => {
      return this.isAuthorized('Disable_Inventory_MovementConfirmation');
    },
    isAssigned: (): Promise<boolean> => {
      return this.isAuthorized('Disable_Inventory_MovementConfirmation');
    }
  }
  public Disable_InventoryGrid_TransferInventory = {
    isAuthorized: (): Promise<boolean> => {
      return this.isAuthorized('Disable_InventoryGrid_TransferInventory');
    },
    isAssigned: (): Promise<boolean> => {
      return this.isAuthorized('Disable_InventoryGrid_TransferInventory');
    }
  }
  public Disable_InventoryTransfers_TransferCreation = {
    isAuthorized: (): Promise<boolean> => {
      return this.isAuthorized('Disable_InventoryTransfers_TransferCreation');
    },
    isAssigned: (): Promise<boolean> => {
      return this.isAuthorized('Disable_InventoryTransfers_TransferCreation');
    }
  }
  public Disable_LicensePlate_View = {
    isAuthorized: (): Promise<boolean> => {
      return this.isAuthorized('Disable_LicensePlate_View');
    },
    isAssigned: (): Promise<boolean> => {
      return this.isAuthorized('Disable_LicensePlate_View');
    }
  }
  public Disable_LoadContainer_Creation = {
    isAuthorized: (): Promise<boolean> => {
      return this.isAuthorized('Disable_LoadContainer_Creation');
    },
    isAssigned: (): Promise<boolean> => {
      return this.isAuthorized('Disable_LoadContainer_Creation');
    }
  }
  public Disable_Location_View = {
    isAuthorized: (): Promise<boolean> => {
      return this.isAuthorized('Disable_Location_View');
    },
    isAssigned: (): Promise<boolean> => {
      return this.isAuthorized('Disable_Location_View');
    }
  }
  public Disable_Lot_View = {
    isAuthorized: (): Promise<boolean> => {
      return this.isAuthorized('Disable_Lot_View');
    },
    isAssigned: (): Promise<boolean> => {
      return this.isAuthorized('Disable_Lot_View');
    }
  }
  public Disable_Material_Copying = {
    isAuthorized: (): Promise<boolean> => {
      return this.isAuthorized('Disable_Material_Copying');
    },
    isAssigned: (): Promise<boolean> => {
      return this.isAuthorized('Disable_Material_Copying');
    }
  }
  public Disable_Material_Creation = {
    isAuthorized: (): Promise<boolean> => {
      return this.isAuthorized('Disable_Material_Creation');
    },
    isAssigned: (): Promise<boolean> => {
      return this.isAuthorized('Disable_Material_Creation');
    }
  }
  public Disable_Material_Importing = {
    isAuthorized: (): Promise<boolean> => {
      return this.isAuthorized('Disable_Material_Importing');
    },
    isAssigned: (): Promise<boolean> => {
      return this.isAuthorized('Disable_Material_Importing');
    }
  }
  public Disable_Material_View = {
    isAuthorized: (): Promise<boolean> => {
      return this.isAuthorized('Disable_Material_View');
    },
    isAssigned: (): Promise<boolean> => {
      return this.isAuthorized('Disable_Material_View');
    }
  }
  public Disable_Navigation_Billing = {
    isAuthorized: (): Promise<boolean> => {
      return this.isAuthorized('Disable_Navigation_Billing');
    },
    isAssigned: (): Promise<boolean> => {
      return this.isAuthorized('Disable_Navigation_Billing');
    }
  }
  public Disable_Navigation_Billing_Auto_Invoicing = {
    isAuthorized: (): Promise<boolean> => {
      return this.isAuthorized('Disable_Navigation_Billing_Auto_Invoicing');
    },
    isAssigned: (): Promise<boolean> => {
      return this.isAuthorized('Disable_Navigation_Billing_Auto_Invoicing');
    }
  }
  public Disable_Navigation_Billing_Billing_Contracts = {
    isAuthorized: (): Promise<boolean> => {
      return this.isAuthorized('Disable_Navigation_Billing_Billing_Contracts');
    },
    isAssigned: (): Promise<boolean> => {
      return this.isAuthorized('Disable_Navigation_Billing_Billing_Contracts');
    }
  }
  public Disable_Navigation_Billing_Billing_Records = {
    isAuthorized: (): Promise<boolean> => {
      return this.isAuthorized('Disable_Navigation_Billing_Billing_Records');
    },
    isAssigned: (): Promise<boolean> => {
      return this.isAuthorized('Disable_Navigation_Billing_Billing_Records');
    }
  }
  public Disable_Navigation_Billing_Invoices = {
    isAuthorized: (): Promise<boolean> => {
      return this.isAuthorized('Disable_Navigation_Billing_Invoices');
    },
    isAssigned: (): Promise<boolean> => {
      return this.isAuthorized('Disable_Navigation_Billing_Invoices');
    }
  }
  public Disable_Navigation_Billing_Work_Orders = {
    isAuthorized: (): Promise<boolean> => {
      return this.isAuthorized('Disable_Navigation_Billing_Work_Orders');
    },
    isAssigned: (): Promise<boolean> => {
      return this.isAuthorized('Disable_Navigation_Billing_Work_Orders');
    }
  }
  public Disable_Navigation_Document360 = {
    isAuthorized: (): Promise<boolean> => {
      return this.isAuthorized('Disable_Navigation_Document360');
    },
    isAssigned: (): Promise<boolean> => {
      return this.isAuthorized('Disable_Navigation_Document360');
    }
  }
  public Disable_Navigation_Inbound = {
    isAuthorized: (): Promise<boolean> => {
      return this.isAuthorized('Disable_Navigation_Inbound');
    },
    isAssigned: (): Promise<boolean> => {
      return this.isAuthorized('Disable_Navigation_Inbound');
    }
  }
  public Disable_Navigation_Inbound_Orders_Hub = {
    isAuthorized: (): Promise<boolean> => {
      return this.isAuthorized('Disable_Navigation_Inbound_Orders_Hub');
    },
    isAssigned: (): Promise<boolean> => {
      return this.isAuthorized('Disable_Navigation_Inbound_Orders_Hub');
    }
  }
  public Disable_Navigation_Inbound_Returns = {
    isAuthorized: (): Promise<boolean> => {
      return this.isAuthorized('Disable_Navigation_Inbound_Returns');
    },
    isAssigned: (): Promise<boolean> => {
      return this.isAuthorized('Disable_Navigation_Inbound_Returns');
    }
  }
  public Disable_Navigation_Integrations = {
    isAuthorized: (): Promise<boolean> => {
      return this.isAuthorized('Disable_Navigation_Integrations');
    },
    isAssigned: (): Promise<boolean> => {
      return this.isAuthorized('Disable_Navigation_Integrations');
    }
  }
  public Disable_Navigation_Integrations_Entity_Import = {
    isAuthorized: (): Promise<boolean> => {
      return this.isAuthorized('Disable_Navigation_Integrations_Entity_Import');
    },
    isAssigned: (): Promise<boolean> => {
      return this.isAuthorized('Disable_Navigation_Integrations_Entity_Import');
    }
  }
  public Disable_Navigation_Integrations_Footprint_Api_Manager = {
    isAuthorized: (): Promise<boolean> => {
      return this.isAuthorized('Disable_Navigation_Integrations_Footprint_Api_Manager');
    },
    isAssigned: (): Promise<boolean> => {
      return this.isAuthorized('Disable_Navigation_Integrations_Footprint_Api_Manager');
    }
  }
  public Disable_Navigation_Inventory = {
    isAuthorized: (): Promise<boolean> => {
      return this.isAuthorized('Disable_Navigation_Inventory');
    },
    isAssigned: (): Promise<boolean> => {
      return this.isAuthorized('Disable_Navigation_Inventory');
    }
  }
  public Disable_Navigation_Inventory_Activity = {
    isAuthorized: (): Promise<boolean> => {
      return this.isAuthorized('Disable_Navigation_Inventory_Activity');
    },
    isAssigned: (): Promise<boolean> => {
      return this.isAuthorized('Disable_Navigation_Inventory_Activity');
    }
  }
  public Disable_Navigation_Inventory_Inventory_Count = {
    isAuthorized: (): Promise<boolean> => {
      return this.isAuthorized('Disable_Navigation_Inventory_Inventory_Count');
    },
    isAssigned: (): Promise<boolean> => {
      return this.isAuthorized('Disable_Navigation_Inventory_Inventory_Count');
    }
  }
  public Disable_Navigation_Inventory_Inventory_Hub = {
    isAuthorized: (): Promise<boolean> => {
      return this.isAuthorized('Disable_Navigation_Inventory_Inventory_Hub');
    },
    isAssigned: (): Promise<boolean> => {
      return this.isAuthorized('Disable_Navigation_Inventory_Inventory_Hub');
    }
  }
  public Disable_Navigation_Inventory_Inventory_Transfers = {
    isAuthorized: (): Promise<boolean> => {
      return this.isAuthorized('Disable_Navigation_Inventory_Inventory_Transfers');
    },
    isAssigned: (): Promise<boolean> => {
      return this.isAuthorized('Disable_Navigation_Inventory_Inventory_Transfers');
    }
  }
  public Disable_Navigation_Inventory_Lots = {
    isAuthorized: (): Promise<boolean> => {
      return this.isAuthorized('Disable_Navigation_Inventory_Lots');
    },
    isAssigned: (): Promise<boolean> => {
      return this.isAuthorized('Disable_Navigation_Inventory_Lots');
    }
  }
  public Disable_Navigation_Inventory_Replenishment = {
    isAuthorized: (): Promise<boolean> => {
      return this.isAuthorized('Disable_Navigation_Inventory_Replenishment');
    },
    isAssigned: (): Promise<boolean> => {
      return this.isAuthorized('Disable_Navigation_Inventory_Replenishment');
    }
  }
  public Disable_Navigation_Inventory_Serial_Numbers = {
    isAuthorized: (): Promise<boolean> => {
      return this.isAuthorized('Disable_Navigation_Inventory_Serial_Numbers');
    },
    isAssigned: (): Promise<boolean> => {
      return this.isAuthorized('Disable_Navigation_Inventory_Serial_Numbers');
    }
  }
  public Disable_Navigation_Inventory_Warehouses = {
    isAuthorized: (): Promise<boolean> => {
      return this.isAuthorized('Disable_Navigation_Inventory_Warehouses');
    },
    isAssigned: (): Promise<boolean> => {
      return this.isAuthorized('Disable_Navigation_Inventory_Warehouses');
    }
  }
  public Disable_Navigation_Outbound = {
    isAuthorized: (): Promise<boolean> => {
      return this.isAuthorized('Disable_Navigation_Outbound');
    },
    isAssigned: (): Promise<boolean> => {
      return this.isAuthorized('Disable_Navigation_Outbound');
    }
  }
  public Disable_Navigation_Outbound_Orders_Hub = {
    isAuthorized: (): Promise<boolean> => {
      return this.isAuthorized('Disable_Navigation_Outbound_Orders_Hub');
    },
    isAssigned: (): Promise<boolean> => {
      return this.isAuthorized('Disable_Navigation_Outbound_Orders_Hub');
    }
  }
  public Disable_Navigation_Outbound_Outbound_Warehouse_Transfers = {
    isAuthorized: (): Promise<boolean> => {
      return this.isAuthorized('Disable_Navigation_Outbound_Outbound_Warehouse_Transfers');
    },
    isAssigned: (): Promise<boolean> => {
      return this.isAuthorized('Disable_Navigation_Outbound_Outbound_Warehouse_Transfers');
    }
  }
  public Disable_Navigation_Outbound_Pack_Verification = {
    isAuthorized: (): Promise<boolean> => {
      return this.isAuthorized('Disable_Navigation_Outbound_Pack_Verification');
    },
    isAssigned: (): Promise<boolean> => {
      return this.isAuthorized('Disable_Navigation_Outbound_Pack_Verification');
    }
  }
  public Disable_Navigation_Outbound_Waves = {
    isAuthorized: (): Promise<boolean> => {
      return this.isAuthorized('Disable_Navigation_Outbound_Waves');
    },
    isAssigned: (): Promise<boolean> => {
      return this.isAuthorized('Disable_Navigation_Outbound_Waves');
    }
  }
  public Disable_Navigation_Owners = {
    isAuthorized: (): Promise<boolean> => {
      return this.isAuthorized('Disable_Navigation_Owners');
    },
    isAssigned: (): Promise<boolean> => {
      return this.isAuthorized('Disable_Navigation_Owners');
    }
  }
  public Disable_Navigation_Owners_Materials = {
    isAuthorized: (): Promise<boolean> => {
      return this.isAuthorized('Disable_Navigation_Owners_Materials');
    },
    isAssigned: (): Promise<boolean> => {
      return this.isAuthorized('Disable_Navigation_Owners_Materials');
    }
  }
  public Disable_Navigation_Owners_Owners_Hub = {
    isAuthorized: (): Promise<boolean> => {
      return this.isAuthorized('Disable_Navigation_Owners_Owners_Hub');
    },
    isAssigned: (): Promise<boolean> => {
      return this.isAuthorized('Disable_Navigation_Owners_Owners_Hub');
    }
  }
  public Disable_Navigation_Owners_Projects = {
    isAuthorized: (): Promise<boolean> => {
      return this.isAuthorized('Disable_Navigation_Owners_Projects');
    },
    isAssigned: (): Promise<boolean> => {
      return this.isAuthorized('Disable_Navigation_Owners_Projects');
    }
  }
  public Disable_Navigation_Planning = {
    isAuthorized: (): Promise<boolean> => {
      return this.isAuthorized('Disable_Navigation_Planning');
    },
    isAssigned: (): Promise<boolean> => {
      return this.isAuthorized('Disable_Navigation_Planning');
    }
  }
  public Disable_Navigation_Planning_Inspections = {
    isAuthorized: (): Promise<boolean> => {
      return this.isAuthorized('Disable_Navigation_Planning_Inspections');
    },
    isAssigned: (): Promise<boolean> => {
      return this.isAuthorized('Disable_Navigation_Planning_Inspections');
    }
  }
  public Disable_Navigation_Planning_Labor_Management = {
    isAuthorized: (): Promise<boolean> => {
      return this.isAuthorized('Disable_Navigation_Planning_Labor_Management');
    },
    isAssigned: (): Promise<boolean> => {
      return this.isAuthorized('Disable_Navigation_Planning_Labor_Management');
    }
  }
  public Disable_Navigation_Settings = {
    isAuthorized: (): Promise<boolean> => {
      return this.isAuthorized('Disable_Navigation_Settings');
    },
    isAssigned: (): Promise<boolean> => {
      return this.isAuthorized('Disable_Navigation_Settings');
    }
  }
  public Disable_Navigation_Settings_Inventory_Configurations = {
    isAuthorized: (): Promise<boolean> => {
      return this.isAuthorized('Disable_Navigation_Settings_Inventory_Configurations');
    },
    isAssigned: (): Promise<boolean> => {
      return this.isAuthorized('Disable_Navigation_Settings_Inventory_Configurations');
    }
  }
  public Disable_Navigation_Settings_Storage_Categories = {
    isAuthorized: (): Promise<boolean> => {
      return this.isAuthorized('Disable_Navigation_Settings_Storage_Categories');
    },
    isAssigned: (): Promise<boolean> => {
      return this.isAuthorized('Disable_Navigation_Settings_Storage_Categories');
    }
  }
  public Disable_Navigation_Settings_Surveys = {
    isAuthorized: (): Promise<boolean> => {
      return this.isAuthorized('Disable_Navigation_Settings_Surveys');
    },
    isAssigned: (): Promise<boolean> => {
      return this.isAuthorized('Disable_Navigation_Settings_Surveys');
    }
  }
  public Disable_Navigation_Transportation = {
    isAuthorized: (): Promise<boolean> => {
      return this.isAuthorized('Disable_Navigation_Transportation');
    },
    isAssigned: (): Promise<boolean> => {
      return this.isAuthorized('Disable_Navigation_Transportation');
    }
  }
  public Disable_Navigation_Transportation_Dock_Appointments = {
    isAuthorized: (): Promise<boolean> => {
      return this.isAuthorized('Disable_Navigation_Transportation_Dock_Appointments');
    },
    isAssigned: (): Promise<boolean> => {
      return this.isAuthorized('Disable_Navigation_Transportation_Dock_Appointments');
    }
  }
  public Disable_Navigation_Transportation_Dock_Doors = {
    isAuthorized: (): Promise<boolean> => {
      return this.isAuthorized('Disable_Navigation_Transportation_Dock_Doors');
    },
    isAssigned: (): Promise<boolean> => {
      return this.isAuthorized('Disable_Navigation_Transportation_Dock_Doors');
    }
  }
  public Disable_Navigation_Transportation_Driver_Check_Out = {
    isAuthorized: (): Promise<boolean> => {
      return this.isAuthorized('Disable_Navigation_Transportation_Driver_Check_Out');
    },
    isAssigned: (): Promise<boolean> => {
      return this.isAuthorized('Disable_Navigation_Transportation_Driver_Check_Out');
    }
  }
  public Disable_Navigation_Transportation_Load_Containers = {
    isAuthorized: (): Promise<boolean> => {
      return this.isAuthorized('Disable_Navigation_Transportation_Load_Containers');
    },
    isAssigned: (): Promise<boolean> => {
      return this.isAuthorized('Disable_Navigation_Transportation_Load_Containers');
    }
  }
  public Disable_Navigation_Transportation_Shipping_Containers = {
    isAuthorized: (): Promise<boolean> => {
      return this.isAuthorized('Disable_Navigation_Transportation_Shipping_Containers');
    },
    isAssigned: (): Promise<boolean> => {
      return this.isAuthorized('Disable_Navigation_Transportation_Shipping_Containers');
    }
  }
  public Disable_Navigation_UserSnap = {
    isAuthorized: (): Promise<boolean> => {
      return this.isAuthorized('Disable_Navigation_UserSnap');
    },
    isAssigned: (): Promise<boolean> => {
      return this.isAuthorized('Disable_Navigation_UserSnap');
    }
  }
  public Disable_Outbound_Order_AutoLoading = {
    isAuthorized: (): Promise<boolean> => {
      return this.isAuthorized('Disable_Outbound_Order_AutoLoading');
    },
    isAssigned: (): Promise<boolean> => {
      return this.isAuthorized('Disable_Outbound_Order_AutoLoading');
    }
  }
  public Disable_Outbound_Order_AutoPicking = {
    isAuthorized: (): Promise<boolean> => {
      return this.isAuthorized('Disable_Outbound_Order_AutoPicking');
    },
    isAssigned: (): Promise<boolean> => {
      return this.isAuthorized('Disable_Outbound_Order_AutoPicking');
    }
  }
  public Disable_Outbound_Order_Cancellation = {
    isAuthorized: (): Promise<boolean> => {
      return this.isAuthorized('Disable_Outbound_Order_Cancellation');
    },
    isAssigned: (): Promise<boolean> => {
      return this.isAuthorized('Disable_Outbound_Order_Cancellation');
    }
  }
  public Disable_Outbound_Order_Completion = {
    isAuthorized: (): Promise<boolean> => {
      return this.isAuthorized('Disable_Outbound_Order_Completion');
    },
    isAssigned: (): Promise<boolean> => {
      return this.isAuthorized('Disable_Outbound_Order_Completion');
    }
  }
  public Disable_Outbound_Order_Deletion = {
    isAuthorized: (): Promise<boolean> => {
      return this.isAuthorized('Disable_Outbound_Order_Deletion');
    },
    isAssigned: (): Promise<boolean> => {
      return this.isAuthorized('Disable_Outbound_Order_Deletion');
    }
  }
  public Disable_Outbound_Order_Editing = {
    isAuthorized: (): Promise<boolean> => {
      return this.isAuthorized('Disable_Outbound_Order_Editing');
    },
    isAssigned: (): Promise<boolean> => {
      return this.isAuthorized('Disable_Outbound_Order_Editing');
    }
  }
  public Disable_Outbound_Order_Import = {
    isAuthorized: (): Promise<boolean> => {
      return this.isAuthorized('Disable_Outbound_Order_Import');
    },
    isAssigned: (): Promise<boolean> => {
      return this.isAuthorized('Disable_Outbound_Order_Import');
    }
  }
  public Disable_Outbound_Order_Manifesting = {
    isAuthorized: (): Promise<boolean> => {
      return this.isAuthorized('Disable_Outbound_Order_Manifesting');
    },
    isAssigned: (): Promise<boolean> => {
      return this.isAuthorized('Disable_Outbound_Order_Manifesting');
    }
  }
  public Disable_Outbound_Order_Processing = {
    isAuthorized: (): Promise<boolean> => {
      return this.isAuthorized('Disable_Outbound_Order_Processing');
    },
    isAssigned: (): Promise<boolean> => {
      return this.isAuthorized('Disable_Outbound_Order_Processing');
    }
  }
  public Disable_Owner_Creation = {
    isAuthorized: (): Promise<boolean> => {
      return this.isAuthorized('Disable_Owner_Creation');
    },
    isAssigned: (): Promise<boolean> => {
      return this.isAuthorized('Disable_Owner_Creation');
    }
  }
  public Disable_Owner_Deletion = {
    isAuthorized: (): Promise<boolean> => {
      return this.isAuthorized('Disable_Owner_Deletion');
    },
    isAssigned: (): Promise<boolean> => {
      return this.isAuthorized('Disable_Owner_Deletion');
    }
  }
  public Disable_Owner_Editing = {
    isAuthorized: (): Promise<boolean> => {
      return this.isAuthorized('Disable_Owner_Editing');
    },
    isAssigned: (): Promise<boolean> => {
      return this.isAuthorized('Disable_Owner_Editing');
    }
  }
  public Disable_Project_Creation = {
    isAuthorized: (): Promise<boolean> => {
      return this.isAuthorized('Disable_Project_Creation');
    },
    isAssigned: (): Promise<boolean> => {
      return this.isAuthorized('Disable_Project_Creation');
    }
  }
  public Disable_Project_Deletion = {
    isAuthorized: (): Promise<boolean> => {
      return this.isAuthorized('Disable_Project_Deletion');
    },
    isAssigned: (): Promise<boolean> => {
      return this.isAuthorized('Disable_Project_Deletion');
    }
  }
  public Disable_Project_Editing = {
    isAuthorized: (): Promise<boolean> => {
      return this.isAuthorized('Disable_Project_Editing');
    },
    isAssigned: (): Promise<boolean> => {
      return this.isAuthorized('Disable_Project_Editing');
    }
  }
  public Disable_Project_Importing = {
    isAuthorized: (): Promise<boolean> => {
      return this.isAuthorized('Disable_Project_Importing');
    },
    isAssigned: (): Promise<boolean> => {
      return this.isAuthorized('Disable_Project_Importing');
    }
  }
  public Disable_Project_View = {
    isAuthorized: (): Promise<boolean> => {
      return this.isAuthorized('Disable_Project_View');
    },
    isAssigned: (): Promise<boolean> => {
      return this.isAuthorized('Disable_Project_View');
    }
  }
  public Disable_Purchage_Order_Deletion = {
    isAuthorized: (): Promise<boolean> => {
      return this.isAuthorized('Disable_Purchage_Order_Deletion');
    },
    isAssigned: (): Promise<boolean> => {
      return this.isAuthorized('Disable_Purchage_Order_Deletion');
    }
  }
  public Disable_PurchaseOrder_Completion = {
    isAuthorized: (): Promise<boolean> => {
      return this.isAuthorized('Disable_PurchaseOrder_Completion');
    },
    isAssigned: (): Promise<boolean> => {
      return this.isAuthorized('Disable_PurchaseOrder_Completion');
    }
  }
  public Disable_PurchaseOrder_Creation = {
    isAuthorized: (): Promise<boolean> => {
      return this.isAuthorized('Disable_PurchaseOrder_Creation');
    },
    isAssigned: (): Promise<boolean> => {
      return this.isAuthorized('Disable_PurchaseOrder_Creation');
    }
  }
  public Disable_PurchaseOrder_Editing = {
    isAuthorized: (): Promise<boolean> => {
      return this.isAuthorized('Disable_PurchaseOrder_Editing');
    },
    isAssigned: (): Promise<boolean> => {
      return this.isAuthorized('Disable_PurchaseOrder_Editing');
    }
  }
  public Disable_Replenishment_Rules_View = {
    isAuthorized: (): Promise<boolean> => {
      return this.isAuthorized('Disable_Replenishment_Rules_View');
    },
    isAssigned: (): Promise<boolean> => {
      return this.isAuthorized('Disable_Replenishment_Rules_View');
    }
  }
  public Disable_ReturnOrders_OrderCreation = {
    isAuthorized: (): Promise<boolean> => {
      return this.isAuthorized('Disable_ReturnOrders_OrderCreation');
    },
    isAssigned: (): Promise<boolean> => {
      return this.isAuthorized('Disable_ReturnOrders_OrderCreation');
    }
  }
  public Disable_SalesOrder_Editing = {
    isAuthorized: (): Promise<boolean> => {
      return this.isAuthorized('Disable_SalesOrder_Editing');
    },
    isAssigned: (): Promise<boolean> => {
      return this.isAuthorized('Disable_SalesOrder_Editing');
    }
  }
  public Disable_SalesOrders_AutoLoading = {
    isAuthorized: (): Promise<boolean> => {
      return this.isAuthorized('Disable_SalesOrders_AutoLoading');
    },
    isAssigned: (): Promise<boolean> => {
      return this.isAuthorized('Disable_SalesOrders_AutoLoading');
    }
  }
  public Disable_SalesOrders_AutoPicking = {
    isAuthorized: (): Promise<boolean> => {
      return this.isAuthorized('Disable_SalesOrders_AutoPicking');
    },
    isAssigned: (): Promise<boolean> => {
      return this.isAuthorized('Disable_SalesOrders_AutoPicking');
    }
  }
  public Disable_SalesOrders_Cancellation = {
    isAuthorized: (): Promise<boolean> => {
      return this.isAuthorized('Disable_SalesOrders_Cancellation');
    },
    isAssigned: (): Promise<boolean> => {
      return this.isAuthorized('Disable_SalesOrders_Cancellation');
    }
  }
  public Disable_SalesOrders_Completion = {
    isAuthorized: (): Promise<boolean> => {
      return this.isAuthorized('Disable_SalesOrders_Completion');
    },
    isAssigned: (): Promise<boolean> => {
      return this.isAuthorized('Disable_SalesOrders_Completion');
    }
  }
  public Disable_SalesOrders_Copying = {
    isAuthorized: (): Promise<boolean> => {
      return this.isAuthorized('Disable_SalesOrders_Copying');
    },
    isAssigned: (): Promise<boolean> => {
      return this.isAuthorized('Disable_SalesOrders_Copying');
    }
  }
  public Disable_SalesOrders_Deletion = {
    isAuthorized: (): Promise<boolean> => {
      return this.isAuthorized('Disable_SalesOrders_Deletion');
    },
    isAssigned: (): Promise<boolean> => {
      return this.isAuthorized('Disable_SalesOrders_Deletion');
    }
  }
  public Disable_SalesOrders_OrderCreation = {
    isAuthorized: (): Promise<boolean> => {
      return this.isAuthorized('Disable_SalesOrders_OrderCreation');
    },
    isAssigned: (): Promise<boolean> => {
      return this.isAuthorized('Disable_SalesOrders_OrderCreation');
    }
  }
  public Disable_SalesOrders_Processing = {
    isAuthorized: (): Promise<boolean> => {
      return this.isAuthorized('Disable_SalesOrders_Processing');
    },
    isAssigned: (): Promise<boolean> => {
      return this.isAuthorized('Disable_SalesOrders_Processing');
    }
  }
  public Disable_SalesOrders_Reverting = {
    isAuthorized: (): Promise<boolean> => {
      return this.isAuthorized('Disable_SalesOrders_Reverting');
    },
    isAssigned: (): Promise<boolean> => {
      return this.isAuthorized('Disable_SalesOrders_Reverting');
    }
  }
  public Disable_SerialNumber_View = {
    isAuthorized: (): Promise<boolean> => {
      return this.isAuthorized('Disable_SerialNumber_View');
    },
    isAssigned: (): Promise<boolean> => {
      return this.isAuthorized('Disable_SerialNumber_View');
    }
  }
  public Disable_SerialNumbers_DistributeWeight = {
    isAuthorized: (): Promise<boolean> => {
      return this.isAuthorized('Disable_SerialNumbers_DistributeWeight');
    },
    isAssigned: (): Promise<boolean> => {
      return this.isAuthorized('Disable_SerialNumbers_DistributeWeight');
    }
  }
  public Disable_Temperatures_Entry = {
    isAuthorized: (): Promise<boolean> => {
      return this.isAuthorized('Disable_Temperatures_Entry');
    },
    isAssigned: (): Promise<boolean> => {
      return this.isAuthorized('Disable_Temperatures_Entry');
    }
  }
  public Disable_TransLoads_AutoLoading = {
    isAuthorized: (): Promise<boolean> => {
      return this.isAuthorized('Disable_TransLoads_AutoLoading');
    },
    isAssigned: (): Promise<boolean> => {
      return this.isAuthorized('Disable_TransLoads_AutoLoading');
    }
  }
  public Enable_Accessorials_ShowAllOperations = {
    isAuthorized: (): Promise<boolean> => {
      return this.isAuthorized('Enable_Accessorials_ShowAllOperations');
    },
    isAssigned: (): Promise<boolean> => {
      return this.isAuthorized('Enable_Accessorials_ShowAllOperations');
    }
  }
  public Enable_Inventory_AdjustmentsValidateAvailability = {
    isAuthorized: (): Promise<boolean> => {
      return this.isAuthorized('Enable_Inventory_AdjustmentsValidateAvailability');
    },
    isAssigned: (): Promise<boolean> => {
      return this.isAuthorized('Enable_Inventory_AdjustmentsValidateAvailability');
    }
  }
  public Enable_Locations_DefaultWarehouseByActivity = {
    isAuthorized: (): Promise<boolean> => {
      return this.isAuthorized('Enable_Locations_DefaultWarehouseByActivity');
    },
    isAssigned: (): Promise<boolean> => {
      return this.isAuthorized('Enable_Locations_DefaultWarehouseByActivity');
    }
  }
  public Enable_SalesOrders_DefaultOrderHubCreatedStatus = {
    isAuthorized: (): Promise<boolean> => {
      return this.isAuthorized('Enable_SalesOrders_DefaultOrderHubCreatedStatus');
    },
    isAssigned: (): Promise<boolean> => {
      return this.isAuthorized('Enable_SalesOrders_DefaultOrderHubCreatedStatus');
    }
  }
  public Enable_SalesOrders_SelectChildSerialNumbers = {
    isAuthorized: (): Promise<boolean> => {
      return this.isAuthorized('Enable_SalesOrders_SelectChildSerialNumbers');
    },
    isAssigned: (): Promise<boolean> => {
      return this.isAuthorized('Enable_SalesOrders_SelectChildSerialNumbers');
    }
  }

  private isAuthorized(operationName: string): Promise<boolean> {
    return this.utils.http.post(`${environment.backendUrl}api/FootPrintManager/operations/${operationName}/isauthorized`, null);
  }
}
