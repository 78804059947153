import { Inject, Injectable, Injector } from '@angular/core';

import { Utilities_FlowService } from './Utilities.flow.index';

import { Materials_copy_material_flowService } from './Materials.flow.index';
import { Materials_copy_material_udf_flowService } from './Materials.flow.index';
import { Materials_create_material_flowService } from './Materials.flow.index';
import { Materials_create_materials_flowService } from './Materials.flow.index';
import { Materials_delete_material_flowService } from './Materials.flow.index';
import { Materials_delete_project_materials_flowsService } from './Materials.flow.index';
import { Materials_get_material_default_packagingService } from './Materials.flow.index';
import { Materials_get_material_packaging_decimal_precision_formattedService } from './Materials.flow.index';
import { Materials_is_material_deletable_flowService } from './Materials.flow.index';

import { $frontendTypes } from './Materials.frontend.types'

@Injectable({ providedIn: 'root' })
export class Materials_FlowService {

  constructor(
    private injector: Injector
  ) {
    this.Utilities = this.injector.get(Utilities_FlowService);
  }

    public Utilities: Utilities_FlowService;
  public Materials: Materials_FlowService = this;

  // injecting lazily in order to avoid circular dependencies
 
  private _copy_material_flow: Materials_copy_material_flowService;
  public async copy_material_flow(inParams: { materialId?: number, targetProjectId?: number }): Promise< { nextMaterialId?: number, reason?: string }> {
    if(!this._copy_material_flow) { 
      this._copy_material_flow = this.injector.get(Materials_copy_material_flowService);
    }
    return this._copy_material_flow.run(inParams);
  }
   
   

   
 
  private _copy_material_udf_flow: Materials_copy_material_udf_flowService;
  public async copy_material_udf_flow(inParams: { sourceMaterialId?: number, targetMaterialId?: number }): Promise< { reason?: string }> {
    if(!this._copy_material_udf_flow) { 
      this._copy_material_udf_flow = this.injector.get(Materials_copy_material_udf_flowService);
    }
    return this._copy_material_udf_flow.run(inParams);
  }
   
   

   
 
  private _create_material_flow: Materials_create_material_flowService;
  public async create_material_flow(inParams: { projectId: number, lookupcode: string, lotControlled: boolean, serialControlled: boolean, materialGroupId: number, description: string, name?: string, allocationStrategyId: number, isFixedWeight: boolean, isFixedLength: boolean, isFixedVolume: boolean, shelfLifeSpan: number, basePackagingId: number, netWeight?: number, shippingWeight?: number, weightUomId?: number, height?: number, length?: number, width?: number, dimensionUomId?: number, netVolume?: number, shippingVolume?: number, volumeUomId?: number, palletTie?: number, palletHigh?: number, upcCode?: string, quantityPrecisionScale?: number, storageCategoryRuleId?: number, allocationStrategyWorkflowId?: string }): Promise< { reasons?: string[], materialId?: number }> {
    if(!this._create_material_flow) { 
      this._create_material_flow = this.injector.get(Materials_create_material_flowService);
    }
    return this._create_material_flow.run(inParams);
  }
   
   

   
 
  private _create_materials_flow: Materials_create_materials_flowService;
  public async create_materials_flow(inParams: { materials: $frontendTypes.Materials.Material[] }): Promise< { results?: any, materials?: any[], errors?: string[] }> {
    if(!this._create_materials_flow) { 
      this._create_materials_flow = this.injector.get(Materials_create_materials_flowService);
    }
    return this._create_materials_flow.run(inParams);
  }
   
   

   
 
  private _delete_material_flow: Materials_delete_material_flowService;
  public async delete_material_flow(inParams: { materialId: number }): Promise< { reason?: string }> {
    if(!this._delete_material_flow) { 
      this._delete_material_flow = this.injector.get(Materials_delete_material_flowService);
    }
    return this._delete_material_flow.run(inParams);
  }
   
   

   
 
  private _delete_project_materials_flows: Materials_delete_project_materials_flowsService;
  public async delete_project_materials_flows(inParams: { project_id: number }): Promise<void> {
    if(!this._delete_project_materials_flows) { 
      this._delete_project_materials_flows = this.injector.get(Materials_delete_project_materials_flowsService);
    }
    return this._delete_project_materials_flows.run(inParams);
  }
   
   

   
 
  private _get_material_default_packaging: Materials_get_material_default_packagingService;
  public async get_material_default_packaging(inParams: { materialId: number }): Promise< { packagingId?: number }> {
    if(!this._get_material_default_packaging) { 
      this._get_material_default_packaging = this.injector.get(Materials_get_material_default_packagingService);
    }
    return this._get_material_default_packaging.run(inParams);
  }
   
   

   
 
  private _get_material_packaging_decimal_precision_formatted: Materials_get_material_packaging_decimal_precision_formattedService;
  public async get_material_packaging_decimal_precision_formatted(inParams: { materialId: number, packagingId: number }): Promise< { format?: string }> {
    if(!this._get_material_packaging_decimal_precision_formatted) { 
      this._get_material_packaging_decimal_precision_formatted = this.injector.get(Materials_get_material_packaging_decimal_precision_formattedService);
    }
    return this._get_material_packaging_decimal_precision_formatted.run(inParams);
  }
   
   

   
 
  private _is_material_deletable_flow: Materials_is_material_deletable_flowService;
  public async is_material_deletable_flow(inParams: { material_ids: number[] }): Promise< { reason?: string, have_lines?: number[], have_tasks?: number[], have_contents?: number[] }> {
    if(!this._is_material_deletable_flow) { 
      this._is_material_deletable_flow = this.injector.get(Materials_is_material_deletable_flowService);
    }
    return this._is_material_deletable_flow.run(inParams);
  }
   
   

   
}
