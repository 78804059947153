import { 
  Component, 
  OnInit,
  OnDestroy,
  OnChanges,
  Input,
  SimpleChanges,
  Output,
  EventEmitter,
  Inject,
  forwardRef
} from '@angular/core';

import { 
  FormGroup,
  Validators 
} from '@angular/forms';

import { isEqual, isNil } from 'lodash-es';
import { Subject } from 'rxjs';
import { takeUntil, mergeMap, shareReplay } from 'rxjs/operators';

import { DatexFormControl, validateControlOnChange, validateFormOnControlChange } from './models/datex-form-control';
import { 
  TextBoxModel, 
  NumberBoxModel, 
  SelectBoxModel, 
  ESelectBoxType,
  DateBoxModel, 
  CheckBoxModel, 
  TextModel, 
  LabelModel, 
  ButtonModel,
  SplitButtonModel,
  SeparatorModel,
  ImageModel,
  DrawModel,
  CodeBoxModel,
  ButtonStyles,
  ValueControlModel
} from './models/control';
import { Styles, ControlContainerStyles } from './models/style';
import { FieldModel } from './models/field';
import { FieldsetModel } from './models/fieldset';
import { ToolModel } from './models/tool';
import { BaseComponent } from './components/base.component';

import { SharedModule } from './shared.module';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';
import { Orderful_ShellService } from './Orderful.shell.service';
import { Orderful_OperationService } from './Orderful.operation.service';
import { Orderful_DatasourceService } from './Orderful.datasource.index';
import { Orderful_FlowService } from './Orderful.flow.index';
import { Orderful_ReportService } from './Orderful.report.index';
import { Orderful_LocalizationService } from './Orderful.localization.service';
import { Language } from './localization.service';
import { JobStatus } from './common-interfaces'
import { CleanupLoggerService } from './cleanup.logging.service';
import { $frontendTypes} from './Orderful.frontend.types'
import { $frontendTypes as $types} from './Orderful.frontend.types' 

import { EModalSize, EToasterType, EToasterPosition } from 'wavelength-ui';


@Component({
  standalone: true,
  imports: [
    SharedModule,
  ],
  selector: 'Orderful-message_xml',
  templateUrl: './Orderful.message_xml.component.html'
})
export class Orderful_message_xmlComponent extends BaseComponent implements OnInit, OnDestroy, OnChanges {

  inParams: { message?: string } = { message: null };
  //#region Inputs
  @Input('message') set $inParams_message(v: string) {
    this.inParams.message = v;
  }
  get $inParams_message(): string {
    return this.inParams.message;
  }
  //#endregion Inputs

  //#region Outputs
  @Output()
  $finish = new EventEmitter();
  //#endregion

  //#region title
  // Make it async so that it won't cause expressionChangedAfterItHasBeenCheckedError
  // The title is often meant to be shown from the parent (shell breadcrumb for example)
  // and often it will cause an expressionChangedAfterItHasBeenCheckedError because 
  // the parent has already been checked and the child now change something on the parent 
  // in dev, CD is run twice
  $titleChange = new EventEmitter<string>(true);
  private $_title: string;
  get title(): string {
    return this.$_title;
  }
  set title(t: string) {
    this.$_title = t;
    this.$titleChange.emit(this.$_title);
  }
  //#endregion title
  //#region Variables
  //#endregion
  //#region Events
  
  //#endregion

  formGroup: FormGroup = new FormGroup({
    message: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
  });

  get valid(): boolean {
    return this.formGroup.valid;
  }
  
  toolbar = {
  };

  fields = {
    message: new FieldModel( new CodeBoxModel(this.formGroup.controls['message'] as DatexFormControl, null, null,'xml', null)
, new ControlContainerStyles(null, null), '', false, false),
  };

  fieldsets = {
  message: new FieldsetModel('Message', true, false, true, false),
};

  //#region fields inParams
  //#endregion fields inParams

  $formGroupFieldValidationObservables = {
  }
  

  constructor(
    private utils: UtilsService,
private settings: SettingsValuesService,
private shell: Orderful_ShellService,
private datasources: Orderful_DatasourceService,
private flows: Orderful_FlowService,
private reports: Orderful_ReportService,
private localization: Orderful_LocalizationService,
private operations: Orderful_OperationService,
private logger: CleanupLoggerService,
) { 
    super();
    this.$subscribeFormControlValueChanges();
  }

  ngOnInit(): void {
    this.$init();
  }
  
  private $isFirstNgOnChanges = true;
  ngOnChanges(changes: SimpleChanges): void {
    if (this.$isFirstNgOnChanges) {
      this.$isFirstNgOnChanges = false;
    } else {
      this.$init();
    }
  }

  private $unsubscribe$ = new Subject();
  ngOnDestroy(): void {
    this.$unsubscribe$.next(null);
    this.$unsubscribe$.complete();
  }

  initialized = false;

  async $init() {
    this.title = 'message_xml';
  
    const $form = this;
    const $utils = this.utils;

    (this.fields.message.control as CodeBoxModel).value = $form.inParams.message;

    await this.on_init();

    this.initialized = true;
  }

  private $subscribeFormControlValueChanges() {
  }

  close() {
    this.$finish.emit();
  }

  openImageViewer(imageSource: string) {
    this.shell.openImageViewerDialog(imageSource);
  }
 
  //#region private flows
  on_init(event = null) {
    return this.on_initInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_initInternal(
    $form: Orderful_message_xmlComponent,
  
    $shell: Orderful_ShellService,
    $datasources: Orderful_DatasourceService,
    $flows: Orderful_FlowService,
    $reports: Orderful_ReportService,
    $settings: SettingsValuesService,
    $operations: Orderful_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: Orderful_LocalizationService,
    $event: any
  ) {
    this.logger.log('Orderful', 'message_xml.on_init');
  $form.title = `Orderful Message`;
  }
  //#endregion private flows
}
