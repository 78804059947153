import { 
  Component, 
  OnInit,
  OnChanges,
  OnDestroy,
  Input,
  SimpleChanges,
  Output,
  EventEmitter,
  ViewChild,
  Inject,
  forwardRef
} from '@angular/core';

import { 
  FormGroup,
  Validators 
} from '@angular/forms';

import { MatLegacySelectChange as MatSelectChange } from '@angular/material/legacy-select';
import { isEqual, isNil, isEmpty } from 'lodash-es';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { DatexFormControl } from './models/datex-form-control';
import { 
  TextBoxModel, 
  NumberBoxModel, 
  SelectBoxModel, 
  ESelectBoxType,
  DateBoxModel, 
  CheckBoxModel, 
  TextModel, 
  LabelModel, 
  ButtonModel,
  SplitButtonModel,
  ImageModel,
  DrawModel,
  CodeBoxModel,
  SeparatorModel,
  ButtonStyles 
} from './models/control';
import { Styles, ControlContainerStyles } from './models/style';
import { FieldModel } from './models/field';
import { ToolModel } from './models/tool';
import { TabItemModel, TabGroupModel } from './models/tab';
import { WidgetModel } from './models/widget';
import { FieldsetModel } from './models/fieldset';
import { BaseComponent } from './components/base.component';

import { SharedModule } from './shared.module';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';
import { FootPrintManager_ShellService } from './FootPrintManager.shell.service';
import { FootPrintManager_OperationService } from './FootPrintManager.operation.service';
import { FootPrintManager_DatasourceService } from './FootPrintManager.datasource.index';
import { FootPrintManager_FlowService } from './FootPrintManager.flow.index';
import { FootPrintManager_ReportService } from './FootPrintManager.report.index';
import { FootPrintManager_LocalizationService } from './FootPrintManager.localization.service';
import { Language } from './localization.service';
import { JobStatus } from './common-interfaces'
import { CleanupLoggerService } from './cleanup.logging.service';
import { $frontendTypes} from './FootPrintManager.frontend.types'
import { $frontendTypes as $types} from './FootPrintManager.frontend.types' 

import { EModalSize, EToasterType, EToasterPosition } from 'wavelength-ui';


import { FootPrintManager_inventory_location_gridComponent } from './FootPrintManager.inventory_location_grid.component';
import { FootPrintManager_inventory_licenseplate_gridComponent } from './FootPrintManager.inventory_licenseplate_grid.component';
import { FootPrintManager_inventory_lot_gridComponent } from './FootPrintManager.inventory_lot_grid.component';
import { FootPrintManager_inventory_material_gridComponent } from './FootPrintManager.inventory_material_grid.component';
import { FootPrintManager_licenseplate_listComponent } from './FootPrintManager.licenseplate_list.component';
import { FootPrintManager_inventory_tasks_gridComponent } from './FootPrintManager.inventory_tasks_grid.component';
import { FootPrintManager_material_packagings_gridComponent } from './FootPrintManager.material_packagings_grid.component';
import { FootPrintManager_material_kits_gridComponent } from './FootPrintManager.material_kits_grid.component';
import { FootPrintManager_materials_udf_gridComponent } from './FootPrintManager.materials_udf_grid.component';
import { FootPrintManager_serialnumbers_gridComponent } from './FootPrintManager.serialnumbers_grid.component';
import { FootPrintManager_locations_replenishments_gridComponent } from './FootPrintManager.locations_replenishments_grid.component';
import { FootPrintManager_instructions_gridComponent } from './FootPrintManager.instructions_grid.component';
import { Inventory_total_available_amount_widgetComponent } from './Inventory.total_available_amount_widget.component';
import { Inventory_total_available_base_amount_widgetComponent } from './Inventory.total_available_base_amount_widget.component';
import { Inventory_total_gross_weight_widgetComponent } from './Inventory.total_gross_weight_widget.component';
import { Inventory_inventory_aggregation_dd_singleComponent } from './Inventory.inventory_aggregation_dd_single.component'

@Component({
  standalone: true,
  imports: [
    SharedModule,
    forwardRef(() => FootPrintManager_inventory_location_gridComponent),
    forwardRef(() => FootPrintManager_inventory_licenseplate_gridComponent),
    forwardRef(() => FootPrintManager_inventory_lot_gridComponent),
    forwardRef(() => FootPrintManager_inventory_material_gridComponent),
    forwardRef(() => FootPrintManager_licenseplate_listComponent),
    forwardRef(() => FootPrintManager_inventory_tasks_gridComponent),
    forwardRef(() => FootPrintManager_material_packagings_gridComponent),
    forwardRef(() => FootPrintManager_material_kits_gridComponent),
    forwardRef(() => FootPrintManager_materials_udf_gridComponent),
    forwardRef(() => FootPrintManager_serialnumbers_gridComponent),
    forwardRef(() => FootPrintManager_locations_replenishments_gridComponent),
    forwardRef(() => FootPrintManager_instructions_gridComponent),
    forwardRef(() => Inventory_total_available_amount_widgetComponent),
    forwardRef(() => Inventory_total_available_base_amount_widgetComponent),
    forwardRef(() => Inventory_total_gross_weight_widgetComponent),
    forwardRef(() => Inventory_inventory_aggregation_dd_singleComponent),
  ],
  selector: 'FootPrintManager-single_material_hub',
  templateUrl: './FootPrintManager.single_material_hub.component.html'
})
export class FootPrintManager_single_material_hubComponent extends BaseComponent implements OnInit, OnDestroy, OnChanges {

  inParams: { materialId: number } = { materialId: null };
  //#region Inputs
  @Input('materialId') set $inParams_materialId(v: number) {
    this.inParams.materialId = v;
  }
  get $inParams_materialId(): number {
    return this.inParams.materialId;
  }
  //#endregion Inputs

  @Input() showInDialog: boolean = false; 
  //#region Outputs
  @Output()
  $finish = new EventEmitter();
  @Output()
  $refreshEvent = new EventEmitter();  
  //#endregion Outputs
  hasToolbar: boolean = true;



  //#region title
  // Make it async so that it won't cause expressionChangedAfterItHasBeenCheckedError
  // The title is often meant to be shown from the parent (shell breadcrumb for example)
  // and often it will cause an expressionChangedAfterItHasBeenCheckedError because 
  // the parent has already been checked and the child now change something on the parent 
  // in dev, CD is run twice
  $titleChange = new EventEmitter<string>(true);
  private $_title: string;
  get title(): string {
    return this.$_title;
  }
  set title(t: string) {
    this.$_title = t;
    this.$titleChange.emit(this.$_title);
  }
  //#endregion title
  //#region Variables
  vars: { hubTitle?: string, hubDescription?: string, project_id?: number } = { };
  //#endregion
  formGroup: FormGroup = new FormGroup({
    inventory_type: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
  });
  
  toolbar = {
      update: new ToolModel(new ButtonModel('update', new ButtonStyles(null, null), false, false, 'Edit', 'icon-ic_fluent_edit_20_regular', null)
    , false),
      separator1: new ToolModel(new SeparatorModel(new Styles(null, null))
    , false),
      create_inventory: new ToolModel(new ButtonModel('create_inventory', new ButtonStyles(null, null), false, false, 'Create inventory', 'icon-ic_fluent_add_20_regular', null)
    , false),
      separator2: new ToolModel(new SeparatorModel(new Styles(null, null))
    , false),
      on_delete: new ToolModel(new ButtonModel('on_delete', new ButtonStyles(['destructive'], null), false, false, 'Delete', 'icon-ic_fluent_delete_20_regular', null)
    , false),
      separator3: new ToolModel(new SeparatorModel(new Styles(null, null))
    , false),
      print: new ToolModel(new ButtonModel('print', new ButtonStyles(null, null), false, false, ' ', 'icon-ic_fluent_print_20_regular', null)
    , false),
      attachments: new ToolModel(new ButtonModel('attachments', new ButtonStyles(null, null), false, false, ' ', 'icon-ic_fluent_attach_20_regular', null)
    , false)
  };

  actionbar = {
      assign_containers_button: new ToolModel(new ButtonModel('assign_containers_button', new ButtonStyles(null, null), false, false, 'Assign containers', 'icon-ic_fluent_text_bullet_list_20_regular', null)
    , false),
      material_substitutions_button: new ToolModel(new ButtonModel('material_substitutions_button', new ButtonStyles(null, null), false, false, 'Material substitutions', 'icon-ic_fluent_flowchart_20_regular', null)
    , false),
      material_aliases: new ToolModel(new ButtonModel('material_aliases', new ButtonStyles(null, null), false, false, 'Material aliases', 'icon-ic_fluent_rename_20_regular', null)
    , false)
  };

 filters = {
    inventory_type: new FieldModel(new SelectBoxModel(
  this.formGroup.controls['inventory_type'] as DatexFormControl, 
  ESelectBoxType.chips, null,
  false, 
  '', null)
, new ControlContainerStyles(null, null), 'Inventory type', false, false),
  };


  filtersets = {
  newGroup1: new FieldsetModel('Label of newGroup1', true, false, true, false),
};

    rootTabGroup = new TabGroupModel();
  
    subTabGroups = {
      inventory: new TabGroupModel(),
    };
  
    onTabSelected(event: MatSelectChange) {
      event.value.activate();
    }
  
    tabs = {
      inventory: new TabItemModel(
        this.rootTabGroup, 
        'Inventory', 
        false,
        true,
        {
          inventory_by_location: new TabItemModel(
          this.subTabGroups.inventory, 
          'Inventory', 
          false
          ),
          inventory_by_lp: new TabItemModel(
          this.subTabGroups.inventory, 
          'Inventory', 
          false
          ),
          inventory_by_lot: new TabItemModel(
          this.subTabGroups.inventory, 
          'Inventory', 
          false
          ),
          inventory_by_material: new TabItemModel(
          this.subTabGroups.inventory, 
          'Inventory', 
          false
          ),
        }
        ),
      licenseplates: new TabItemModel(
        this.rootTabGroup, 
        'License plates', 
        false,
        ),
      tasks: new TabItemModel(
        this.rootTabGroup, 
        'Activity', 
        false,
        ),
      packagings: new TabItemModel(
        this.rootTabGroup, 
        'Packagings', 
        false,
        ),
      material_kits: new TabItemModel(
        this.rootTabGroup, 
        'Material Kits', 
        false,
        ),
      udfs: new TabItemModel(
        this.rootTabGroup, 
        'Custom fields', 
        false,
        ),
      serialnumbers: new TabItemModel(
        this.rootTabGroup, 
        'Serial numbers', 
        false,
        ),
      replenishments: new TabItemModel(
        this.rootTabGroup, 
        'Replenishments', 
        false,
        ),
      instructions: new TabItemModel(
        this.rootTabGroup, 
        'Instructions', 
        false,
        ),
    };
  
  
    //#region tabs inParams
    cacheValueFor_$tabs_inventory_inventory_by_location_inventory_location_grid_inParams_materialIds: number[];
    get $tabs_inventory_inventory_by_location_inventory_location_grid_inParams_materialIds(): number[] {
      const $hub = this;
      const $utils = this.utils;
      const expr = [$hub.inParams.materialId];
      
      if(!isEqual(this.cacheValueFor_$tabs_inventory_inventory_by_location_inventory_location_grid_inParams_materialIds, expr)) {
        this.cacheValueFor_$tabs_inventory_inventory_by_location_inventory_location_grid_inParams_materialIds = expr;
      }
      return this.cacheValueFor_$tabs_inventory_inventory_by_location_inventory_location_grid_inParams_materialIds;
    }
  
    cacheValueFor_$tabs_inventory_inventory_by_lp_inventory_licenseplate_grid_inParams_materialIds: number[];
    get $tabs_inventory_inventory_by_lp_inventory_licenseplate_grid_inParams_materialIds(): number[] {
      const $hub = this;
      const $utils = this.utils;
      const expr = [$hub.inParams.materialId];
      
      if(!isEqual(this.cacheValueFor_$tabs_inventory_inventory_by_lp_inventory_licenseplate_grid_inParams_materialIds, expr)) {
        this.cacheValueFor_$tabs_inventory_inventory_by_lp_inventory_licenseplate_grid_inParams_materialIds = expr;
      }
      return this.cacheValueFor_$tabs_inventory_inventory_by_lp_inventory_licenseplate_grid_inParams_materialIds;
    }
  
    cacheValueFor_$tabs_inventory_inventory_by_lot_inventory_lot_grid_inParams_materialIds: number[];
    get $tabs_inventory_inventory_by_lot_inventory_lot_grid_inParams_materialIds(): number[] {
      const $hub = this;
      const $utils = this.utils;
      const expr = [$hub.inParams.materialId];
      
      if(!isEqual(this.cacheValueFor_$tabs_inventory_inventory_by_lot_inventory_lot_grid_inParams_materialIds, expr)) {
        this.cacheValueFor_$tabs_inventory_inventory_by_lot_inventory_lot_grid_inParams_materialIds = expr;
      }
      return this.cacheValueFor_$tabs_inventory_inventory_by_lot_inventory_lot_grid_inParams_materialIds;
    }
  
    cacheValueFor_$tabs_inventory_inventory_by_material_inventory_material_grid_inParams_materialIds: number[];
    get $tabs_inventory_inventory_by_material_inventory_material_grid_inParams_materialIds(): number[] {
      const $hub = this;
      const $utils = this.utils;
      const expr = [$hub.inParams.materialId];
      
      if(!isEqual(this.cacheValueFor_$tabs_inventory_inventory_by_material_inventory_material_grid_inParams_materialIds, expr)) {
        this.cacheValueFor_$tabs_inventory_inventory_by_material_inventory_material_grid_inParams_materialIds = expr;
      }
      return this.cacheValueFor_$tabs_inventory_inventory_by_material_inventory_material_grid_inParams_materialIds;
    }
  
    get $tabs_licenseplates_licenseplate_list_inParams_materialId(): number {
      const $hub = this;
      const $utils = this.utils;
      const expr = $hub.inParams.materialId;
      
      return expr;
    }
  
    get $tabs_tasks_inventory_tasks_grid_inParams_materialId(): number {
      const $hub = this;
      const $utils = this.utils;
      const expr = $hub.inParams.materialId;
      
      return expr;
    }
  
    get $tabs_packagings_material_packagings_grid_inParams_materialId(): number {
      const $hub = this;
      const $utils = this.utils;
      const expr = $hub.inParams.materialId;
      
      return expr;
    }
  
    get $tabs_material_kits_material_kits_grid_inParams_material_id(): number {
      const $hub = this;
      const $utils = this.utils;
      const expr = $hub.inParams.materialId;
      
      return expr;
    }
  
    get $tabs_material_kits_material_kits_grid_inParams_project_id(): number {
      const $hub = this;
      const $utils = this.utils;
      const expr = $hub.vars.project_id;
      
      return expr;
    }
  
    cacheValueFor_$tabs_udfs_materials_udf_grid_inParams_materialIds: number[];
    get $tabs_udfs_materials_udf_grid_inParams_materialIds(): number[] {
      const $hub = this;
      const $utils = this.utils;
      const expr = [$hub.inParams.materialId];
      
      if(!isEqual(this.cacheValueFor_$tabs_udfs_materials_udf_grid_inParams_materialIds, expr)) {
        this.cacheValueFor_$tabs_udfs_materials_udf_grid_inParams_materialIds = expr;
      }
      return this.cacheValueFor_$tabs_udfs_materials_udf_grid_inParams_materialIds;
    }
  
    cacheValueFor_$tabs_serialnumbers_serialnumbers_grid_inParams_materialIds: number[];
    get $tabs_serialnumbers_serialnumbers_grid_inParams_materialIds(): number[] {
      const $hub = this;
      const $utils = this.utils;
      const expr = [$hub.inParams.materialId];
      
      if(!isEqual(this.cacheValueFor_$tabs_serialnumbers_serialnumbers_grid_inParams_materialIds, expr)) {
        this.cacheValueFor_$tabs_serialnumbers_serialnumbers_grid_inParams_materialIds = expr;
      }
      return this.cacheValueFor_$tabs_serialnumbers_serialnumbers_grid_inParams_materialIds;
    }
  
    get $tabs_serialnumbers_serialnumbers_grid_inParams_archived(): boolean {
      const $hub = this;
      const $utils = this.utils;
      const expr = false;
      
      return expr;
    }
  
    cacheValueFor_$tabs_replenishments_locations_replenishments_grid_inParams_materialId: number[];
    get $tabs_replenishments_locations_replenishments_grid_inParams_materialId(): number[] {
      const $hub = this;
      const $utils = this.utils;
      const expr = [$hub.inParams.materialId];
      
      if(!isEqual(this.cacheValueFor_$tabs_replenishments_locations_replenishments_grid_inParams_materialId, expr)) {
        this.cacheValueFor_$tabs_replenishments_locations_replenishments_grid_inParams_materialId = expr;
      }
      return this.cacheValueFor_$tabs_replenishments_locations_replenishments_grid_inParams_materialId;
    }
  
    get $tabs_instructions_instructions_grid_inParams_entityName(): string {
      const $hub = this;
      const $utils = this.utils;
      const expr = 'Material';
      
      return expr;
    }
  
    get $tabs_instructions_instructions_grid_inParams_enabled(): boolean {
      const $hub = this;
      const $utils = this.utils;
      const expr = true;
      
      return expr;
    }
  
    get $tabs_instructions_instructions_grid_inParams_entityKey1(): number {
      const $hub = this;
      const $utils = this.utils;
      const expr = $hub.inParams.materialId;
      
      return expr;
    }
  
    //#endregion tabs inParams
  
    //#region tabs children
        @ViewChild('$tabs_inventory_inventory_by_location', { read: FootPrintManager_inventory_location_gridComponent }) $tabs_inventory_inventory_by_location: FootPrintManager_inventory_location_gridComponent;
        @ViewChild('$tabs_inventory_inventory_by_lp', { read: FootPrintManager_inventory_licenseplate_gridComponent }) $tabs_inventory_inventory_by_lp: FootPrintManager_inventory_licenseplate_gridComponent;
        @ViewChild('$tabs_inventory_inventory_by_lot', { read: FootPrintManager_inventory_lot_gridComponent }) $tabs_inventory_inventory_by_lot: FootPrintManager_inventory_lot_gridComponent;
        @ViewChild('$tabs_inventory_inventory_by_material', { read: FootPrintManager_inventory_material_gridComponent }) $tabs_inventory_inventory_by_material: FootPrintManager_inventory_material_gridComponent;
      @ViewChild('$tabs_licenseplates', { read: FootPrintManager_licenseplate_listComponent }) $tabs_licenseplates: FootPrintManager_licenseplate_listComponent;
      @ViewChild('$tabs_tasks', { read: FootPrintManager_inventory_tasks_gridComponent }) $tabs_tasks: FootPrintManager_inventory_tasks_gridComponent;
      @ViewChild('$tabs_packagings', { read: FootPrintManager_material_packagings_gridComponent }) $tabs_packagings: FootPrintManager_material_packagings_gridComponent;
      @ViewChild('$tabs_material_kits', { read: FootPrintManager_material_kits_gridComponent }) $tabs_material_kits: FootPrintManager_material_kits_gridComponent;
      @ViewChild('$tabs_udfs', { read: FootPrintManager_materials_udf_gridComponent }) $tabs_udfs: FootPrintManager_materials_udf_gridComponent;
      @ViewChild('$tabs_serialnumbers', { read: FootPrintManager_serialnumbers_gridComponent }) $tabs_serialnumbers: FootPrintManager_serialnumbers_gridComponent;
      @ViewChild('$tabs_replenishments', { read: FootPrintManager_locations_replenishments_gridComponent }) $tabs_replenishments: FootPrintManager_locations_replenishments_gridComponent;
      @ViewChild('$tabs_instructions', { read: FootPrintManager_instructions_gridComponent }) $tabs_instructions: FootPrintManager_instructions_gridComponent;
    //#endregion tabs children
    widgets = {
      total_available_amount_widget: new WidgetModel(false),
      total_available_base_amount_widget: new WidgetModel(false),
      total_gross_weight_widget: new WidgetModel(false),
    };
  
    //#region widgets inParams
    get $widgets_total_available_amount_widget_inParams_materialId(): number {
      const $hub = this;
      const $utils = this.utils;
      const expr = $hub.inParams.materialId;
      
      return expr;
    }
  
    get $widgets_total_available_base_amount_widget_inParams_materialId(): number {
      const $hub = this;
      const $utils = this.utils;
      const expr = $hub.inParams.materialId;
      
      return expr;
    }
  
    get $widgets_total_gross_weight_widget_inParams_materialId(): number {
      const $hub = this;
      const $utils = this.utils;
      const expr = $hub.inParams.materialId;
      
      return expr;
    }
  
    //#endregion widgets inParams
  
    //#region widgets children
      @ViewChild('$widgets_total_available_amount_widget', { read:  Inventory_total_available_amount_widgetComponent }) $widgets_total_available_amount_widget: Inventory_total_available_amount_widgetComponent;
      @ViewChild('$widgets_total_available_base_amount_widget', { read:  Inventory_total_available_base_amount_widgetComponent }) $widgets_total_available_base_amount_widget: Inventory_total_available_base_amount_widgetComponent;
      @ViewChild('$widgets_total_gross_weight_widget', { read:  Inventory_total_gross_weight_widgetComponent }) $widgets_total_gross_weight_widget: Inventory_total_gross_weight_widgetComponent;
    //#endregion widgets children

  //#region filters inParams
  //#endregion filters inParams

  get hubTitle(): string {
    const $hub = this;
    const $utils = this.utils;
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //const $l10n = this.localization;
    return $hub.vars.hubTitle;
  }

  get hubDescription(): string {
    const $hub = this;
    const $utils = this.utils;
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //const $l10n = this.localization;
    return $hub.vars.hubDescription;
  }

  constructor(
  private utils: UtilsService,
  private settings: SettingsValuesService,
  private shell: FootPrintManager_ShellService,
  private datasources: FootPrintManager_DatasourceService,
  private flows: FootPrintManager_FlowService,
  private reports: FootPrintManager_ReportService,
  private localization: FootPrintManager_LocalizationService,
  private operations: FootPrintManager_OperationService,
  private logger: CleanupLoggerService,
  ) { 
    super();
    this.$subscribeFormControlValueChanges();
    this.hasToolbar = !isEmpty(this.toolbar);

    //#region tabs tab init
    this.rootTabGroup.tabs = [
      this.tabs.inventory,
      this.tabs.licenseplates,
      this.tabs.tasks,
      this.tabs.packagings,
      this.tabs.material_kits,
      this.tabs.udfs,
      this.tabs.serialnumbers,
      this.tabs.replenishments,
      this.tabs.instructions,
    ]; 
    this.subTabGroups.inventory.tabs = [
        this.tabs.inventory.tabs.inventory_by_location,
        this.tabs.inventory.tabs.inventory_by_lp,
        this.tabs.inventory.tabs.inventory_by_lot,
        this.tabs.inventory.tabs.inventory_by_material,
    ];    
    //#endregion tabs tab init
  }

  ngOnInit(): void {
    this.$checkRequiredInParams();
    if (!this.$hasMissingRequiredInParams) {
      this.$init();
    } else {
      this.$initEmpty();
    }
  }
  
  private $isFirstNgOnChanges = true;
  ngOnChanges(changes: SimpleChanges): void {
    if (this.$isFirstNgOnChanges) {
      this.$isFirstNgOnChanges = false;
    } else {
      this.$checkRequiredInParams();
      if(!this.$hasMissingRequiredInParams) {
        this.$init();
      } else {
        this.$initEmpty();
      }
    }
  }

  private $unsubscribe$ = new Subject();
  ngOnDestroy(): void {
    this.$unsubscribe$.next(null);
    this.$unsubscribe$.complete();
  }
  $missingRequiredInParams = [];
  get $hasMissingRequiredInParams(): boolean {
    return !!this.$missingRequiredInParams.length;
  }
  
  $checkRequiredInParams() {
    this.$missingRequiredInParams = [];
      if(isNil(this.inParams.materialId)) {
        this.$missingRequiredInParams.push('materialId');
      }
  }



  initialized = false;

  async $init() {
    this.title = 'Single Material Hub';
    
    const $hub = this;
    const $utils = this.utils;

    (this.filters.inventory_type.control as SelectBoxModel).reset(4);

    await this.on_init();

    this.initialized = true;
  }

  private $subscribeFormControlValueChanges() {
    this.formGroup
      .controls['inventory_type']
      .valueChanges
      .pipe(
        takeUntil(this.$unsubscribe$)
      )
      .subscribe(() => {
        this.on_inventory_type_change();
      });
  }
  close() {
    this.$finish.emit();
  }

  refresh(
    skipParent = false,
    skipChildren = false,
    childToSkip: string = null) {
    if (this.$hasMissingRequiredInParams) {
      return Promise.resolve(null);
    }
    // up
    if (skipParent === false) {
      this.$refreshEvent.emit();
    }

    // self
    const result = Promise.resolve(null);
    
    // children
    if (skipChildren === false) {
      this.$refreshChildren(childToSkip);
    }

    return result;
  }

  $refreshChildren(childToSkip: string) {
    //#region widgets children
    if (childToSkip !== '$widgets_total_available_amount_widget') {
      if (!isNil(this.$widgets_total_available_amount_widget) && !this.widgets.total_available_amount_widget.hidden) {
        this.$widgets_total_available_amount_widget.refresh(true, false, null);
      }
    }
    if (childToSkip !== '$widgets_total_available_base_amount_widget') {
      if (!isNil(this.$widgets_total_available_base_amount_widget) && !this.widgets.total_available_base_amount_widget.hidden) {
        this.$widgets_total_available_base_amount_widget.refresh(true, false, null);
      }
    }
    if (childToSkip !== '$widgets_total_gross_weight_widget') {
      if (!isNil(this.$widgets_total_gross_weight_widget) && !this.widgets.total_gross_weight_widget.hidden) {
        this.$widgets_total_gross_weight_widget.refresh(true, false, null);
      }
    }
    //#endregion widgets children
    //#region tabs children
      if (childToSkip !== '$tabs_inventory_inventory_by_location') {
        if (!isNil(this.$tabs_inventory_inventory_by_location) && !this.tabs.inventory.tabs.inventory_by_location.hidden) {
          this.$tabs_inventory_inventory_by_location.refresh(true, false, null);
        }
      }   
      if (childToSkip !== '$tabs_inventory_inventory_by_lp') {
        if (!isNil(this.$tabs_inventory_inventory_by_lp) && !this.tabs.inventory.tabs.inventory_by_lp.hidden) {
          this.$tabs_inventory_inventory_by_lp.refresh(true, false, null);
        }
      }   
      if (childToSkip !== '$tabs_inventory_inventory_by_lot') {
        if (!isNil(this.$tabs_inventory_inventory_by_lot) && !this.tabs.inventory.tabs.inventory_by_lot.hidden) {
          this.$tabs_inventory_inventory_by_lot.refresh(true, false, null);
        }
      }   
      if (childToSkip !== '$tabs_inventory_inventory_by_material') {
        if (!isNil(this.$tabs_inventory_inventory_by_material) && !this.tabs.inventory.tabs.inventory_by_material.hidden) {
          this.$tabs_inventory_inventory_by_material.refresh(true, false, null);
        }
      }   
    if (childToSkip !== '$tabs_licenseplates') {
      if (!isNil(this.$tabs_licenseplates) && !this.tabs.licenseplates.hidden) {
        this.$tabs_licenseplates.refresh(true, false, null);
      }
    }
    if (childToSkip !== '$tabs_tasks') {
      if (!isNil(this.$tabs_tasks) && !this.tabs.tasks.hidden) {
        this.$tabs_tasks.refresh(true, false, null);
      }
    }
    if (childToSkip !== '$tabs_packagings') {
      if (!isNil(this.$tabs_packagings) && !this.tabs.packagings.hidden) {
        this.$tabs_packagings.refresh(true, false, null);
      }
    }
    if (childToSkip !== '$tabs_material_kits') {
      if (!isNil(this.$tabs_material_kits) && !this.tabs.material_kits.hidden) {
        this.$tabs_material_kits.refresh(true, false, null);
      }
    }
    if (childToSkip !== '$tabs_udfs') {
      if (!isNil(this.$tabs_udfs) && !this.tabs.udfs.hidden) {
        this.$tabs_udfs.refresh(true, false, null);
      }
    }
    if (childToSkip !== '$tabs_serialnumbers') {
      if (!isNil(this.$tabs_serialnumbers) && !this.tabs.serialnumbers.hidden) {
        this.$tabs_serialnumbers.refresh(true, false, null);
      }
    }
    if (childToSkip !== '$tabs_replenishments') {
      if (!isNil(this.$tabs_replenishments) && !this.tabs.replenishments.hidden) {
        this.$tabs_replenishments.refresh(true, false, null);
      }
    }
    if (childToSkip !== '$tabs_instructions') {
      if (!isNil(this.$tabs_instructions) && !this.tabs.instructions.hidden) {
        this.$tabs_instructions.refresh(true, false, null);
      }
    }
    //#endregion tabs children
  }

  openImageViewer(imageSource: string) {
    this.shell.openImageViewerDialog(imageSource);
  }

  //#region private flows
  on_create_inventory_clicked(event = null) {
    return this.on_create_inventory_clickedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_create_inventory_clickedInternal(
    $hub: FootPrintManager_single_material_hubComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  
  const dialogResult = await $shell.FootPrintManager.openinventory_creation_formDialog({
      materialId: $hub.inParams.materialId
  });
  const userConfirmed = dialogResult.confirm;
  
  if (userConfirmed) {
      $hub.refresh()
  }
  
  
  }
  on_init(event = null) {
    return this.on_initInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_initInternal(
    $hub: FootPrintManager_single_material_hubComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  
  
  const material = (await $datasources.Materials.ds_get_material_by_materialId.get({ materialId: $hub.inParams.materialId })).result;
  if ($utils.isDefined(material)) {
  
      $hub.vars.project_id = material[0].ProjectId;
  
      $hub.title = `Material ${material[0].LookupCode}`;
  
      const title = `Material ${material[0].LookupCode} Status ${material[0].Status.Name} `;
  
      if ($utils.isDefined(material[0].Description)) {
          const description = `${material[0].Description}  `;
          $hub.vars.hubDescription = description;
      }
  
      $hub.vars.hubTitle = title;
  
  }
  
  // Hide serialnumbers tab if material isn't a serialized material. 
  if (!material[0].IsFixedVolume || !material[0].IsFixedWeight || material[0].ControllerTypeId === 3 || material[0].ControllerTypeId === 4) {
      $hub.tabs.serialnumbers.hidden = false;
  }
  else {
      $hub.tabs.serialnumbers.hidden = true;
  }
  
  // Initialize the inventory aggregation filter
  await $hub.on_inventory_type_change();
  
  
  
  // Lock down material editor if material is part of a catalog
  let versionCheck = await $flows.Utilities.check_footprint_version({ minimumVersion: '24.10.25' });
  
  if (versionCheck.meetsMinimumVersion) {
      // @ts-ignore 
      let catalog_material = (await $datasources.Catalogs.ds_get_material_catalog_details_by_material_id.get({ material_id: $hub.inParams.materialId })).result;
  
      if ($utils.isDefined(catalog_material) && $utils.isDefined(catalog_material[0]?.MaterialCatalogSourceMaterialId)) {
  
    
          $hub.toolbar.update.control.label = 'Catalog subscription'
          $hub.toolbar.update.control.icon = "ms-Icon ms-Icon--View"
  
          // Hide tabs and actions
          $hub.actionbar.assign_containers_button.hidden = true;
          $hub.actionbar.material_substitutions_button.hidden = true;
  
          $hub.tabs.material_kits.hidden = true;
          $hub.tabs.packagings.hidden = true;
          $hub.tabs.udfs.hidden = true;
  
          $hub.toolbar.on_delete.hidden = true;
  
  
      }
  }
  
  $hub.toolbar.create_inventory.hidden = (await $operations.FootPrintManager.Disable_Inventory_Creation.isAuthorized());
  
  }
  on_edit_clicked(event = null) {
    return this.on_edit_clickedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_edit_clickedInternal(
    $hub: FootPrintManager_single_material_hubComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  
  
  
  
  const dialogResult = (await $shell.FootPrintManager.openmaterial_editorDialog({materialId: $hub.inParams.materialId},'flyout'));
  const userConfirmed = dialogResult.confirm;
  
  if (userConfirmed) {
  
      await $hub.on_init();
      await $hub.refresh();
  }
  
  }
  on_delete(event = null) {
    return this.on_deleteInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_deleteInternal(
    $hub: FootPrintManager_single_material_hubComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  const result = await $flows.Materials.is_material_deletable_flow({ material_ids: [$hub.inParams.materialId] });
  const reason = result.reason;
  if ($utils.isDefined(reason)) {
      await $shell.FootPrintManager.openErrorDialog('Material cannot be deleted.', reason);
      return;
  } else {
      const confirm = await $shell.FootPrintManager.openConfirmationDialog('Delete Material', `Are you sure you want to delete the material?`, 'Yes', 'No');
      if (confirm) {
          try {
              const result = await $flows.Materials.delete_material_flow({ materialId: $hub.inParams.materialId });
              const reason = result.reason;
  
              if ($utils.isDefined(reason)) {
                  await $shell.FootPrintManager.openErrorDialog('Material cannot be deleted.', reason);
                  return;
              }
              else {
                  $hub.close();
              }
          } catch (error) {
              const errorMessage = $utils.isDefined(error?.error?.error) ? error?.error?.error.message : error;
              const errorDetail = $utils.isDefined(error?.error?.error) ? error?.error?.error : error;
              const errorDescription = `${errorMessage}`;
              await $shell.FootPrintManager.openErrorDialog('Material delete error', 'An error occurred during the deletion of the material', [errorDescription], null, [{ message: errorDescription, detail: errorDetail }]);
          }
      }
  }
  }
  on_print_clicked(event = null) {
    return this.on_print_clickedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_print_clickedInternal(
    $hub: FootPrintManager_single_material_hubComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  
  await $shell.FootPrintManager.openInfoDialog('Not yet implemented', 'The print functionality for this screen has not yet been implemented.');
  }
  on_attachments_clicked(event = null) {
    return this.on_attachments_clickedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_attachments_clickedInternal(
    $hub: FootPrintManager_single_material_hubComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  await $shell.FootPrintManager.openmaterial_attachments_hubDialog({ materialId: $hub.inParams.materialId });
  }
  on_assign_clicked(event = null) {
    return this.on_assign_clickedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_assign_clickedInternal(
    $hub: FootPrintManager_single_material_hubComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  $shell.FootPrintManager.opencontainer_type_assignment_gridDialog({materialId: $hub.inParams.materialId})
  }
  on_inventory_type_change(event = null) {
    return this.on_inventory_type_changeInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_inventory_type_changeInternal(
    $hub: FootPrintManager_single_material_hubComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  var inventoryType = $hub.filters.inventory_type.control.value;
  
  if (inventoryType == 1) {
      $hub.tabs.inventory.tabs.inventory_by_location.hidden = false;
      $hub.tabs.inventory.tabs.inventory_by_location.activate();
  
      $hub.tabs.inventory.tabs.inventory_by_lp.hidden = true;
      $hub.tabs.inventory.tabs.inventory_by_lot.hidden = true;
      $hub.tabs.inventory.tabs.inventory_by_material.hidden = true;
  }
  
  else if (inventoryType == 2) {
      $hub.tabs.inventory.tabs.inventory_by_lp.hidden = false;
      $hub.tabs.inventory.tabs.inventory_by_lp.activate();
  
      $hub.tabs.inventory.tabs.inventory_by_location.hidden = true;
      $hub.tabs.inventory.tabs.inventory_by_lot.hidden = true;
      $hub.tabs.inventory.tabs.inventory_by_material.hidden = true;
  
  
  }
  else if (inventoryType == 3) {
      $hub.tabs.inventory.tabs.inventory_by_lot.hidden = false;
      $hub.tabs.inventory.tabs.inventory_by_lot.activate();
  
      $hub.tabs.inventory.tabs.inventory_by_location.hidden = true;
      $hub.tabs.inventory.tabs.inventory_by_lp.hidden = true;
      $hub.tabs.inventory.tabs.inventory_by_material.hidden = true;
  
  }
  else if (inventoryType == 4) {
      $hub.tabs.inventory.tabs.inventory_by_material.hidden = false;
      $hub.tabs.inventory.tabs.inventory_by_material.activate();
  
      $hub.tabs.inventory.tabs.inventory_by_location.hidden = true;
      $hub.tabs.inventory.tabs.inventory_by_lp.hidden = true;
      $hub.tabs.inventory.tabs.inventory_by_lot.hidden = true;
  
  }
  }
  on_substitutions_clicked(event = null) {
    return this.on_substitutions_clickedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_substitutions_clickedInternal(
    $hub: FootPrintManager_single_material_hubComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  
  
  const material = (await $datasources.Materials.ds_get_material_by_materialId.get({ materialId: $hub.inParams.materialId })).result;
  const project_id = material[0].ProjectId;
  
  await $shell.FootPrintManager.openmaterial_substitutions_editorDialog({
      material_id: $hub.inParams.materialId,
      project_id: project_id
  }, "flyout", EModalSize.Large);
  
  
  
  
  }
  on_aliases_clicked(event = null) {
    return this.on_aliases_clickedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_aliases_clickedInternal(
    $hub: FootPrintManager_single_material_hubComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  
  
  const material = (await $datasources.Materials.ds_get_material_by_materialId.get({ materialId: $hub.inParams.materialId })).result;
  const project_id = material[0].ProjectId;
  var materialCode = ''
  
  if (material[0]?.LookupCode.trim().toUpperCase() === material[0]?.Name.trim().toUpperCase()) {
      materialCode = material[0]?.LookupCode
  } else {
      materialCode = `${material[0]?.LookupCode} - ${material[0]?.Name}`
  }
  
  
  await $shell.FootPrintManager.openmaterial_aliases_gridDialog({
      material_id: $hub.inParams.materialId,
      project_id: project_id, 
      materialCode: materialCode
  }, "modal", EModalSize.Large);
  
  
  
  
  }
  //#endregion private flows
}
