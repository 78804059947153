import { Inject, Injectable } from '@angular/core';
import { isNil } from 'lodash-es';
import { environment } from '../environments/environment';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';

@Injectable({ providedIn: 'root' })
export class Orderful_ds_find_base_packaging_by_lookupCodeService {

  constructor(
    private utils: UtilsService,
    private settings: SettingsValuesService,
    ) { 
  }

  public async get(inParams: { project_lookup: string[], material_lookup: string[] }): 
  Promise<{ result: { Id?: number, LookupCode?: string, PackagingLookups?: { MaterialId?: number, BasePackaging?: { Name?: string, ShortName?: string } }[], Project?: { LookupCode?: string } }[] }> 
  {
    const missingRequiredInParams = [];
    if (isNil(inParams.project_lookup)) {
      missingRequiredInParams.push('\'project_lookup\'');
    }
    if (isNil(inParams.material_lookup)) {
      missingRequiredInParams.push('\'material_lookup\'');
    }
    if (missingRequiredInParams.length) {
    }
    let url = `${environment.backendUrl}api/Orderful/datasources/ds_find_base_packaging_by_lookupCode/get`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.utils.http.post(url, body, options);
    
  }
  public async getList(inParams: { project_lookup: string[], material_lookup: string[], $top?: number, $skip?: number }): 
  Promise<{ result: { Id?: number, LookupCode?: string, PackagingLookups?: { MaterialId?: number, BasePackaging?: { Name?: string, ShortName?: string } }[], Project?: { LookupCode?: string } }[], totalCount: number }> 
  {
    const missingRequiredInParams = [];
    if (isNil(inParams.project_lookup)) {
      missingRequiredInParams.push('\'project_lookup\'');
    }
    if (isNil(inParams.material_lookup)) {
      missingRequiredInParams.push('\'material_lookup\'');
    }
    if (missingRequiredInParams.length) {
    }
    let url = `${environment.backendUrl}api/Orderful/datasources/ds_find_base_packaging_by_lookupCode/getList`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.utils.http.post(url, body, options);
    
  }
  public async getByKeys(inParams: { project_lookup: string[], material_lookup: string[], $keys: number[] }): 
  Promise<{ result: { Id?: number, LookupCode?: string, PackagingLookups?: { MaterialId?: number, BasePackaging?: { Name?: string, ShortName?: string } }[], Project?: { LookupCode?: string } }[] }> 
  {
    const missingRequiredInParams = [];
    if (isNil(inParams.project_lookup)) {
      missingRequiredInParams.push('\'project_lookup\'');
    }
    if (isNil(inParams.material_lookup)) {
      missingRequiredInParams.push('\'material_lookup\'');
    }
    if (isNil(inParams.$keys)) {
      missingRequiredInParams.push('\'$keys\'');
    }
    if (missingRequiredInParams.length) {
    }
    let url = `${environment.backendUrl}api/Orderful/datasources/ds_find_base_packaging_by_lookupCode/getByKeys`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.utils.http.post(url, body, options);
    
  }


    
    hasMissingRequiredInParams(inParams: { 
      project_lookup: string[], material_lookup: string[]    }) {
        if(isNil(inParams.project_lookup)) {
          return true; 
        }
        if(isNil(inParams.material_lookup)) {
          return true; 
        }
      return false;
    }
}
