import { Inject, Injectable } from '@angular/core';
import { isNil } from 'lodash-es';
import { environment } from '../environments/environment';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';

@Injectable({ providedIn: 'root' })
export class app_custom_ds_dock_appointments_dashboard_grid_order_dataService {

  constructor(
    private utils: UtilsService,
    private settings: SettingsValuesService,
    ) { 
  }

  public async get(inParams: { from_date: string, to_date: string, warehouse_ids?: number[] }): 
  Promise<{ result: { DockAppointmentId?: number, OrderId?: number, DockAppointment?: { CheckedInOn?: string, LookupCode?: string, ScheduledArrival?: string, Status?: { Name?: string }, AssignedLocation?: { Name?: string, Warehouse?: { Name?: string } }, ScheduledLocation?: { Name?: string, Warehouse?: { Name?: string } }, ScheduledProject?: { LookupCode?: string }, Type?: { Name?: string } }, Order?: { LookupCode?: string, OrderClass?: { Name?: string, OrderType?: { Name?: string } }, Project?: { LookupCode?: string }, ShipmentOrderLookups?: { ShipmentId?: number, Shipment?: { Id?: number } }[], OrderLines?: { ExpectedPackageAmount?: number, PackagedAmount?: number }[] }, last_receive_pick_user?: { Id?: number, ModifiedSysUser?: string, OperationCodeId?: number }, last_load_putaway_user?: { Id?: number, ModifiedSysUser?: string, OperationCodeId?: number } }[] }> 
  {
    const missingRequiredInParams = [];
    if (isNil(inParams.from_date)) {
      missingRequiredInParams.push('\'from_date\'');
    }
    if (isNil(inParams.to_date)) {
      missingRequiredInParams.push('\'to_date\'');
    }
    if (missingRequiredInParams.length) {
    }
    let url = `${environment.backendUrl}api/app/datasources/custom_ds_dock_appointments_dashboard_grid_order_data/get`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.utils.http.post(url, body, options);
    
  }
  public async getList(inParams: { from_date: string, to_date: string, warehouse_ids?: number[], $top?: number, $skip?: number }): 
  Promise<{ result: { DockAppointmentId?: number, OrderId?: number, DockAppointment?: { CheckedInOn?: string, LookupCode?: string, ScheduledArrival?: string, Status?: { Name?: string }, AssignedLocation?: { Name?: string, Warehouse?: { Name?: string } }, ScheduledLocation?: { Name?: string, Warehouse?: { Name?: string } }, ScheduledProject?: { LookupCode?: string }, Type?: { Name?: string } }, Order?: { LookupCode?: string, OrderClass?: { Name?: string, OrderType?: { Name?: string } }, Project?: { LookupCode?: string }, ShipmentOrderLookups?: { ShipmentId?: number, Shipment?: { Id?: number } }[], OrderLines?: { ExpectedPackageAmount?: number, PackagedAmount?: number }[] }, last_receive_pick_user?: { Id?: number, ModifiedSysUser?: string, OperationCodeId?: number }, last_load_putaway_user?: { Id?: number, ModifiedSysUser?: string, OperationCodeId?: number } }[], totalCount: number }> 
  {
    const missingRequiredInParams = [];
    if (isNil(inParams.from_date)) {
      missingRequiredInParams.push('\'from_date\'');
    }
    if (isNil(inParams.to_date)) {
      missingRequiredInParams.push('\'to_date\'');
    }
    if (missingRequiredInParams.length) {
    }
    let url = `${environment.backendUrl}api/app/datasources/custom_ds_dock_appointments_dashboard_grid_order_data/getList`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.utils.http.post(url, body, options);
    
  }
  public async getByKeys(inParams: { from_date: string, to_date: string, warehouse_ids?: number[], $keys: { DockAppointmentId?: number, OrderId?: number }[] }): 
  Promise<{ result: { DockAppointmentId?: number, OrderId?: number, DockAppointment?: { CheckedInOn?: string, LookupCode?: string, ScheduledArrival?: string, Status?: { Name?: string }, AssignedLocation?: { Name?: string, Warehouse?: { Name?: string } }, ScheduledLocation?: { Name?: string, Warehouse?: { Name?: string } }, ScheduledProject?: { LookupCode?: string }, Type?: { Name?: string } }, Order?: { LookupCode?: string, OrderClass?: { Name?: string, OrderType?: { Name?: string } }, Project?: { LookupCode?: string }, ShipmentOrderLookups?: { ShipmentId?: number, Shipment?: { Id?: number } }[], OrderLines?: { ExpectedPackageAmount?: number, PackagedAmount?: number }[] }, last_receive_pick_user?: { Id?: number, ModifiedSysUser?: string, OperationCodeId?: number }, last_load_putaway_user?: { Id?: number, ModifiedSysUser?: string, OperationCodeId?: number } }[] }> 
  {
    const missingRequiredInParams = [];
    if (isNil(inParams.from_date)) {
      missingRequiredInParams.push('\'from_date\'');
    }
    if (isNil(inParams.to_date)) {
      missingRequiredInParams.push('\'to_date\'');
    }
    if (isNil(inParams.$keys)) {
      missingRequiredInParams.push('\'$keys\'');
    }
    if (missingRequiredInParams.length) {
    }
    let url = `${environment.backendUrl}api/app/datasources/custom_ds_dock_appointments_dashboard_grid_order_data/getByKeys`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.utils.http.post(url, body, options);
    
  }


    
    hasMissingRequiredInParams(inParams: { 
      from_date: string, to_date: string, warehouse_ids?: number[]    }) {
        if(isNil(inParams.from_date)) {
          return true; 
        }
        if(isNil(inParams.to_date)) {
          return true; 
        }
      return false;
    }
}
