import { Inject, Injectable, Injector } from '@angular/core';

import { Utilities_ReportService } from './Utilities.report.index';
import { Addresses_ReportService } from './Addresses.report.index';
import { Attachments_ReportService } from './Attachments.report.index';
import { Carriers_ReportService } from './Carriers.report.index';
import { EntityImport_ReportService } from './EntityImport.report.index';
import { ExcelMaterialImport_ReportService } from './ExcelMaterialImport.report.index';
import { Instructions_ReportService } from './Instructions.report.index';
import { LoadContainers_ReportService } from './LoadContainers.report.index';
import { Surveys_ReportService } from './Surveys.report.index';
import { Lots_ReportService } from './Lots.report.index';
import { ExcelLocationImport_ReportService } from './ExcelLocationImport.report.index';
import { EndeavorLabs_ReportService } from './EndeavorLabs.report.index';
import { SerialNumbers_ReportService } from './SerialNumbers.report.index';
import { AsnOrders_ReportService } from './AsnOrders.report.index';
import { Owners_ReportService } from './Owners.report.index';
import { Discussions_ReportService } from './Discussions.report.index';
import { ExcelOrderImport_ReportService } from './ExcelOrderImport.report.index';
import { Document360_ReportService } from './Document360.report.index';
import { Usersnap_ReportService } from './Usersnap.report.index';
import { BarTender_ReportService } from './BarTender.report.index';
import { Inventory_ReportService } from './Inventory.report.index';
import { Waves_ReportService } from './Waves.report.index';
import { Invoices_ReportService } from './Invoices.report.index';
import { Locations_ReportService } from './Locations.report.index';
import { ExcelInventoryImport_ReportService } from './ExcelInventoryImport.report.index';
import { DockAppointments_ReportService } from './DockAppointments.report.index';
import { Reports_ReportService } from './Reports.report.index';
import { Materials_ReportService } from './Materials.report.index';
import { PurchaseOrders_ReportService } from './PurchaseOrders.report.index';
import { Notifications_ReportService } from './Notifications.report.index';
import { SalesOrders_ReportService } from './SalesOrders.report.index';
import { FootPrintApiManager_ReportService } from './FootPrintApiManager.report.index';
import { FootPrintManager_ReportService } from './FootPrintManager.report.index';
import { Orderful_ReportService } from './Orderful.report.index';

import { app_TestOrderReportService } from './app.report.index';
import { app_custom_bol_reportService } from './app.report.index';
import { app_custom_inventory_reportService } from './app.report.index';
import { app_custom_inventory_report_by_projectService } from './app.report.index';
import { app_custom_load_diagram_reportService } from './app.report.index';

@Injectable({ providedIn: 'root' })
export class app_ReportService {

  constructor(
    private injector: Injector
  ) {
    this.Utilities = this.injector.get(Utilities_ReportService);
    this.Addresses = this.injector.get(Addresses_ReportService);
    this.Attachments = this.injector.get(Attachments_ReportService);
    this.Carriers = this.injector.get(Carriers_ReportService);
    this.EntityImport = this.injector.get(EntityImport_ReportService);
    this.ExcelMaterialImport = this.injector.get(ExcelMaterialImport_ReportService);
    this.Instructions = this.injector.get(Instructions_ReportService);
    this.LoadContainers = this.injector.get(LoadContainers_ReportService);
    this.Surveys = this.injector.get(Surveys_ReportService);
    this.Lots = this.injector.get(Lots_ReportService);
    this.ExcelLocationImport = this.injector.get(ExcelLocationImport_ReportService);
    this.EndeavorLabs = this.injector.get(EndeavorLabs_ReportService);
    this.SerialNumbers = this.injector.get(SerialNumbers_ReportService);
    this.AsnOrders = this.injector.get(AsnOrders_ReportService);
    this.Owners = this.injector.get(Owners_ReportService);
    this.Discussions = this.injector.get(Discussions_ReportService);
    this.ExcelOrderImport = this.injector.get(ExcelOrderImport_ReportService);
    this.Document360 = this.injector.get(Document360_ReportService);
    this.Usersnap = this.injector.get(Usersnap_ReportService);
    this.BarTender = this.injector.get(BarTender_ReportService);
    this.Inventory = this.injector.get(Inventory_ReportService);
    this.Waves = this.injector.get(Waves_ReportService);
    this.Invoices = this.injector.get(Invoices_ReportService);
    this.Locations = this.injector.get(Locations_ReportService);
    this.ExcelInventoryImport = this.injector.get(ExcelInventoryImport_ReportService);
    this.DockAppointments = this.injector.get(DockAppointments_ReportService);
    this.Reports = this.injector.get(Reports_ReportService);
    this.Materials = this.injector.get(Materials_ReportService);
    this.PurchaseOrders = this.injector.get(PurchaseOrders_ReportService);
    this.Notifications = this.injector.get(Notifications_ReportService);
    this.SalesOrders = this.injector.get(SalesOrders_ReportService);
    this.FootPrintApiManager = this.injector.get(FootPrintApiManager_ReportService);
    this.FootPrintManager = this.injector.get(FootPrintManager_ReportService);
    this.Orderful = this.injector.get(Orderful_ReportService);
  }
    public Utilities: Utilities_ReportService;
    public Addresses: Addresses_ReportService;
    public Attachments: Attachments_ReportService;
    public Carriers: Carriers_ReportService;
    public EntityImport: EntityImport_ReportService;
    public ExcelMaterialImport: ExcelMaterialImport_ReportService;
    public Instructions: Instructions_ReportService;
    public LoadContainers: LoadContainers_ReportService;
    public Surveys: Surveys_ReportService;
    public Lots: Lots_ReportService;
    public ExcelLocationImport: ExcelLocationImport_ReportService;
    public EndeavorLabs: EndeavorLabs_ReportService;
    public SerialNumbers: SerialNumbers_ReportService;
    public AsnOrders: AsnOrders_ReportService;
    public Owners: Owners_ReportService;
    public Discussions: Discussions_ReportService;
    public ExcelOrderImport: ExcelOrderImport_ReportService;
    public Document360: Document360_ReportService;
    public Usersnap: Usersnap_ReportService;
    public BarTender: BarTender_ReportService;
    public Inventory: Inventory_ReportService;
    public Waves: Waves_ReportService;
    public Invoices: Invoices_ReportService;
    public Locations: Locations_ReportService;
    public ExcelInventoryImport: ExcelInventoryImport_ReportService;
    public DockAppointments: DockAppointments_ReportService;
    public Reports: Reports_ReportService;
    public Materials: Materials_ReportService;
    public PurchaseOrders: PurchaseOrders_ReportService;
    public Notifications: Notifications_ReportService;
    public SalesOrders: SalesOrders_ReportService;
    public FootPrintApiManager: FootPrintApiManager_ReportService;
    public FootPrintManager: FootPrintManager_ReportService;
    public Orderful: Orderful_ReportService;

  public app: app_ReportService = this;

  // injecting lazily in order to avoid circular dependencies
  private _TestOrderReport: app_TestOrderReportService;
  public get TestOrderReport(): app_TestOrderReportService {
    if(!this._TestOrderReport) {
      this._TestOrderReport = this.injector.get(app_TestOrderReportService);
    }
    return this._TestOrderReport;
  }
  private _custom_bol_report: app_custom_bol_reportService;
  public get custom_bol_report(): app_custom_bol_reportService {
    if(!this._custom_bol_report) {
      this._custom_bol_report = this.injector.get(app_custom_bol_reportService);
    }
    return this._custom_bol_report;
  }
  private _custom_inventory_report: app_custom_inventory_reportService;
  public get custom_inventory_report(): app_custom_inventory_reportService {
    if(!this._custom_inventory_report) {
      this._custom_inventory_report = this.injector.get(app_custom_inventory_reportService);
    }
    return this._custom_inventory_report;
  }
  private _custom_inventory_report_by_project: app_custom_inventory_report_by_projectService;
  public get custom_inventory_report_by_project(): app_custom_inventory_report_by_projectService {
    if(!this._custom_inventory_report_by_project) {
      this._custom_inventory_report_by_project = this.injector.get(app_custom_inventory_report_by_projectService);
    }
    return this._custom_inventory_report_by_project;
  }
  private _custom_load_diagram_report: app_custom_load_diagram_reportService;
  public get custom_load_diagram_report(): app_custom_load_diagram_reportService {
    if(!this._custom_load_diagram_report) {
      this._custom_load_diagram_report = this.injector.get(app_custom_load_diagram_reportService);
    }
    return this._custom_load_diagram_report;
  }
}

