<div class="blade-wrapper">
    <div class="blade-header" *ngIf="!showInDialog || (showInDialog && hasToolbar)">
      <div *ngIf="!$hasMissingRequiredInParams"
          class="blade-tools">
          <app-toolbar [toolbar]="toolbar">
              <ng-template 
                toolbarToolDef="edit" let-tool>
                <ng-container  *ngIf="tool.hasButtonsToShow">
                <button mat-button
                        data-cy="button"
                        class="datex-button"
                        [disabled]="tool.control.readOnly"
                        [ngStyle]="tool.control.styles.style"
                        [ngClass]="tool.control.styles.classes"
                        [matTooltip]="tool.control.tooltip"
                        matTooltipClass="datex-control-tooltip"
                        (click)="on_edit_clicked($event)"
                        >
                  <div class="button-label">
                    <ng-container *ngIf="tool.control.icon === 'datex-default-spinner'; else showIcon">
                      <div class="button-icon">
                        <i data-cy="button-icon" class="datex-default-spinner"></i>
                      </div>
                    </ng-container>
                    <ng-template #showIcon>
                      <div class="button-icon">
                        <i data-cy="button-icon" class="icon {{tool.control.icon}}"></i>
                      </div>
                    </ng-template>
                    <div data-cy="button-label" class="button-text">{{tool.control.label}}</div>
                  </div>
                
                </button>
                </ng-container>
              </ng-template>
              <ng-template 
                toolbarToolDef="separator1" let-tool>
                <ng-container  *ngIf="tool.hasButtonsToShow">
                <div class="tool-separator"></div>
                </ng-container>
              </ng-template>
              <ng-template 
                toolbarToolDef="create_inventory" let-tool>
                <ng-container  *ngIf="tool.hasButtonsToShow">
                <button mat-button
                        data-cy="button"
                        class="datex-button"
                        [disabled]="tool.control.readOnly"
                        [ngStyle]="tool.control.styles.style"
                        [ngClass]="tool.control.styles.classes"
                        [matTooltip]="tool.control.tooltip"
                        matTooltipClass="datex-control-tooltip"
                        (click)="on_create_inventory_clicked($event)"
                        >
                  <div class="button-label">
                    <ng-container *ngIf="tool.control.icon === 'datex-default-spinner'; else showIcon">
                      <div class="button-icon">
                        <i data-cy="button-icon" class="datex-default-spinner"></i>
                      </div>
                    </ng-container>
                    <ng-template #showIcon>
                      <div class="button-icon">
                        <i data-cy="button-icon" class="icon {{tool.control.icon}}"></i>
                      </div>
                    </ng-template>
                    <div data-cy="button-label" class="button-text">{{tool.control.label}}</div>
                  </div>
                
                </button>
                </ng-container>
              </ng-template>
              <ng-template 
                toolbarToolDef="location_project_assignments" let-tool>
                <ng-container  *ngIf="tool.hasButtonsToShow">
                <button mat-button
                        data-cy="button"
                        class="datex-button"
                        [disabled]="tool.control.readOnly"
                        [ngStyle]="tool.control.styles.style"
                        [ngClass]="tool.control.styles.classes"
                        [matTooltip]="tool.control.tooltip"
                        matTooltipClass="datex-control-tooltip"
                        (click)="on_project_assignments_clicked($event)"
                        >
                  <div class="button-label">
                    <ng-container *ngIf="tool.control.icon === 'datex-default-spinner'; else showIcon">
                      <div class="button-icon">
                        <i data-cy="button-icon" class="datex-default-spinner"></i>
                      </div>
                    </ng-container>
                    <ng-template #showIcon>
                      <div class="button-icon">
                        <i data-cy="button-icon" class="icon {{tool.control.icon}}"></i>
                      </div>
                    </ng-template>
                    <div data-cy="button-label" class="button-text">{{tool.control.label}}</div>
                  </div>
                
                </button>
                </ng-container>
              </ng-template>
              <ng-template 
                toolbarToolDef="separator2" let-tool>
                <ng-container  *ngIf="tool.hasButtonsToShow">
                <div class="tool-separator"></div>
                </ng-container>
              </ng-template>
              <ng-template 
                toolbarToolDef="surveys" let-tool>
                <ng-container  *ngIf="tool.hasButtonsToShow">
                <button mat-button
                        data-cy="button"
                        class="datex-button"
                        [disabled]="tool.control.readOnly"
                        [ngStyle]="tool.control.styles.style"
                        [ngClass]="tool.control.styles.classes"
                        [matTooltip]="tool.control.tooltip"
                        matTooltipClass="datex-control-tooltip"
                        (click)="on_surveys_clicked($event)"
                        >
                  <div class="button-label">
                    <ng-container *ngIf="tool.control.icon === 'datex-default-spinner'; else showIcon">
                      <div class="button-icon">
                        <i data-cy="button-icon" class="datex-default-spinner"></i>
                      </div>
                    </ng-container>
                    <ng-template #showIcon>
                      <div class="button-icon">
                        <i data-cy="button-icon" class="icon {{tool.control.icon}}"></i>
                      </div>
                    </ng-template>
                    <div data-cy="button-label" class="button-text">{{tool.control.label}}</div>
                  </div>
                
                </button>
                </ng-container>
              </ng-template>
          </app-toolbar>
      </div>
      <ng-content></ng-content>
    </div>

  <div class="blade-content">
    <div class="datex-hub">
      <ng-container
                    *ngIf="initialized && !$hasMissingRequiredInParams">
        <div class="hubdata">
          <h1 class="hubname" *ngIf="!showInDialog">{{hubTitle}}</h1>
          <h6 class="hubdesc">{{hubDescription}}</h6>
          <div class="hub-filters">
            <div class="fieldsetsGroup">
              <div [formGroup]="formGroup"
                   class="formdata">
                <div data-cy="fieldset-id-newGroup1"
                     *ngIf="!filtersets.newGroup1.hidden && !filtersets.newGroup1.removed"
                     class="fieldset-container">
                  <div class="fieldsetsContainer"
                       [ngClass]="{'collapsible': filtersets.newGroup1.collapsible }">
                    <div *ngIf="!filtersets.newGroup1.hideTitle"
                         data-cy="fieldset-title"
                         class="fieldsetsTitle"
                         (click)="filtersets.newGroup1.toggle()">
                      <span class="fieldsetsTitle-text">
                      {{filtersets.newGroup1.label}}
                      </span>
                      <i data-cy="fieldset-toggle"
                         *ngIf="filtersets.newGroup1.collapsible"
                         class="icon"
                         [ngClass]="{'icon-ic_fluent_chevron_down_20_filled': !filtersets.newGroup1.expanded, 'icon-ic_fluent_chevron_up_20_filled': filtersets.newGroup1.expanded }"></i>
                    </div>
                    <div data-cy="fieldset-group"
                         *ngIf="filtersets.newGroup1.expanded"
                         class="fieldsetsGroup">
                            <div data-cy="field-id-Loose_Flag" *ngIf="!filters.Loose_Flag.hidden && !filters.Loose_Flag.removed" 
                                  class="field-container standard {{filters.Loose_Flag.invalid ? 'invalid' : ''}}"
                                  [ngStyle]="filters.Loose_Flag.styles.style"
                                  [ngClass]="filters.Loose_Flag.styles.classes">
                              <div class="label-container"
                                   [matTooltip]="filters.Loose_Flag.label + (filters.Loose_Flag.required ? ' (required)' : '')"
                                   matTooltipClass="datex-control-tooltip">
                                <label data-cy="field-label" class="datex-label">{{filters.Loose_Flag.label}}<span *ngIf="filters.Loose_Flag.required" class="required-asterisk">*</span>
                                </label>
                              </div>
                              <mat-checkbox data-cy="checkBox" formControlName="Loose_Flag"
                                            class="datex-checkbox"
                                            color="primary"
                                            [ngStyle]="filters.Loose_Flag.control.styles.style"
                                            [ngClass]="filters.Loose_Flag.control.styles.classes"
                                            [matTooltip]="filters.Loose_Flag.control.tooltip"
                                            matTooltipClass="datex-control-tooltip">{{filters.Loose_Flag.control.label}}</mat-checkbox>
                              <ng-container *ngIf="filters.Loose_Flag.invalid">
                                <ng-container *ngFor="let error of filters.Loose_Flag.errors">
                                  <span class="invalid-message">
                                    {{error}}
                                  </span>
                                </ng-container>
                              </ng-container>
                            </div>              <div data-cy="field-id-Allocation_eligible" *ngIf="!filters.Allocation_eligible.hidden && !filters.Allocation_eligible.removed" 
                                  class="field-container standard {{filters.Allocation_eligible.invalid ? 'invalid' : ''}}"
                                  [ngStyle]="filters.Allocation_eligible.styles.style"
                                  [ngClass]="filters.Allocation_eligible.styles.classes">
                              <div class="label-container"
                                   [matTooltip]="filters.Allocation_eligible.label + (filters.Allocation_eligible.required ? ' (required)' : '')"
                                   matTooltipClass="datex-control-tooltip">
                                <label data-cy="field-label" class="datex-label">{{filters.Allocation_eligible.label}}<span *ngIf="filters.Allocation_eligible.required" class="required-asterisk">*</span>
                                </label>
                              </div>
                              <mat-checkbox data-cy="checkBox" formControlName="Allocation_eligible"
                                            class="datex-checkbox"
                                            color="primary"
                                            [ngStyle]="filters.Allocation_eligible.control.styles.style"
                                            [ngClass]="filters.Allocation_eligible.control.styles.classes"
                                            [matTooltip]="filters.Allocation_eligible.control.tooltip"
                                            matTooltipClass="datex-control-tooltip">{{filters.Allocation_eligible.control.label}}</mat-checkbox>
                              <ng-container *ngIf="filters.Allocation_eligible.invalid">
                                <ng-container *ngFor="let error of filters.Allocation_eligible.errors">
                                  <span class="invalid-message">
                                    {{error}}
                                  </span>
                                </ng-container>
                              </ng-container>
                            </div>            </div>
                        </div>
                      </div>
                    </div>
            </div>
          </div>
          <div class="hub-tools">
              <app-toolbar [toolbar]="actionbar">
                  <ng-template 
                    toolbarToolDef="custom_fields" let-tool>
                    <ng-container  *ngIf="tool.hasButtonsToShow">
                    <button mat-button
                            data-cy="button"
                            class="datex-button"
                            [disabled]="tool.control.readOnly"
                            [ngStyle]="tool.control.styles.style"
                            [ngClass]="tool.control.styles.classes"
                            [matTooltip]="tool.control.tooltip"
                            matTooltipClass="datex-control-tooltip"
                            (click)="on_custom_fields_clicked($event)"
                            >
                      <div class="button-label">
                        <ng-container *ngIf="tool.control.icon === 'datex-default-spinner'; else showIcon">
                          <div class="button-icon">
                            <i data-cy="button-icon" class="datex-default-spinner"></i>
                          </div>
                        </ng-container>
                        <ng-template #showIcon>
                          <div class="button-icon">
                            <i data-cy="button-icon" class="icon {{tool.control.icon}}"></i>
                          </div>
                        </ng-template>
                        <div data-cy="button-label" class="button-text">{{tool.control.label}}</div>
                      </div>
                    
                    </button>
                    </ng-container>
                  </ng-template>
              </app-toolbar>
          </div>
        </div>

        <div class="widgets">
          <div *ngIf="!widgets.total_available_amount_widget.hidden && !widgets.total_available_amount_widget.removed" class="widget-container">
            <Inventory-total_available_amount_location_widget
            #$widgets_total_available_amount_widget 
              [locationId]="$widgets_total_available_amount_widget_inParams_locationId"
            ($refreshEvent)="refresh(false, false, '$widgets_total_available_amount_widget')"
            >
            </Inventory-total_available_amount_location_widget>
          </div>
          <div *ngIf="!widgets.total_gross_weight_widget.hidden && !widgets.total_gross_weight_widget.removed" class="widget-container">
            <Inventory-total_gross_weight_widget
            #$widgets_total_gross_weight_widget 
              [locationId]="$widgets_total_gross_weight_widget_inParams_locationId"
            ($refreshEvent)="refresh(false, false, '$widgets_total_gross_weight_widget')"
            >
            </Inventory-total_gross_weight_widget>
          </div>
          <div *ngIf="!widgets.total_packaged_amount_widget.hidden && !widgets.total_packaged_amount_widget.removed" class="widget-container">
            <Inventory-total_packaged_amount_widget
            #$widgets_total_packaged_amount_widget 
              [locationId]="$widgets_total_packaged_amount_widget_inParams_locationId"
            ($refreshEvent)="refresh(false, false, '$widgets_total_packaged_amount_widget')"
            >
            </Inventory-total_packaged_amount_widget>
          </div>
        </div>

        <div class="datex-tabcontrol" *ngIf="!this.rootTabGroup.allTabsHidden">
          <div class="tab-container">
            <div *ngIf="tabs.inventory.hasTabToShow" class="tab" data-cy="tab-inventory">
              <h2 [className]="tabs.inventory.active? 'active': ''" (click)="tabs.inventory.activate()">{{tabs.inventory.title}}</h2>
            </div>
            <div *ngIf="tabs.licenseplates_cards.hasTabToShow" class="tab" data-cy="tab-licenseplates_cards">
              <h2 [className]="tabs.licenseplates_cards.active? 'active': ''" (click)="tabs.licenseplates_cards.activate()">{{tabs.licenseplates_cards.title}}</h2>
            </div>
            <div *ngIf="tabs.tasks.hasTabToShow" class="tab" data-cy="tab-tasks">
              <h2 [className]="tabs.tasks.active? 'active': ''" (click)="tabs.tasks.activate()">{{tabs.tasks.title}}</h2>
            </div>
            <div *ngIf="tabs.serialnumbers.hasTabToShow" class="tab" data-cy="tab-serialnumbers">
              <h2 [className]="tabs.serialnumbers.active? 'active': ''" (click)="tabs.serialnumbers.activate()">{{tabs.serialnumbers.title}}</h2>
            </div>
            <div *ngIf="tabs.replenishments.hasTabToShow" class="tab" data-cy="tab-replenishments">
              <h2 [className]="tabs.replenishments.active? 'active': ''" (click)="tabs.replenishments.activate()">{{tabs.replenishments.title}}</h2>
            </div>
          </div>
        
              <FootPrintManager-inventory_location_grid *ngIf="tabs.inventory.active"
              #$tabs_inventory
              [locationId]="$tabs_inventory_inventory_location_grid_inParams_locationId"
              ($refreshEvent)="refresh(false, false, '$tabs_inventory')">
              </FootPrintManager-inventory_location_grid>
              <FootPrintManager-licenseplate_list *ngIf="tabs.licenseplates_cards.active"
              #$tabs_licenseplates_cards
              [locationId]="$tabs_licenseplates_cards_licenseplate_list_inParams_locationId"
              ($refreshEvent)="refresh(false, false, '$tabs_licenseplates_cards')">
              </FootPrintManager-licenseplate_list>
              <app-custom_activity_grid *ngIf="tabs.tasks.active"
              #$tabs_tasks
              [operation_codes]="$tabs_tasks_custom_activity_grid_inParams_operation_codes"
              [location_id]="$tabs_tasks_custom_activity_grid_inParams_location_id"
              ($refreshEvent)="refresh(false, false, '$tabs_tasks')">
              </app-custom_activity_grid>
              <FootPrintManager-serialnumbers_grid *ngIf="tabs.serialnumbers.active"
              #$tabs_serialnumbers
              [locationId]="$tabs_serialnumbers_serialnumbers_grid_inParams_locationId"
              [archived]="$tabs_serialnumbers_serialnumbers_grid_inParams_archived"
              ($refreshEvent)="refresh(false, false, '$tabs_serialnumbers')">
              </FootPrintManager-serialnumbers_grid>
              <FootPrintManager-locations_replenishments_grid *ngIf="tabs.replenishments.active"
              #$tabs_replenishments
              [locationId]="$tabs_replenishments_locations_replenishments_grid_inParams_locationId"
              ($refreshEvent)="refresh(false, false, '$tabs_replenishments')">
              </FootPrintManager-locations_replenishments_grid>
        </div>

      </ng-container>
      <div *ngIf="$hasMissingRequiredInParams"
           class="missing-params">
        <h3>Please provide {{$missingRequiredInParams.toString()}}</h3>
      </div>
    </div>
  </div>
</div>