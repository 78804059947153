import { Inject, Injectable } from '@angular/core';
import { isNil } from 'lodash-es';
import { environment } from '../environments/environment';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';

@Injectable({ providedIn: 'root' })
export class EndeavorLabs_ds_prediction_flow_reportService {

  constructor(
    private utils: UtilsService,
    private settings: SettingsValuesService,
    ) { 
  }


  public async get(inParams: { fr?: string, to?: string, projectid?: number, whid?: number[] }): Promise<{ result?: { prediction?: { ShipmentId?: number, Picktime?: number, Predict?: number, Load?: number, OrderLookup?: string, Owner?: string, pickstart?: string, pickend?: string, loadstart?: string, loadend?: string, Weight?: number, UOM?: string, warehouse?: string, project?: string, Actual?: number, shipmin?: string, shipmax?: string, loadmin?: string, loadmax?: string }[] } }> {
    let url = `${environment.backendUrl}api/EndeavorLabs/datasources/ds_prediction_flow_report/get`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.utils.http.post(url, body, options);
    
  }

    
    hasMissingRequiredInParams(inParams: { 
      fr?: string, to?: string, projectid?: number, whid?: number[]    }) {
      return false;
    }
}
