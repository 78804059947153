import { 
  Component, 
  OnInit,
  OnChanges,
  Input,
  SimpleChanges, 
  EventEmitter,
  Output,
  Inject
} from '@angular/core';

import { FatNumberStyles } from './models/widget';
import { isNil } from 'lodash-es';
import { BaseComponent } from './components/base.component';

import { SharedModule } from './shared.module';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';
import { EndeavorLabs_ShellService } from './EndeavorLabs.shell.service';
import { EndeavorLabs_OperationService } from './EndeavorLabs.operation.service';
import { EndeavorLabs_DatasourceService } from './EndeavorLabs.datasource.index';
import { EndeavorLabs_FlowService } from './EndeavorLabs.flow.index';
import { EndeavorLabs_ReportService } from './EndeavorLabs.report.index';
import { EndeavorLabs_LocalizationService } from './EndeavorLabs.localization.service';
import { Language } from './localization.service';
import { JobStatus } from './common-interfaces'
import { CleanupLoggerService } from './cleanup.logging.service';
import { $frontendTypes} from './EndeavorLabs.frontend.types'
import { $frontendTypes as $types} from './EndeavorLabs.frontend.types' 

import { EModalSize, EToasterType, EToasterPosition } from 'wavelength-ui';


@Component({
  standalone: true,
  imports: [
    SharedModule,
  ],
  selector: 'EndeavorLabs-Predictions',
  templateUrl: './EndeavorLabs.Predictions.component.html'
})
export class EndeavorLabs_PredictionsComponent extends BaseComponent implements OnInit, OnChanges {
  //#region Outputs
  @Output()
  $refreshEvent = new EventEmitter();
  //#endregion Outputs

  inParams: { OrderId?: number } = { OrderId: null };
  //#region Inputs
  @Input('OrderId') set $inParams_OrderId(v: number) {
    this.inParams.OrderId = v;
  }
  get $inParams_OrderId(): number {
    return this.inParams.OrderId;
  }
  //#endregion Inputs

  //#region Variables
  //#endregion  
  private entity: { ShipmentId?: number, Picktime?: number, Predict?: number, Load?: number, OrderId?: number };

  value: any;
  styles: FatNumberStyles;
  originalValue: any;
  prefix: string = '';
  suffix: string = '';

  constructor(private utils: UtilsService,
private settings: SettingsValuesService,
private shell: EndeavorLabs_ShellService,
private datasources: EndeavorLabs_DatasourceService,
private flows: EndeavorLabs_FlowService,
private reports: EndeavorLabs_ReportService,
private localization: EndeavorLabs_LocalizationService,
private operations: EndeavorLabs_OperationService,
private logger: CleanupLoggerService,
) { 
    super();

    this.styles = new FatNumberStyles();
  }

  ngOnInit(): void {
    this.$init();
  }
  
  private $isFirstNgOnChanges = true;
  ngOnChanges(changes: SimpleChanges): void {
    if (this.$isFirstNgOnChanges) {
      this.$isFirstNgOnChanges = false;
    } else {
      this.$init();
    }
  }


  initialized = false;
  $hasDataLoaded = false;

  async $init() {

    await this.$dataLoad();

    this.initialized = true;
  }
  
  async $dataLoad() {
    const $widget = this;
    const $utils = this.utils;

    const dsParams = {
      OrderId:  $widget.inParams.OrderId 
    };

    const data = await this.datasources.EndeavorLabs.ds_prediction_flow_order.get(dsParams);
    if (isNil(data.result)) {
      this.$hasDataLoaded = false;
      this.entity = null;
    } else {
      this.$hasDataLoaded = true;
      this.entity = data.result;
      await this.$dataLoaded();
    }
  }

  async $dataLoaded() {
    const $widget = this;
    const $utils = this.utils;

    this.value = $widget.entity.Predict;

    
    this.originalValue = $widget.entity.Predict;
  }

  refresh(
    skipParent = false,
    skipChildren = false,
    childToSkip: string = null) {
    // up
    if (skipParent === false) {
      this.$refreshEvent.emit();
    }

    // self
    const result = this.$dataLoad();

    // children
    if (skipChildren === false) {
    }

    return result;
  }

  getStyleClass() {
    const valString = isNil(this.value) ? '' : this.value.toString();
    const length = (valString).length;
    if (length === 2) {
      return 'tens';
    }
    if (length === 3) {
      return 'hundreds';
    }
    if (length === 4) {
      return 'thousands';
    }
    if (length === 5) {
      return 'tenThousands';
    }
    if (length === 6) {
      return 'hundredThousands';
    }
    if (length > 6) {
      return 'millions';
    }
    return null;
  }

  //#region private flows
  predictions_flow(event = null) {
    return this.predictions_flowInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async predictions_flowInternal(
    $widget: EndeavorLabs_PredictionsComponent,
  
    $shell: EndeavorLabs_ShellService,
    $datasources: EndeavorLabs_DatasourceService,
    $flows: EndeavorLabs_FlowService,
    $reports: EndeavorLabs_ReportService,
    $settings: SettingsValuesService,
    $operations: EndeavorLabs_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: EndeavorLabs_LocalizationService,
    $event: any
  ) {
    this.logger.log('EndeavorLabs', 'Predictions.predictions_flow');
  
  }
  //#endregion private flows
}
