import { Inject, Injectable } from '@angular/core';
import { isNil } from 'lodash-es';
import { environment } from '../environments/environment';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';

@Injectable({ providedIn: 'root' })
export class app_custom_ds_dock_appointments_dashboard_gridService {

  constructor(
    private utils: UtilsService,
    private settings: SettingsValuesService,
    ) { 
  }


  public async getList(inParams: { from_date: string, to_date: string, warehouse_ids?: number[], $top?: number, $skip?: number }): Promise<{ result?: { appointment_lookup?: string, item_lookup?: string, appointment_date?: string, appointment_status?: string, shipment_type?: string, dock_door?: string, check_in?: string, carrier_status?: string, csw_status?: string, expected_cases?: number, expected_full_lp?: number, picked_received_cases?: number, picked_full_lp?: number, loaded?: number, pick_received_percent?: number, warehouse?: string, project_lookup_code?: string, order_class?: string, length?: number, load_putaway_percent?: number, last_pick_received_user?: string, last_load_putaway_user?: string, shipment_id?: number, order_id?: number, item_type?: string, scheduled_arrival?: string, wait_time?: number, appointment_id?: number, order_type?: string, actual?: number, first_completed_pick_task?: number, last_completed_pick_task?: number, today?: string, apppointment_date_test?: string }[], totalCount?: number }> {
    const missingRequiredInParams = [];
    if (isNil(inParams.from_date)) {
      missingRequiredInParams.push('\'from_date\'');
    }
    if (isNil(inParams.to_date)) {
      missingRequiredInParams.push('\'to_date\'');
    }
    if (missingRequiredInParams.length) {
    }
    let url = `${environment.backendUrl}api/app/datasources/custom_ds_dock_appointments_dashboard_grid/getList`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.utils.http.post(url, body, options);
    
  }
  public async getByKeys(inParams: { from_date?: string, to_date?: string, warehouse_ids?: number[], $keys: number[] }): Promise<{ result?: { appointment_lookup?: string, item_lookup?: string, appointment_date?: string, appointment_status?: string, shipment_type?: string, dock_door?: string, check_in?: string, carrier_status?: string, csw_status?: string, expected_cases?: number, expected_full_lp?: number, picked_received_cases?: number, picked_full_lp?: number, loaded?: number, pick_received_percent?: number, warehouse?: string, project_lookup_code?: string, order_class?: string, length?: number, load_putaway_percent?: number, last_pick_received_user?: string, last_load_putaway_user?: string, shipment_id?: number, order_id?: number, item_type?: string, scheduled_arrival?: string, wait_time?: number, appointment_id?: number, order_type?: string, actual?: number, first_completed_pick_task?: number, last_completed_pick_task?: number, today?: string, apppointment_date_test?: string }[] }> {
    const missingRequiredInParams = [];
    if (isNil(inParams.$keys)) {
      missingRequiredInParams.push('\'$keys\'');
    }
    if (missingRequiredInParams.length) {
    }
    let url = `${environment.backendUrl}api/app/datasources/custom_ds_dock_appointments_dashboard_grid/getByKeys`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.utils.http.post(url, body, options);
    
  }

    
    hasMissingRequiredInParams(inParams: { 
      from_date: string, to_date: string, warehouse_ids?: number[]    }) {
        if(isNil(inParams.from_date)) {
          return true; 
        }
        if(isNil(inParams.to_date)) {
          return true; 
        }
      return false;
    }
}
