import { Inject, Injectable } from '@angular/core';
import { isNil } from 'lodash-es';
import { environment } from '../environments/environment';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';

@Injectable({ providedIn: 'root' })
export class EndeavorLabs_ds_shipment_detailsService {

  constructor(
    private utils: UtilsService,
    private settings: SettingsValuesService,
    ) { 
  }

  public async get(inParams: {  }): 
  Promise<{ result: { Id?: number, AccountId?: number, CreatedSysDateTime?: string, ExpectedWarehouseId?: number, OrderLookups?: { ShipmentId?: number, Order?: { Id?: number, AccountId?: number, PreferredWarehouseId?: number, Project?: { Id?: number, Name?: string, Owner?: { Id?: number, Name?: string } }, Account?: { Id?: number, Name?: string } } }[], Account?: { Id?: number, Name?: string }, ExpectedWarehouse?: { Name?: string }, ShipmentLines?: { Id?: number, ExpectedAmount?: number, ExpectedPackagedId?: number, ShipmentId?: number }[] }[] }> 
  {
    let url = `${environment.backendUrl}api/EndeavorLabs/datasources/ds_shipment_details/get`;
    
    const options = {
    }
    
    const body = null;
    
    return this.utils.http.post(url, body, options);
    
  }
  public async getList(inParams: { $top?: number, $skip?: number }): 
  Promise<{ result: { Id?: number, AccountId?: number, CreatedSysDateTime?: string, ExpectedWarehouseId?: number, OrderLookups?: { ShipmentId?: number, Order?: { Id?: number, AccountId?: number, PreferredWarehouseId?: number, Project?: { Id?: number, Name?: string, Owner?: { Id?: number, Name?: string } }, Account?: { Id?: number, Name?: string } } }[], Account?: { Id?: number, Name?: string }, ExpectedWarehouse?: { Name?: string }, ShipmentLines?: { Id?: number, ExpectedAmount?: number, ExpectedPackagedId?: number, ShipmentId?: number }[] }[], totalCount: number }> 
  {
    let url = `${environment.backendUrl}api/EndeavorLabs/datasources/ds_shipment_details/getList`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.utils.http.post(url, body, options);
    
  }
  public async getByKeys(inParams: { $keys: number[] }): 
  Promise<{ result: { Id?: number, AccountId?: number, CreatedSysDateTime?: string, ExpectedWarehouseId?: number, OrderLookups?: { ShipmentId?: number, Order?: { Id?: number, AccountId?: number, PreferredWarehouseId?: number, Project?: { Id?: number, Name?: string, Owner?: { Id?: number, Name?: string } }, Account?: { Id?: number, Name?: string } } }[], Account?: { Id?: number, Name?: string }, ExpectedWarehouse?: { Name?: string }, ShipmentLines?: { Id?: number, ExpectedAmount?: number, ExpectedPackagedId?: number, ShipmentId?: number }[] }[] }> 
  {
    const missingRequiredInParams = [];
    if (isNil(inParams.$keys)) {
      missingRequiredInParams.push('\'$keys\'');
    }
    if (missingRequiredInParams.length) {
    }
    let url = `${environment.backendUrl}api/EndeavorLabs/datasources/ds_shipment_details/getByKeys`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.utils.http.post(url, body, options);
    
  }


    
    hasMissingRequiredInParams(inParams: { 
      }) {
      return false;
    }
}
