import { 
  Component, 
  OnInit,
  OnDestroy,
  OnChanges,
  Input,
  SimpleChanges,
  Output,
  EventEmitter,
  Inject,
  forwardRef
} from '@angular/core';

import { 
  FormGroup,
  Validators 
} from '@angular/forms';

import { isEqual, isNil } from 'lodash-es';
import { Subject } from 'rxjs';
import { takeUntil, mergeMap, shareReplay } from 'rxjs/operators';

import { DatexFormControl, validateControlOnChange, validateFormOnControlChange } from './models/datex-form-control';
import { 
  TextBoxModel, 
  NumberBoxModel, 
  SelectBoxModel, 
  ESelectBoxType,
  DateBoxModel, 
  CheckBoxModel, 
  TextModel, 
  LabelModel, 
  ButtonModel,
  SplitButtonModel,
  SeparatorModel,
  ImageModel,
  DrawModel,
  CodeBoxModel,
  ButtonStyles,
  ValueControlModel
} from './models/control';
import { Styles, ControlContainerStyles } from './models/style';
import { FieldModel } from './models/field';
import { FieldsetModel } from './models/fieldset';
import { ToolModel } from './models/tool';
import { BaseComponent } from './components/base.component';

import { SharedModule } from './shared.module';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';
import { EndeavorLabs_ShellService } from './EndeavorLabs.shell.service';
import { EndeavorLabs_OperationService } from './EndeavorLabs.operation.service';
import { EndeavorLabs_DatasourceService } from './EndeavorLabs.datasource.index';
import { EndeavorLabs_FlowService } from './EndeavorLabs.flow.index';
import { EndeavorLabs_ReportService } from './EndeavorLabs.report.index';
import { EndeavorLabs_LocalizationService } from './EndeavorLabs.localization.service';
import { Language } from './localization.service';
import { JobStatus } from './common-interfaces'
import { CleanupLoggerService } from './cleanup.logging.service';
import { $frontendTypes} from './EndeavorLabs.frontend.types'
import { $frontendTypes as $types} from './EndeavorLabs.frontend.types' 

import { EModalSize, EToasterType, EToasterPosition } from 'wavelength-ui';


@Component({
  standalone: true,
  imports: [
    SharedModule,
  ],
  selector: 'EndeavorLabs-endeavorlabs_library_home',
  templateUrl: './EndeavorLabs.endeavorlabs_library_home.component.html'
})
export class EndeavorLabs_endeavorlabs_library_homeComponent extends BaseComponent implements OnInit, OnDestroy {


  //#region Outputs
  @Output()
  $finish = new EventEmitter();
  //#endregion

  //#region title
  // Make it async so that it won't cause expressionChangedAfterItHasBeenCheckedError
  // The title is often meant to be shown from the parent (shell breadcrumb for example)
  // and often it will cause an expressionChangedAfterItHasBeenCheckedError because 
  // the parent has already been checked and the child now change something on the parent 
  // in dev, CD is run twice
  $titleChange = new EventEmitter<string>(true);
  private $_title: string;
  get title(): string {
    return this.$_title;
  }
  set title(t: string) {
    this.$_title = t;
    this.$titleChange.emit(this.$_title);
  }
  //#endregion title
  //#region Variables
  //#endregion
  //#region Events
  
  //#endregion

  formGroup: FormGroup = new FormGroup({
  });

  get valid(): boolean {
    return this.formGroup.valid;
  }
  
  toolbar = {
  };

  fields = {
    description: new FieldModel(new LabelModel(null, null, null)
, new ControlContainerStyles(null, null), 'Description', false, false),
  };

  fieldsets = {
  newGroup1: new FieldsetModel('Endeavor labs library', false, true, true, false),
};

  //#region fields inParams
  //#endregion fields inParams

  $formGroupFieldValidationObservables = {
  }
  

  constructor(
    private utils: UtilsService,
private settings: SettingsValuesService,
private shell: EndeavorLabs_ShellService,
private datasources: EndeavorLabs_DatasourceService,
private flows: EndeavorLabs_FlowService,
private reports: EndeavorLabs_ReportService,
private localization: EndeavorLabs_LocalizationService,
private operations: EndeavorLabs_OperationService,
private logger: CleanupLoggerService,
) { 
    super();
    this.$subscribeFormControlValueChanges();
  }

  ngOnInit(): void {
    this.$init();
  }
  

  private $unsubscribe$ = new Subject();
  ngOnDestroy(): void {
    this.$unsubscribe$.next(null);
    this.$unsubscribe$.complete();
  }

  initialized = false;

  async $init() {
    this.title = 'Home';
  
    const $form = this;
    const $utils = this.utils;

    (this.fields.description.control as LabelModel).label = `Endeavor Labs helps organizations realize the potential of data and AI to power digital transformation.We bring together diverse technical, industry, and functional expertise to deliver on projects from planning and design through implementation. Endeavor Labs is here to help your business navigate, execute, and win in the AI era.`;


    this.initialized = true;
  }

  private $subscribeFormControlValueChanges() {
  }

  close() {
    this.$finish.emit();
  }

  openImageViewer(imageSource: string) {
    this.shell.openImageViewerDialog(imageSource);
  }
 
}
