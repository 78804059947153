import { Injectable } from '@angular/core';
import { ParamMap } from '@angular/router';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { isNil, cloneDeep } from 'lodash-es';
import { ToastrService } from 'ngx-toastr';

import { WavelengthShellService } from 'wavelength-ui';
import { EModalSize, EToasterType, EToasterPosition } from 'wavelength-ui';


import { CleanupLoggerService } from './cleanup.logging.service';
import { Inventory_inventory_library_homeComponent } from './Inventory.inventory_library_home.component';
import { Inventory_inventory_licenseplate_snapshot_gridComponent } from './Inventory.inventory_licenseplate_snapshot_grid.component';
import { Inventory_inventory_lot_snapshot_gridComponent } from './Inventory.inventory_lot_snapshot_grid.component';
import { Inventory_inventory_material_snaphot_gridComponent } from './Inventory.inventory_material_snaphot_grid.component';
import { Inventory_inventory_snapshot_rules_gridComponent } from './Inventory.inventory_snapshot_rules_grid.component';
import { Inventory_inventory_by_project_reportComponent } from './Inventory.inventory_by_project_report.component';
import { Inventory_view_json_payloadComponent } from './Inventory.view_json_payload.component';
import { Inventory_container_type_categories_dd_singleComponent } from './Inventory.container_type_categories_dd_single.component';
import { Inventory_container_type_styles_dd_singleComponent } from './Inventory.container_type_styles_dd_single.component';
import { Inventory_inventory_aggregation_dd_singleComponent } from './Inventory.inventory_aggregation_dd_single.component';
import { Inventory_inventory_snapshot_levels_dd_singleComponent } from './Inventory.inventory_snapshot_levels_dd_single.component';
import { Inventory_inventory_type_dd_singleComponent } from './Inventory.inventory_type_dd_single.component';
import { Inventory_licenseplate_by_inventory_dd_singleComponent } from './Inventory.licenseplate_by_inventory_dd_single.component';
import { Inventory_licenseplates_dd_singleComponent } from './Inventory.licenseplates_dd_single.component';
import { Inventory_lots_by_licenseplate_dd_singleComponent } from './Inventory.lots_by_licenseplate_dd_single.component';
import { Inventory_materials_by_licenseplate_dd_singleComponent } from './Inventory.materials_by_licenseplate_dd_single.component';
import { Inventory_measurement_units_dd_singleComponent } from './Inventory.measurement_units_dd_single.component';
import { Inventory_operationcodes_dd_singleComponent } from './Inventory.operationcodes_dd_single.component';
import { Inventory_owners_dd_singleComponent } from './Inventory.owners_dd_single.component';
import { Inventory_packagings_by_licenseplate_material_dd_singleComponent } from './Inventory.packagings_by_licenseplate_material_dd_single.component';
import { Inventory_projects_dd_singleComponent } from './Inventory.projects_dd_single.component';
import { Inventory_reasoncodes_dd_singleComponent } from './Inventory.reasoncodes_dd_single.component';
import { Inventory_serialnumbers_by_inventory_dd_singleComponent } from './Inventory.serialnumbers_by_inventory_dd_single.component';
import { Inventory_task_statuses_dd_singleComponent } from './Inventory.task_statuses_dd_single.component';
import { Inventory_temperature_categories_dd_singleComponent } from './Inventory.temperature_categories_dd_single.component';
import { Inventory_temperature_category_rules_dd_singleComponent } from './Inventory.temperature_category_rules_dd_single.component';
import { Inventory_vendorlots_by_licenseplate_dd_singleComponent } from './Inventory.vendorlots_by_licenseplate_dd_single.component';
import { Inventory_warehouse_dd_singleComponent } from './Inventory.warehouse_dd_single.component';
import { Inventory_container_type_categories_dd_multiComponent } from './Inventory.container_type_categories_dd_multi.component';
import { Inventory_container_type_styles_dd_multiComponent } from './Inventory.container_type_styles_dd_multi.component';
import { Inventory_inventory_aggregation_dd_multiComponent } from './Inventory.inventory_aggregation_dd_multi.component';
import { Inventory_inventory_snapshot_levels_dd_multiComponent } from './Inventory.inventory_snapshot_levels_dd_multi.component';
import { Inventory_inventory_type_dd_multiComponent } from './Inventory.inventory_type_dd_multi.component';
import { Inventory_licenseplate_by_inventory_dd_multiComponent } from './Inventory.licenseplate_by_inventory_dd_multi.component';
import { Inventory_licenseplates_dd_multiComponent } from './Inventory.licenseplates_dd_multi.component';
import { Inventory_lots_by_licenseplate_dd_multiComponent } from './Inventory.lots_by_licenseplate_dd_multi.component';
import { Inventory_materials_by_licenseplate_dd_multiComponent } from './Inventory.materials_by_licenseplate_dd_multi.component';
import { Inventory_measurement_units_dd_multiComponent } from './Inventory.measurement_units_dd_multi.component';
import { Inventory_operationcodes_dd_multiComponent } from './Inventory.operationcodes_dd_multi.component';
import { Inventory_owners_dd_multiComponent } from './Inventory.owners_dd_multi.component';
import { Inventory_packagings_by_licenseplate_material_dd_multiComponent } from './Inventory.packagings_by_licenseplate_material_dd_multi.component';
import { Inventory_projects_dd_multiComponent } from './Inventory.projects_dd_multi.component';
import { Inventory_reasoncodes_dd_multiComponent } from './Inventory.reasoncodes_dd_multi.component';
import { Inventory_serialnumbers_by_inventory_dd_multiComponent } from './Inventory.serialnumbers_by_inventory_dd_multi.component';
import { Inventory_task_statuses_dd_multiComponent } from './Inventory.task_statuses_dd_multi.component';
import { Inventory_temperature_categories_dd_multiComponent } from './Inventory.temperature_categories_dd_multi.component';
import { Inventory_temperature_category_rules_dd_multiComponent } from './Inventory.temperature_category_rules_dd_multi.component';
import { Inventory_vendorlots_by_licenseplate_dd_multiComponent } from './Inventory.vendorlots_by_licenseplate_dd_multi.component';
import { Inventory_warehouse_dd_multiComponent } from './Inventory.warehouse_dd_multi.component';
import { Inventory_licenseplate_total_gross_weight_widgetComponent } from './Inventory.licenseplate_total_gross_weight_widget.component';
import { Inventory_licenseplate_total_packaged_amount_widgetComponent } from './Inventory.licenseplate_total_packaged_amount_widget.component';
import { Inventory_serialnumber_gross_weight_widgetComponent } from './Inventory.serialnumber_gross_weight_widget.component';
import { Inventory_serialnumber_net_weight_widgetComponent } from './Inventory.serialnumber_net_weight_widget.component';
import { Inventory_total_available_amount_location_widgetComponent } from './Inventory.total_available_amount_location_widget.component';
import { Inventory_total_available_amount_widgetComponent } from './Inventory.total_available_amount_widget.component';
import { Inventory_total_available_base_amount_widgetComponent } from './Inventory.total_available_base_amount_widget.component';
import { Inventory_total_gross_weight_widgetComponent } from './Inventory.total_gross_weight_widget.component';
import { Inventory_total_packaged_amount_widgetComponent } from './Inventory.total_packaged_amount_widget.component';

import { Utilities_ShellService } from './Utilities.shell.service';

@Injectable({ providedIn: 'root' })
export class Inventory_ShellService extends WavelengthShellService  {
  constructor(
    dialog : MatDialog,
    toastr: ToastrService,
    private logger: CleanupLoggerService,
    public Utilities: Utilities_ShellService,
  ) {
    super(dialog, toastr);
  }

  public Inventory: Inventory_ShellService = this;

  public openinventory_library_home(replaceCurrentView?: boolean) {
    this.logger.log('Inventory', 'inventory_library_home');
    WavelengthShellService.openViewRequest$.next(
      {
        title: 'Home',
        referenceName: 'Inventory_inventory_library_home',
        component: Inventory_inventory_library_homeComponent,
        replaceCurrentView: replaceCurrentView,
        
      }
    );
  }

  public openinventory_library_homeDialog(
    
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    this.logger.log('Inventory', 'inventory_library_home');
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      Inventory_inventory_library_homeComponent,
      'Home',
      mode,
      dialogSize
    )
  }
  public openinventory_licenseplate_snapshot_grid(inParams:{ projectId: number, warehouseId: number, captureDate: string, materialIds?: number[] }, replaceCurrentView?: boolean) {
    this.logger.log('Inventory', 'inventory_licenseplate_snapshot_grid');
    WavelengthShellService.openViewRequest$.next(
      {
        title: 'Inventory licenseplate snapshot grid',
        referenceName: 'Inventory_inventory_licenseplate_snapshot_grid',
        component: Inventory_inventory_licenseplate_snapshot_gridComponent,
        replaceCurrentView: replaceCurrentView,
        inParams: inParams
      }
    );
  }

  public openinventory_licenseplate_snapshot_gridDialog(
    inParams:{ projectId: number, warehouseId: number, captureDate: string, materialIds?: number[] }, 
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    this.logger.log('Inventory', 'inventory_licenseplate_snapshot_grid');
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      Inventory_inventory_licenseplate_snapshot_gridComponent,
      'Inventory licenseplate snapshot grid',
      mode,
      dialogSize
      , cloneDeep(inParams)
    )
  }
  public openinventory_lot_snapshot_grid(inParams:{ captureDate: string, projectId: number, warehouseId: number, materialIds?: number[] }, replaceCurrentView?: boolean) {
    this.logger.log('Inventory', 'inventory_lot_snapshot_grid');
    WavelengthShellService.openViewRequest$.next(
      {
        title: 'Inventory lot snapshot grid',
        referenceName: 'Inventory_inventory_lot_snapshot_grid',
        component: Inventory_inventory_lot_snapshot_gridComponent,
        replaceCurrentView: replaceCurrentView,
        inParams: inParams
      }
    );
  }

  public openinventory_lot_snapshot_gridDialog(
    inParams:{ captureDate: string, projectId: number, warehouseId: number, materialIds?: number[] }, 
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    this.logger.log('Inventory', 'inventory_lot_snapshot_grid');
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      Inventory_inventory_lot_snapshot_gridComponent,
      'Inventory lot snapshot grid',
      mode,
      dialogSize
      , cloneDeep(inParams)
    )
  }
  public openinventory_material_snaphot_grid(inParams:{ captureDate: string, projectId: number, materialIds?: number[], warehouseId: number }, replaceCurrentView?: boolean) {
    this.logger.log('Inventory', 'inventory_material_snaphot_grid');
    WavelengthShellService.openViewRequest$.next(
      {
        title: 'Inventory material snaphot grid',
        referenceName: 'Inventory_inventory_material_snaphot_grid',
        component: Inventory_inventory_material_snaphot_gridComponent,
        replaceCurrentView: replaceCurrentView,
        inParams: inParams
      }
    );
  }

  public openinventory_material_snaphot_gridDialog(
    inParams:{ captureDate: string, projectId: number, materialIds?: number[], warehouseId: number }, 
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    this.logger.log('Inventory', 'inventory_material_snaphot_grid');
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      Inventory_inventory_material_snaphot_gridComponent,
      'Inventory material snaphot grid',
      mode,
      dialogSize
      , cloneDeep(inParams)
    )
  }
  public openinventory_snapshot_rules_grid(replaceCurrentView?: boolean) {
    WavelengthShellService.openViewRequest$.next(
      {
        title: 'Inventory snapshot rules',
        referenceName: 'Inventory_inventory_snapshot_rules_grid',
        component: Inventory_inventory_snapshot_rules_gridComponent,
        replaceCurrentView: replaceCurrentView,
        
      }
    );
  }

  public openinventory_snapshot_rules_gridDialog(
    
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      Inventory_inventory_snapshot_rules_gridComponent,
      'Inventory snapshot rules',
      mode,
      dialogSize
    )
  }
  public openinventory_by_project_report(inParams:{ projectId: number }, replaceCurrentView?: boolean) {
    WavelengthShellService.openViewRequest$.next(
      {
        title: 'custom_inventory_report_by_project',
        referenceName: 'Inventory_inventory_by_project_report',
        component: Inventory_inventory_by_project_reportComponent,
        replaceCurrentView: replaceCurrentView,
        inParams: inParams
      }
    );
  }

  public openinventory_by_project_reportDialog(
    inParams:{ projectId: number }, 
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Xlarge;
    }

    return this.openDialog(
      Inventory_inventory_by_project_reportComponent,
      'custom_inventory_report_by_project',
      mode,
      dialogSize
      , cloneDeep(inParams)
    )
  }
  public openview_json_payload(inParams:{ payload?: any, title?: string }, replaceCurrentView?: boolean) {
    WavelengthShellService.openViewRequest$.next(
      {
        title: 'view_json_payload',
        referenceName: 'Inventory_view_json_payload',
        component: Inventory_view_json_payloadComponent,
        replaceCurrentView: replaceCurrentView,
        inParams: inParams
      }
    );
  }

  public openview_json_payloadDialog(
    inParams:{ payload?: any, title?: string }, 
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      Inventory_view_json_payloadComponent,
      'view_json_payload',
      mode,
      dialogSize
      , cloneDeep(inParams)
    )
  }

  public override getComponentInformation(referenceName: string, params: ParamMap): { title: string, component: any, inParams: any } {
    if (referenceName === 'Inventory_inventory_library_home') {
      this.logger.log('Inventory', 'inventory_library_home');
      const title = 'Home';
      const component = Inventory_inventory_library_homeComponent;
      const inParams = null;

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'Inventory_inventory_licenseplate_snapshot_grid') {
      this.logger.log('Inventory', 'inventory_licenseplate_snapshot_grid');
      const title = 'Inventory licenseplate snapshot grid';
      const component = Inventory_inventory_licenseplate_snapshot_gridComponent;
      const inParams:{ projectId: number, warehouseId: number, captureDate: string, materialIds?: number[] } = { projectId: null, warehouseId: null, captureDate: null, materialIds: [] };
      if (!isNil(params.get('projectId'))) {
        const paramValueString = params.get('projectId');
        inParams.projectId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('warehouseId'))) {
        const paramValueString = params.get('warehouseId');
        inParams.warehouseId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('captureDate'))) {
        const paramValueString = params.get('captureDate');
        // TODO: date
        inParams.captureDate = paramValueString;
              }
      if (!isNil(params.get('materialIds'))) {
        const paramValueString = params.get('materialIds');
        inParams.materialIds = this.convertToJSON(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'Inventory_inventory_lot_snapshot_grid') {
      this.logger.log('Inventory', 'inventory_lot_snapshot_grid');
      const title = 'Inventory lot snapshot grid';
      const component = Inventory_inventory_lot_snapshot_gridComponent;
      const inParams:{ captureDate: string, projectId: number, warehouseId: number, materialIds?: number[] } = { captureDate: null, projectId: null, warehouseId: null, materialIds: [] };
      if (!isNil(params.get('captureDate'))) {
        const paramValueString = params.get('captureDate');
        // TODO: date
        inParams.captureDate = paramValueString;
              }
      if (!isNil(params.get('projectId'))) {
        const paramValueString = params.get('projectId');
        inParams.projectId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('warehouseId'))) {
        const paramValueString = params.get('warehouseId');
        inParams.warehouseId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('materialIds'))) {
        const paramValueString = params.get('materialIds');
        inParams.materialIds = this.convertToJSON(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'Inventory_inventory_material_snaphot_grid') {
      this.logger.log('Inventory', 'inventory_material_snaphot_grid');
      const title = 'Inventory material snaphot grid';
      const component = Inventory_inventory_material_snaphot_gridComponent;
      const inParams:{ captureDate: string, projectId: number, materialIds?: number[], warehouseId: number } = { captureDate: null, projectId: null, materialIds: [], warehouseId: null };
      if (!isNil(params.get('captureDate'))) {
        const paramValueString = params.get('captureDate');
        // TODO: date
        inParams.captureDate = paramValueString;
              }
      if (!isNil(params.get('projectId'))) {
        const paramValueString = params.get('projectId');
        inParams.projectId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('materialIds'))) {
        const paramValueString = params.get('materialIds');
        inParams.materialIds = this.convertToJSON(paramValueString);
      }
      if (!isNil(params.get('warehouseId'))) {
        const paramValueString = params.get('warehouseId');
        inParams.warehouseId = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'Inventory_inventory_snapshot_rules_grid') {
      const title = 'Inventory snapshot rules';
      const component = Inventory_inventory_snapshot_rules_gridComponent;
      const inParams = null;

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'Inventory_inventory_by_project_report') {
      const title = 'custom_inventory_report_by_project';
      const component = Inventory_inventory_by_project_reportComponent;
      const inParams:{ projectId: number } = { projectId: null };
      if (!isNil(params.get('projectId'))) {
        const paramValueString = params.get('projectId');
        inParams.projectId = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'Inventory_view_json_payload') {
      const title = 'view_json_payload';
      const component = Inventory_view_json_payloadComponent;
      const inParams:{ payload?: any, title?: string } = { payload: null, title: null };
      if (!isNil(params.get('payload'))) {
        const paramValueString = params.get('payload');
        inParams.payload = this.convertToJSON(paramValueString);
      }
      if (!isNil(params.get('title'))) {
        const paramValueString = params.get('title');
        // TODO: date
        inParams.title = paramValueString;
              }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'Inventory_container_type_categories_dd_single') {
      const title = 'Container Type Categories';
      const component = Inventory_container_type_categories_dd_singleComponent;
      const inParams = null;

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'Inventory_container_type_styles_dd_single') {
      const title = 'container_type_styles_dd';
      const component = Inventory_container_type_styles_dd_singleComponent;
      const inParams = null;

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'Inventory_inventory_aggregation_dd_single') {
      const title = 'Inventory aggregation type';
      const component = Inventory_inventory_aggregation_dd_singleComponent;
      const inParams = null;

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'Inventory_inventory_snapshot_levels_dd_single') {
      const title = 'Inventory snapshot levels';
      const component = Inventory_inventory_snapshot_levels_dd_singleComponent;
      const inParams = null;

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'Inventory_inventory_type_dd_single') {
      const title = 'Inventory type';
      const component = Inventory_inventory_type_dd_singleComponent;
      const inParams = null;

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'Inventory_licenseplate_by_inventory_dd_single') {
      const title = 'Licenseplates by Inventory';
      const component = Inventory_licenseplate_by_inventory_dd_singleComponent;
      const inParams:{ lotId: number, packagingId: number, warehouseId: number } = { lotId: null, packagingId: null, warehouseId: null };
      if (!isNil(params.get('lotId'))) {
        const paramValueString = params.get('lotId');
        inParams.lotId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('packagingId'))) {
        const paramValueString = params.get('packagingId');
        inParams.packagingId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('warehouseId'))) {
        const paramValueString = params.get('warehouseId');
        inParams.warehouseId = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'Inventory_licenseplates_dd_single') {
      const title = 'Licenseplates dropdown';
      const component = Inventory_licenseplates_dd_singleComponent;
      const inParams:{ locationId: number, typeId: number, archived: boolean, shipmentId?: number } = { locationId: null, typeId: null, archived: null, shipmentId: null };
      if (!isNil(params.get('locationId'))) {
        const paramValueString = params.get('locationId');
        inParams.locationId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('typeId'))) {
        const paramValueString = params.get('typeId');
        inParams.typeId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('archived'))) {
        const paramValueString = params.get('archived');
        inParams.archived = this.convertToBoolean(paramValueString);
      }
      if (!isNil(params.get('shipmentId'))) {
        const paramValueString = params.get('shipmentId');
        inParams.shipmentId = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'Inventory_lots_by_licenseplate_dd_single') {
      const title = 'Lots by Licenseplate';
      const component = Inventory_lots_by_licenseplate_dd_singleComponent;
      const inParams:{ licenseplateId?: number, materialId?: number, vendorlotId?: number } = { licenseplateId: null, materialId: null, vendorlotId: null };
      if (!isNil(params.get('licenseplateId'))) {
        const paramValueString = params.get('licenseplateId');
        inParams.licenseplateId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('materialId'))) {
        const paramValueString = params.get('materialId');
        inParams.materialId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('vendorlotId'))) {
        const paramValueString = params.get('vendorlotId');
        inParams.vendorlotId = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'Inventory_materials_by_licenseplate_dd_single') {
      const title = 'Materials by Licenseplate';
      const component = Inventory_materials_by_licenseplate_dd_singleComponent;
      const inParams:{ licenseplateId: number } = { licenseplateId: null };
      if (!isNil(params.get('licenseplateId'))) {
        const paramValueString = params.get('licenseplateId');
        inParams.licenseplateId = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'Inventory_measurement_units_dd_single') {
      const title = 'Measurement Units Dropdown';
      const component = Inventory_measurement_units_dd_singleComponent;
      const inParams:{ typeId?: number } = { typeId: null };
      if (!isNil(params.get('typeId'))) {
        const paramValueString = params.get('typeId');
        inParams.typeId = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'Inventory_operationcodes_dd_single') {
      this.logger.log('Inventory', 'operationcodes_dd_single');
      const title = 'Operation codes';
      const component = Inventory_operationcodes_dd_singleComponent;
      const inParams:{ operationCodeIds?: number[] } = { operationCodeIds: [] };
      if (!isNil(params.get('operationCodeIds'))) {
        const paramValueString = params.get('operationCodeIds');
        inParams.operationCodeIds = this.convertToJSON(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'Inventory_owners_dd_single') {
      const title = 'Owners dropdown';
      const component = Inventory_owners_dd_singleComponent;
      const inParams:{ statusId?: number, projectId?: number } = { statusId: null, projectId: null };
      if (!isNil(params.get('statusId'))) {
        const paramValueString = params.get('statusId');
        inParams.statusId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('projectId'))) {
        const paramValueString = params.get('projectId');
        inParams.projectId = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'Inventory_packagings_by_licenseplate_material_dd_single') {
      const title = 'Packages by Licenseplates and Materials';
      const component = Inventory_packagings_by_licenseplate_material_dd_singleComponent;
      const inParams:{ licenseplateId: number, materialId: number } = { licenseplateId: null, materialId: null };
      if (!isNil(params.get('licenseplateId'))) {
        const paramValueString = params.get('licenseplateId');
        inParams.licenseplateId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('materialId'))) {
        const paramValueString = params.get('materialId');
        inParams.materialId = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'Inventory_projects_dd_single') {
      const title = 'Projects dropdown';
      const component = Inventory_projects_dd_singleComponent;
      const inParams:{ statusId?: number, ownerId?: number } = { statusId: null, ownerId: null };
      if (!isNil(params.get('statusId'))) {
        const paramValueString = params.get('statusId');
        inParams.statusId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('ownerId'))) {
        const paramValueString = params.get('ownerId');
        inParams.ownerId = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'Inventory_reasoncodes_dd_single') {
      const title = 'Reasoncodes dropdown';
      const component = Inventory_reasoncodes_dd_singleComponent;
      const inParams:{ parentId: number, parentEntity: string } = { parentId: null, parentEntity: null };
      if (!isNil(params.get('parentId'))) {
        const paramValueString = params.get('parentId');
        inParams.parentId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('parentEntity'))) {
        const paramValueString = params.get('parentEntity');
        // TODO: date
        inParams.parentEntity = paramValueString;
              }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'Inventory_serialnumbers_by_inventory_dd_single') {
      const title = 'Serialnumbers by Inventory';
      const component = Inventory_serialnumbers_by_inventory_dd_singleComponent;
      const inParams:{ lotId?: number, packagingId: number, warehouseId: number, licenseplateId?: number, materialId?: number } = { lotId: null, packagingId: null, warehouseId: null, licenseplateId: null, materialId: null };
      if (!isNil(params.get('lotId'))) {
        const paramValueString = params.get('lotId');
        inParams.lotId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('packagingId'))) {
        const paramValueString = params.get('packagingId');
        inParams.packagingId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('warehouseId'))) {
        const paramValueString = params.get('warehouseId');
        inParams.warehouseId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('licenseplateId'))) {
        const paramValueString = params.get('licenseplateId');
        inParams.licenseplateId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('materialId'))) {
        const paramValueString = params.get('materialId');
        inParams.materialId = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'Inventory_task_statuses_dd_single') {
      const title = 'Task Statuses';
      const component = Inventory_task_statuses_dd_singleComponent;
      const inParams = null;

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'Inventory_temperature_categories_dd_single') {
      this.logger.log('Inventory', 'temperature_categories_dd_single');
      const title = 'Temperature Categories';
      const component = Inventory_temperature_categories_dd_singleComponent;
      const inParams = null;

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'Inventory_temperature_category_rules_dd_single') {
      this.logger.log('Inventory', 'temperature_category_rules_dd_single');
      const title = 'Temperature Category Rules';
      const component = Inventory_temperature_category_rules_dd_singleComponent;
      const inParams = null;

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'Inventory_vendorlots_by_licenseplate_dd_single') {
      const title = 'Vendorlots by Licenseplate';
      const component = Inventory_vendorlots_by_licenseplate_dd_singleComponent;
      const inParams:{ licenseplateId?: number, materialId?: number } = { licenseplateId: null, materialId: null };
      if (!isNil(params.get('licenseplateId'))) {
        const paramValueString = params.get('licenseplateId');
        inParams.licenseplateId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('materialId'))) {
        const paramValueString = params.get('materialId');
        inParams.materialId = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'Inventory_warehouse_dd_single') {
      const title = 'Warehouse dropdown';
      const component = Inventory_warehouse_dd_singleComponent;
      const inParams:{ warehouseId?: number[] } = { warehouseId: [] };
      if (!isNil(params.get('warehouseId'))) {
        const paramValueString = params.get('warehouseId');
        inParams.warehouseId = this.convertToJSON(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'Inventory_container_type_categories_dd_multi') {
      const title = 'Container Type Categories';
      const component = Inventory_container_type_categories_dd_multiComponent;
      const inParams = null;

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'Inventory_container_type_styles_dd_multi') {
      const title = 'container_type_styles_dd';
      const component = Inventory_container_type_styles_dd_multiComponent;
      const inParams = null;

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'Inventory_inventory_aggregation_dd_multi') {
      const title = 'Inventory aggregation type';
      const component = Inventory_inventory_aggregation_dd_multiComponent;
      const inParams = null;

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'Inventory_inventory_snapshot_levels_dd_multi') {
      const title = 'Inventory snapshot levels';
      const component = Inventory_inventory_snapshot_levels_dd_multiComponent;
      const inParams = null;

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'Inventory_inventory_type_dd_multi') {
      const title = 'Inventory type';
      const component = Inventory_inventory_type_dd_multiComponent;
      const inParams = null;

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'Inventory_licenseplate_by_inventory_dd_multi') {
      const title = 'Licenseplates by Inventory';
      const component = Inventory_licenseplate_by_inventory_dd_multiComponent;
      const inParams:{ lotId: number, packagingId: number, warehouseId: number } = { lotId: null, packagingId: null, warehouseId: null };
      if (!isNil(params.get('lotId'))) {
        const paramValueString = params.get('lotId');
        inParams.lotId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('packagingId'))) {
        const paramValueString = params.get('packagingId');
        inParams.packagingId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('warehouseId'))) {
        const paramValueString = params.get('warehouseId');
        inParams.warehouseId = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'Inventory_licenseplates_dd_multi') {
      const title = 'Licenseplates dropdown';
      const component = Inventory_licenseplates_dd_multiComponent;
      const inParams:{ locationId: number, typeId: number, archived: boolean, shipmentId?: number } = { locationId: null, typeId: null, archived: null, shipmentId: null };
      if (!isNil(params.get('locationId'))) {
        const paramValueString = params.get('locationId');
        inParams.locationId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('typeId'))) {
        const paramValueString = params.get('typeId');
        inParams.typeId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('archived'))) {
        const paramValueString = params.get('archived');
        inParams.archived = this.convertToBoolean(paramValueString);
      }
      if (!isNil(params.get('shipmentId'))) {
        const paramValueString = params.get('shipmentId');
        inParams.shipmentId = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'Inventory_lots_by_licenseplate_dd_multi') {
      const title = 'Lots by Licenseplate';
      const component = Inventory_lots_by_licenseplate_dd_multiComponent;
      const inParams:{ licenseplateId?: number, materialId?: number, vendorlotId?: number } = { licenseplateId: null, materialId: null, vendorlotId: null };
      if (!isNil(params.get('licenseplateId'))) {
        const paramValueString = params.get('licenseplateId');
        inParams.licenseplateId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('materialId'))) {
        const paramValueString = params.get('materialId');
        inParams.materialId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('vendorlotId'))) {
        const paramValueString = params.get('vendorlotId');
        inParams.vendorlotId = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'Inventory_materials_by_licenseplate_dd_multi') {
      const title = 'Materials by Licenseplate';
      const component = Inventory_materials_by_licenseplate_dd_multiComponent;
      const inParams:{ licenseplateId: number } = { licenseplateId: null };
      if (!isNil(params.get('licenseplateId'))) {
        const paramValueString = params.get('licenseplateId');
        inParams.licenseplateId = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'Inventory_measurement_units_dd_multi') {
      const title = 'Measurement Units Dropdown';
      const component = Inventory_measurement_units_dd_multiComponent;
      const inParams:{ typeId?: number } = { typeId: null };
      if (!isNil(params.get('typeId'))) {
        const paramValueString = params.get('typeId');
        inParams.typeId = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'Inventory_operationcodes_dd_multi') {
      this.logger.log('Inventory', 'operationcodes_dd_multi');
      const title = 'Operation codes';
      const component = Inventory_operationcodes_dd_multiComponent;
      const inParams:{ operationCodeIds?: number[] } = { operationCodeIds: [] };
      if (!isNil(params.get('operationCodeIds'))) {
        const paramValueString = params.get('operationCodeIds');
        inParams.operationCodeIds = this.convertToJSON(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'Inventory_owners_dd_multi') {
      const title = 'Owners dropdown';
      const component = Inventory_owners_dd_multiComponent;
      const inParams:{ statusId?: number, projectId?: number } = { statusId: null, projectId: null };
      if (!isNil(params.get('statusId'))) {
        const paramValueString = params.get('statusId');
        inParams.statusId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('projectId'))) {
        const paramValueString = params.get('projectId');
        inParams.projectId = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'Inventory_packagings_by_licenseplate_material_dd_multi') {
      const title = 'Packages by Licenseplates and Materials';
      const component = Inventory_packagings_by_licenseplate_material_dd_multiComponent;
      const inParams:{ licenseplateId: number, materialId: number } = { licenseplateId: null, materialId: null };
      if (!isNil(params.get('licenseplateId'))) {
        const paramValueString = params.get('licenseplateId');
        inParams.licenseplateId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('materialId'))) {
        const paramValueString = params.get('materialId');
        inParams.materialId = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'Inventory_projects_dd_multi') {
      const title = 'Projects dropdown';
      const component = Inventory_projects_dd_multiComponent;
      const inParams:{ statusId?: number, ownerId?: number } = { statusId: null, ownerId: null };
      if (!isNil(params.get('statusId'))) {
        const paramValueString = params.get('statusId');
        inParams.statusId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('ownerId'))) {
        const paramValueString = params.get('ownerId');
        inParams.ownerId = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'Inventory_reasoncodes_dd_multi') {
      const title = 'Reasoncodes dropdown';
      const component = Inventory_reasoncodes_dd_multiComponent;
      const inParams:{ parentId: number, parentEntity: string } = { parentId: null, parentEntity: null };
      if (!isNil(params.get('parentId'))) {
        const paramValueString = params.get('parentId');
        inParams.parentId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('parentEntity'))) {
        const paramValueString = params.get('parentEntity');
        // TODO: date
        inParams.parentEntity = paramValueString;
              }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'Inventory_serialnumbers_by_inventory_dd_multi') {
      const title = 'Serialnumbers by Inventory';
      const component = Inventory_serialnumbers_by_inventory_dd_multiComponent;
      const inParams:{ lotId?: number, packagingId: number, warehouseId: number, licenseplateId?: number, materialId?: number } = { lotId: null, packagingId: null, warehouseId: null, licenseplateId: null, materialId: null };
      if (!isNil(params.get('lotId'))) {
        const paramValueString = params.get('lotId');
        inParams.lotId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('packagingId'))) {
        const paramValueString = params.get('packagingId');
        inParams.packagingId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('warehouseId'))) {
        const paramValueString = params.get('warehouseId');
        inParams.warehouseId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('licenseplateId'))) {
        const paramValueString = params.get('licenseplateId');
        inParams.licenseplateId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('materialId'))) {
        const paramValueString = params.get('materialId');
        inParams.materialId = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'Inventory_task_statuses_dd_multi') {
      const title = 'Task Statuses';
      const component = Inventory_task_statuses_dd_multiComponent;
      const inParams = null;

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'Inventory_temperature_categories_dd_multi') {
      this.logger.log('Inventory', 'temperature_categories_dd_multi');
      const title = 'Temperature Categories';
      const component = Inventory_temperature_categories_dd_multiComponent;
      const inParams = null;

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'Inventory_temperature_category_rules_dd_multi') {
      this.logger.log('Inventory', 'temperature_category_rules_dd_multi');
      const title = 'Temperature Category Rules';
      const component = Inventory_temperature_category_rules_dd_multiComponent;
      const inParams = null;

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'Inventory_vendorlots_by_licenseplate_dd_multi') {
      const title = 'Vendorlots by Licenseplate';
      const component = Inventory_vendorlots_by_licenseplate_dd_multiComponent;
      const inParams:{ licenseplateId?: number, materialId?: number } = { licenseplateId: null, materialId: null };
      if (!isNil(params.get('licenseplateId'))) {
        const paramValueString = params.get('licenseplateId');
        inParams.licenseplateId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('materialId'))) {
        const paramValueString = params.get('materialId');
        inParams.materialId = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'Inventory_warehouse_dd_multi') {
      const title = 'Warehouse dropdown';
      const component = Inventory_warehouse_dd_multiComponent;
      const inParams:{ warehouseId?: number[] } = { warehouseId: [] };
      if (!isNil(params.get('warehouseId'))) {
        const paramValueString = params.get('warehouseId');
        inParams.warehouseId = this.convertToJSON(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'Inventory_licenseplate_total_gross_weight_widget') {
      const title = 'Total gross weight';
      const component = Inventory_licenseplate_total_gross_weight_widgetComponent;
      const inParams:{ licenseplateId: number } = { licenseplateId: null };
      if (!isNil(params.get('licenseplateId'))) {
        const paramValueString = params.get('licenseplateId');
        inParams.licenseplateId = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'Inventory_licenseplate_total_packaged_amount_widget') {
      const title = 'Total amount';
      const component = Inventory_licenseplate_total_packaged_amount_widgetComponent;
      const inParams:{ licenseplateId: number } = { licenseplateId: null };
      if (!isNil(params.get('licenseplateId'))) {
        const paramValueString = params.get('licenseplateId');
        inParams.licenseplateId = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'Inventory_serialnumber_gross_weight_widget') {
      const title = 'Gross weight';
      const component = Inventory_serialnumber_gross_weight_widgetComponent;
      const inParams:{ serialnumberId: number } = { serialnumberId: null };
      if (!isNil(params.get('serialnumberId'))) {
        const paramValueString = params.get('serialnumberId');
        inParams.serialnumberId = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'Inventory_serialnumber_net_weight_widget') {
      const title = 'Net Weight';
      const component = Inventory_serialnumber_net_weight_widgetComponent;
      const inParams:{ serialnumberId: number } = { serialnumberId: null };
      if (!isNil(params.get('serialnumberId'))) {
        const paramValueString = params.get('serialnumberId');
        inParams.serialnumberId = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'Inventory_total_available_amount_location_widget') {
      const title = 'Total available';
      const component = Inventory_total_available_amount_location_widgetComponent;
      const inParams:{ locationId?: number } = { locationId: null };
      if (!isNil(params.get('locationId'))) {
        const paramValueString = params.get('locationId');
        inParams.locationId = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'Inventory_total_available_amount_widget') {
      const title = 'Total available';
      const component = Inventory_total_available_amount_widgetComponent;
      const inParams:{ ownerId?: number, projectId?: number, lotId?: number, materialIds?: number[], locationId?: number, materialId?: number, warehouseId?: number } = { ownerId: null, projectId: null, lotId: null, materialIds: [], locationId: null, materialId: null, warehouseId: null };
      if (!isNil(params.get('ownerId'))) {
        const paramValueString = params.get('ownerId');
        inParams.ownerId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('projectId'))) {
        const paramValueString = params.get('projectId');
        inParams.projectId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('lotId'))) {
        const paramValueString = params.get('lotId');
        inParams.lotId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('materialIds'))) {
        const paramValueString = params.get('materialIds');
        inParams.materialIds = this.convertToJSON(paramValueString);
      }
      if (!isNil(params.get('locationId'))) {
        const paramValueString = params.get('locationId');
        inParams.locationId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('materialId'))) {
        const paramValueString = params.get('materialId');
        inParams.materialId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('warehouseId'))) {
        const paramValueString = params.get('warehouseId');
        inParams.warehouseId = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'Inventory_total_available_base_amount_widget') {
      const title = 'Total base available';
      const component = Inventory_total_available_base_amount_widgetComponent;
      const inParams:{ ownerId?: number, projectId?: number, lotId?: number, materialIds?: number[], locationId?: number, materialId?: number, warehouseIds?: number[] } = { ownerId: null, projectId: null, lotId: null, materialIds: [], locationId: null, materialId: null, warehouseIds: [] };
      if (!isNil(params.get('ownerId'))) {
        const paramValueString = params.get('ownerId');
        inParams.ownerId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('projectId'))) {
        const paramValueString = params.get('projectId');
        inParams.projectId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('lotId'))) {
        const paramValueString = params.get('lotId');
        inParams.lotId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('materialIds'))) {
        const paramValueString = params.get('materialIds');
        inParams.materialIds = this.convertToJSON(paramValueString);
      }
      if (!isNil(params.get('locationId'))) {
        const paramValueString = params.get('locationId');
        inParams.locationId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('materialId'))) {
        const paramValueString = params.get('materialId');
        inParams.materialId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('warehouseIds'))) {
        const paramValueString = params.get('warehouseIds');
        inParams.warehouseIds = this.convertToJSON(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'Inventory_total_gross_weight_widget') {
      const title = 'Total gross weight';
      const component = Inventory_total_gross_weight_widgetComponent;
      const inParams:{ warehouseId?: number[], ownerId?: number, projectId?: number, lotId?: number, materialIds?: number[], locationId?: number, materialId?: number } = { warehouseId: [], ownerId: null, projectId: null, lotId: null, materialIds: [], locationId: null, materialId: null };
      if (!isNil(params.get('warehouseId'))) {
        const paramValueString = params.get('warehouseId');
        inParams.warehouseId = this.convertToJSON(paramValueString);
      }
      if (!isNil(params.get('ownerId'))) {
        const paramValueString = params.get('ownerId');
        inParams.ownerId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('projectId'))) {
        const paramValueString = params.get('projectId');
        inParams.projectId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('lotId'))) {
        const paramValueString = params.get('lotId');
        inParams.lotId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('materialIds'))) {
        const paramValueString = params.get('materialIds');
        inParams.materialIds = this.convertToJSON(paramValueString);
      }
      if (!isNil(params.get('locationId'))) {
        const paramValueString = params.get('locationId');
        inParams.locationId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('materialId'))) {
        const paramValueString = params.get('materialId');
        inParams.materialId = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'Inventory_total_packaged_amount_widget') {
      const title = 'Total amount';
      const component = Inventory_total_packaged_amount_widgetComponent;
      const inParams:{ warehouseId?: number, ownerId?: number, projectId?: number, lotId?: number, materialIds?: number[], locationId?: number, materialId?: number } = { warehouseId: null, ownerId: null, projectId: null, lotId: null, materialIds: [], locationId: null, materialId: null };
      if (!isNil(params.get('warehouseId'))) {
        const paramValueString = params.get('warehouseId');
        inParams.warehouseId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('ownerId'))) {
        const paramValueString = params.get('ownerId');
        inParams.ownerId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('projectId'))) {
        const paramValueString = params.get('projectId');
        inParams.projectId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('lotId'))) {
        const paramValueString = params.get('lotId');
        inParams.lotId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('materialIds'))) {
        const paramValueString = params.get('materialIds');
        inParams.materialIds = this.convertToJSON(paramValueString);
      }
      if (!isNil(params.get('locationId'))) {
        const paramValueString = params.get('locationId');
        inParams.locationId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('materialId'))) {
        const paramValueString = params.get('materialId');
        inParams.materialId = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }


    let result = null;
    result = this.Utilities.getComponentInformation(referenceName, params);
    if (result) {
      return result;
    }
    return result;
  }
}
