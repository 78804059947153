<datex-grid #$gridComponent *ngIf="initialized " [selection]="true" (selectionChange)="$selectionChanged($event)" [headers]="headers" [rows]="rows" [(pageSize)]="pageSize" [(pageSkip)]="pageSkip" [totalCount]="totalCount" [loadingStatus]="loadingStatus" (pageChange)="$dataLoad()" [containerStyles]="containerStyles"



>
    <ng-container topToolbar>
    <div class="toolContainer">
      <button mat-button
              class="datex-button"
              (click)="$exportExcel()">
        <div class="button-label">
          <div class="button-icon">
            <i class="icon icon-ic_fluent_document_arrow_down_20_regular"></i>
          </div>
          <div class="button-text">Export</div>
        </div>
      </button>
    </div>
    <div class="query-filter">
      <input
        matInput
        [(ngModel)]="fullTextSearch" 
        (ngModelChange)="reload()" 
        [ngModelOptions]="{updateOn: 'blur'}"
        autocomplete="off"
        class="datex-textbox query-search"
        spellcheck="false"
        placeholder="Filter"
        [matTooltip]="fullTextSearch"
        matTooltipClass="datex-control-tooltip">
    </div>
  </ng-container>

  <ng-container filters>
    <div class="fieldsetsContainer" [formGroup]="formGroup">
      <div class="fieldsetsGroup">
      <div data-cy="field-id-date_from" *ngIf="!filters.date_from.hidden && !filters.date_from.removed" 
            class="field-container standard {{filters.date_from.invalid ? 'invalid' : ''}}"
            [ngStyle]="filters.date_from.styles.style"
            [ngClass]="filters.date_from.styles.classes">
        <div class="label-container"
             [matTooltip]="filters.date_from.label + (filters.date_from.required ? ' (required)' : '')"
             matTooltipClass="datex-control-tooltip">
          <label data-cy="field-label" class="datex-label">{{filters.date_from.label}}<span *ngIf="filters.date_from.required" class="required-asterisk">*</span>
          </label>
        </div>
        <app-datebox 
            data-cy="dateBox"
            formControlName="date_from"
            [format]="filters.date_from.control.format"
            [mode]="filters.date_from.control.mode"
            [ngStyle]="filters.date_from.control.styles.style"
            [ngClass]="filters.date_from.control.styles.classes"
            [tooltip]="filters.date_from.control.tooltip">
        </app-datebox>
        <ng-container *ngIf="filters.date_from.invalid">
          <ng-container *ngFor="let error of filters.date_from.errors">
            <span class="invalid-message">
              {{error}}
            </span>
          </ng-container>
        </ng-container>
      </div>      <div data-cy="field-id-date_to" *ngIf="!filters.date_to.hidden && !filters.date_to.removed" 
            class="field-container standard {{filters.date_to.invalid ? 'invalid' : ''}}"
            [ngStyle]="filters.date_to.styles.style"
            [ngClass]="filters.date_to.styles.classes">
        <div class="label-container"
             [matTooltip]="filters.date_to.label + (filters.date_to.required ? ' (required)' : '')"
             matTooltipClass="datex-control-tooltip">
          <label data-cy="field-label" class="datex-label">{{filters.date_to.label}}<span *ngIf="filters.date_to.required" class="required-asterisk">*</span>
          </label>
        </div>
        <app-datebox 
            data-cy="dateBox"
            formControlName="date_to"
            [format]="filters.date_to.control.format"
            [mode]="filters.date_to.control.mode"
            [ngStyle]="filters.date_to.control.styles.style"
            [ngClass]="filters.date_to.control.styles.classes"
            [tooltip]="filters.date_to.control.tooltip">
        </app-datebox>
        <ng-container *ngIf="filters.date_to.invalid">
          <ng-container *ngFor="let error of filters.date_to.errors">
            <span class="invalid-message">
              {{error}}
            </span>
          </ng-container>
        </ng-container>
      </div>      <div data-cy="field-id-date_type" *ngIf="!filters.date_type.hidden && !filters.date_type.removed" 
            class="field-container standard {{filters.date_type.invalid ? 'invalid' : ''}}"
            [ngStyle]="filters.date_type.styles.style"
            [ngClass]="filters.date_type.styles.classes">
        <div class="label-container"
             [matTooltip]="filters.date_type.label + (filters.date_type.required ? ' (required)' : '')"
             matTooltipClass="datex-control-tooltip">
          <label data-cy="field-label" class="datex-label">{{filters.date_type.label}}<span *ngIf="filters.date_type.required" class="required-asterisk">*</span>
          </label>
        </div>
        <LoadContainers-load_container_date_types_single 
            data-cy="selector"
            [type]="filters.date_type.control.type"
            formControlName="date_type"
            (displayTextChange)="filters.date_type.control.displayText=$event"
            [placeholder]="filters.date_type.control.placeholder"
            [styles]="filters.date_type.control.styles"
            [tooltip]="filters.date_type.control.tooltip"
        >
        </LoadContainers-load_container_date_types_single>
        <ng-container *ngIf="filters.date_type.invalid">
          <ng-container *ngFor="let error of filters.date_type.errors">
            <span class="invalid-message">
              {{error}}
            </span>
          </ng-container>
        </ng-container>
      </div>      </div>
    </div>
  </ng-container>

  <ng-container gridColumnDef="LookupCode">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.LookupCode.displayControl.styles.style"
          [ngClass]="row.cells.LookupCode.displayControl.styles.classes"
          [matTooltip]="row.cells.LookupCode.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.LookupCode.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="OrderType_Name">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.OrderType_Name.displayControl.styles.style"
          [ngClass]="row.cells.OrderType_Name.displayControl.styles.classes"
          [matTooltip]="row.cells.OrderType_Name.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.OrderType_Name.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="Available">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.Available.displayControl.styles.style"
          [ngClass]="row.cells.Available.displayControl.styles.classes"
          [matTooltip]="row.cells.Available.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.Available.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="Picked">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.Picked.displayControl.styles.style"
          [ngClass]="row.cells.Picked.displayControl.styles.classes"
          [matTooltip]="row.cells.Picked.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.Picked.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="Loaded">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.Loaded.displayControl.styles.style"
          [ngClass]="row.cells.Loaded.displayControl.styles.classes"
          [matTooltip]="row.cells.Loaded.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.Loaded.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="Received">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.Received.displayControl.styles.style"
          [ngClass]="row.cells.Received.displayControl.styles.classes"
          [matTooltip]="row.cells.Received.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.Received.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="Putaway">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.Putaway.displayControl.styles.style"
          [ngClass]="row.cells.Putaway.displayControl.styles.classes"
          [matTooltip]="row.cells.Putaway.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.Putaway.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="Status_Name">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.Status_Name.displayControl.styles.style"
          [ngClass]="row.cells.Status_Name.displayControl.styles.classes"
          [matTooltip]="row.cells.Status_Name.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.Status_Name.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="ContainerType_Name">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.ContainerType_Name.displayControl.styles.style"
          [ngClass]="row.cells.ContainerType_Name.displayControl.styles.classes"
          [matTooltip]="row.cells.ContainerType_Name.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.ContainerType_Name.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="Carrier_Name">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.Carrier_Name.displayControl.styles.style"
          [ngClass]="row.cells.Carrier_Name.displayControl.styles.classes"
          [matTooltip]="row.cells.Carrier_Name.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.Carrier_Name.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="CarrierServiceType_Name">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.CarrierServiceType_Name.displayControl.styles.style"
          [ngClass]="row.cells.CarrierServiceType_Name.displayControl.styles.classes"
          [matTooltip]="row.cells.CarrierServiceType_Name.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.CarrierServiceType_Name.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="Priority">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.Priority.displayControl.styles.style"
          [ngClass]="row.cells.Priority.displayControl.styles.classes"
          [matTooltip]="row.cells.Priority.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.Priority.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="ContainerSize">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.ContainerSize.displayControl.styles.style"
          [ngClass]="row.cells.ContainerSize.displayControl.styles.classes"
          [matTooltip]="row.cells.ContainerSize.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.ContainerSize.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="DockAppointment">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.DockAppointment.displayControl.styles.style"
          [ngClass]="row.cells.DockAppointment.displayControl.styles.classes"
          [matTooltip]="row.cells.DockAppointment.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.DockAppointment.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="ScheduledLocation">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.ScheduledLocation.displayControl.styles.style"
          [ngClass]="row.cells.ScheduledLocation.displayControl.styles.classes"
          [matTooltip]="row.cells.ScheduledLocation.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.ScheduledLocation.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="SealIdentifier">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.SealIdentifier.displayControl.styles.style"
          [ngClass]="row.cells.SealIdentifier.displayControl.styles.classes"
          [matTooltip]="row.cells.SealIdentifier.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.SealIdentifier.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="TrailerNumber">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.TrailerNumber.displayControl.styles.style"
          [ngClass]="row.cells.TrailerNumber.displayControl.styles.classes"
          [matTooltip]="row.cells.TrailerNumber.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.TrailerNumber.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="YardLocation_Name">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.YardLocation_Name.displayControl.styles.style"
          [ngClass]="row.cells.YardLocation_Name.displayControl.styles.classes"
          [matTooltip]="row.cells.YardLocation_Name.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.YardLocation_Name.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="Notes">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.Notes.displayControl.styles.style"
          [ngClass]="row.cells.Notes.displayControl.styles.classes"
          [matTooltip]="row.cells.Notes.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.Notes.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="AvailableDate">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.AvailableDate.displayControl.styles.style"
          [ngClass]="row.cells.AvailableDate.displayControl.styles.classes"
          [matTooltip]="row.cells.AvailableDate.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.AvailableDate.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="CallOutDate">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.CallOutDate.displayControl.styles.style"
          [ngClass]="row.cells.CallOutDate.displayControl.styles.classes"
          [matTooltip]="row.cells.CallOutDate.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.CallOutDate.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="CheckOutDate">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.CheckOutDate.displayControl.styles.style"
          [ngClass]="row.cells.CheckOutDate.displayControl.styles.classes"
          [matTooltip]="row.cells.CheckOutDate.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.CheckOutDate.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="InYardDate">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.InYardDate.displayControl.styles.style"
          [ngClass]="row.cells.InYardDate.displayControl.styles.classes"
          [matTooltip]="row.cells.InYardDate.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.InYardDate.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="LastOnsiteDate">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.LastOnsiteDate.displayControl.styles.style"
          [ngClass]="row.cells.LastOnsiteDate.displayControl.styles.classes"
          [matTooltip]="row.cells.LastOnsiteDate.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.LastOnsiteDate.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="LastPierDate">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.LastPierDate.displayControl.styles.style"
          [ngClass]="row.cells.LastPierDate.displayControl.styles.classes"
          [matTooltip]="row.cells.LastPierDate.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.LastPierDate.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="CreatedSysDateTime">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.CreatedSysDateTime.displayControl.styles.style"
          [ngClass]="row.cells.CreatedSysDateTime.displayControl.styles.classes"
          [matTooltip]="row.cells.CreatedSysDateTime.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.CreatedSysDateTime.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="CreatedSysUser">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.CreatedSysUser.displayControl.styles.style"
          [ngClass]="row.cells.CreatedSysUser.displayControl.styles.classes"
          [matTooltip]="row.cells.CreatedSysUser.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.CreatedSysUser.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="ModifiedSysDateTime">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.ModifiedSysDateTime.displayControl.styles.style"
          [ngClass]="row.cells.ModifiedSysDateTime.displayControl.styles.classes"
          [matTooltip]="row.cells.ModifiedSysDateTime.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.ModifiedSysDateTime.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="ModifiedSysUser">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.ModifiedSysUser.displayControl.styles.style"
          [ngClass]="row.cells.ModifiedSysUser.displayControl.styles.classes"
          [matTooltip]="row.cells.ModifiedSysUser.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.ModifiedSysUser.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>

<ng-container bottomToolbar>
</ng-container>


</datex-grid>
